import { Controller } from '@hotwired/stimulus';
import {
  dom,
  findIconDefinition,
  library,
} from '@fortawesome/fontawesome-svg-core';

export default class extends Controller {
  static values = {
    icon: String,
    iconCamelized: String,
    prefix: String,
    importPathPrefix: String,
  };

  importHasBeenAttepted = false;

  async connect() {
    const iconDefinition = findIconDefinition({
      prefix: this.prefixValue,
      iconName: this.iconValue,
    });

    if (!iconDefinition && !this.importHasBeenAttepted) {
      window.exports = {};
      const iconModule = await import(
        /* webpackIgnore: true */
        `${location.protocol}//${this.importPathPrefixValue}/fa${this.iconCamelizedValue}.js`
      );

      iconModule.definition.icon[2] = iconModule.aliases;
      library.add(iconModule.definition);
    }

    if (this.element.tagName === 'svg' || this.importHasBeenAttepted) return;

    this.importHasBeenAttepted = true;

    this.element.classList.add(this.prefixValue, `fa-${this.iconValue}`);

    dom.i2svg({ node: this.element.parentNode });
  }
}
