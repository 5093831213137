import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import hoverintent from 'hoverintent';

const hoverIntentOptions = {
  interval: 50,
  sensitivity: 5,
};

class Snapshot extends Turbo.navigator.view.snapshot.constructor {}

export default class extends Controller {
  get visitableLinks() {
    return Array.from(
      document.querySelectorAll('a:not([data-turbo=false])')
    ).filter((element) => isPreloadable(element));
  }

  connect() {
    document.addEventListener('turbo:load', this.setupPrefetch);
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.setupPrefetch);
  }

  setupPrefetch = () => {
    this.visitableLinks.forEach((element) => {
      hoverintent(
        element,
        async () => {
          const href = element.href;
          const url = new URL(href);
          if (!Turbo.navigator.view.snapshotCache.has(url)) {
            const page = await fetchPage(href);

            const snapshot = Snapshot.fromHTMLString(page);
            Turbo.navigator.view.snapshotCache.put(url, snapshot);
            preloadCoverImage(page);
          }
        },
        () => {}
      ).options(hoverIntentOptions);
    });
  };
}

function fetchPage(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.setRequestHeader('Purpose', 'prefetch');
    xhr.setRequestHeader('Accept', 'text/html');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status !== 200) {
        reject(new Error(`Failed to prefetch ${url}`));
      } else {
        resolve(xhr.responseText);
      }
    };
    xhr.send();
  });
}

function preloadCoverImage(page) {
  const doc = document.implementation.createHTMLDocument();
  doc.open();
  doc.write(page);
  doc.close();

  const shadowElement = document.createElement('div');
  shadowElement.style.display = 'none';
  const shadow = shadowElement.attachShadow({ mode: 'open' });
  const elements = doc.querySelectorAll('section.block-cover, style');
  for (const element of elements) {
    shadow.appendChild(element);
  }
  const cover = shadow.querySelector('section.block-cover');

  if (!cover) {
    return;
  }

  document.body.appendChild(shadowElement);
  const coverUrl = window.getComputedStyle(cover).backgroundImage;
  document.body.removeChild(shadowElement);

  if (coverUrl) {
    const preloadElement = document.createElement('link');
    preloadElement.rel = 'preload';
    preloadElement.href = coverUrl.slice(5, -2);
    preloadElement.as = 'image';
    document.head.appendChild(preloadElement);
  }
}

function isPreloadable(linkElement) {
  if (
    !linkElement ||
    !linkElement.getAttribute('href') ||
    linkElement.dataset.turbo == 'false' ||
    linkElement.dataset.prefetch == 'false' ||
    linkElement.origin != location.origin ||
    !['http:', 'https:'].includes(linkElement.protocol) ||
    (linkElement.search && !('prefetch' in linkElement.dataset)) ||
    (linkElement.hash &&
      linkElement.pathname + linkElement.search ==
        location.pathname + location.search)
  ) {
    return false;
  }
  return true;
}
