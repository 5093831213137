import { Controller } from '@hotwired/stimulus';
import { tokenColors } from '@teamtailor/design-tokens';

export default class extends Controller {
  static targets = ['button'];
  static values = { color: String };
  static classes = ['hover'];

  connect() {
    if (this.colorValue) {
      document.body.style.setProperty(
        '--candidate-connect-color',
        tokenColors[this.colorValue]['500']
      );
    }
  }

  changeColor(e) {
    const { color, preview } = e.target.dataset;

    document.body.style.setProperty(
      '--candidate-connect-color',
      tokenColors[color]['500']
    );

    if (preview) {
      this.buttonTargets.forEach((button) => {
        if (button.dataset.color === color) {
          button.disabled = true;
          button.classList.remove(...this.hoverClasses);
        } else {
          button.disabled = false;
          button.classList.add(...this.hoverClasses);
        }
      });
    }
  }
}
