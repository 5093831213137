import { Controller } from '@hotwired/stimulus';

import oauthWindow from 'utils/oauthWindow';

export default class extends Controller {
  static values = { oauthUrl: String };

  async connect() {
    window.addEventListener('message', this.handleFacebookOauthCallback, false);
  }

  disconnect() {
    window.removeEventListener('message', this.handleFacebookOauthCallback);
  }

  setJobApplicationFormValues(attributes) {
    const form = document.getElementById('job-application-form');
    form.setAttribute(
      'data-careersite--form-social-data-value',
      JSON.stringify(attributes)
    );
  }

  facebookOauthClicked() {
    this.window = oauthWindow(this.oauthUrlValue, 'Facebook authentication');
  }

  handleFacebookOauthCallback = (event) => {
    if (this.window === event.source) {
      const {
        first_name: firstName,
        last_name: lastName,
        email: emailAddress,
        facebook_id: facebookId,
        social_image_url: profileImageUrl,
      } = event.data;

      this.setJobApplicationFormValues({
        firstName,
        lastName,
        emailAddress,
        facebookId,
        profileImageUrl,
      });
    }
  };
}
