import { Controller } from '@hotwired/stimulus';
import { arrangeCollageItems } from 'utils/collage';

export default class GalleryCollage extends Controller {
  static targets = ['image'];

  async connect() {
    await this.renderGallery();

    window.addEventListener('resize', this.renderGallery);
  }

  disconnect() {
    window.removeEventListener('resize', this.renderGallery);
  }

  renderGallery = async () => {
    const galleryHeight = await arrangeCollageItems(this.imageTargets);
    this.imageTargets.forEach((image) => {
      image.classList.add('animate__fadeIn');
      image.classList.remove('invisible');
    });
    this.element.style.height = `${galleryHeight}px`;
  };
}
