import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['autoRow'];

  static values = {
    colMinW: Number,
    colMaxW: Number,
    gap: Number,
  };

  async connect() {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const oneCol =
          entry.borderBoxSize[0].inlineSize <
          this.colMinWValue * 2 + this.gapValue;
        /**
         * when you can use container queries for container custom properties or vars in mediq queries this can probably be done in css instead
         * @see https://caniuse.com/?search=container%20queries%20custom%20properties
         */
        this.element.style.setProperty(
          '--col-max-w',
          oneCol ? 'none' : `${this.colMaxWValue}px`
        );
        this.element.classList.toggle(this.autoRowClass, !oneCol);
      });
    });

    observer.observe(this.element);
  }
}
