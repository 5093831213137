import { Controller } from '@hotwired/stimulus';
import { isEmpty } from 'lodash-es';

const CUSTOM_INPUT_TEMPLATES = ['color', 'emoji'];

export default class QuestionsPresetFavouritesController extends Controller {
  static targets = [
    'container',
    'categorySelect',
    'categoryInputTemplate',
    'colorInputTemplate',
    'emojiInputTemplate',
  ];

  static values = {
    selectedCategories: Array,
    preselectedCategory: Object,
    answersData: Object,
    categories: Array,
  };

  connect() {
    if (!isEmpty(this.answersDataValue)) {
      for (const [key, value] of Object.entries(this.answersDataValue)) {
        const { name } = this.categoriesValue.find((cat) => cat.key === key);
        this.addCategory({
          categoryKey: key,
          categoryName: name,
          categoryValue: value,
        });
      }
    } else {
      this.addCategory(this.preselectedCategoryValue);
    }
  }

  selectedCategoriesValueChanged() {
    window.dispatchEvent(
      new CustomEvent('favourites-selected-categories-changed', {
        detail: {
          selectedCategories: this.selectedCategoriesValue,
        },
      })
    );
  }

  addCategory({ categoryKey, categoryName, categoryValue }) {
    const inputTemplate = this.createCategoryElement(
      categoryKey,
      categoryName,
      categoryValue
    );

    this.containerTarget.insertBefore(
      inputTemplate,
      this.containerTarget.lastChild
    );

    this.selectedCategoriesValue = [
      ...this.selectedCategoriesValue,
      categoryKey,
    ];
  }

  handleClick(e) {
    e.preventDefault();
    this.addCategory(e.currentTarget.dataset);
  }

  handleDropdownClosed(e) {
    const { id } = e.detail;
    if (id === 'favourite-category-dropdown') {
      this.focusLast();
    }
  }

  handleCategoryRemoved(e) {
    const category = e.detail.category;
    this.focusLast();

    this.selectedCategoriesValue = this.selectedCategoriesValue.filter(
      (item) => item !== category
    );
  }

  handleCategoryChange(e) {
    const { categoryKey, categoryName } = e.detail.to;

    const inputTemplate = this.createCategoryElement(categoryKey, categoryName);

    this.containerTarget.insertBefore(inputTemplate, e.detail.target);
    this.containerTarget.removeChild(e.detail.target);

    const selectedCategoriesValue = this.selectedCategoriesValue.filter(
      (item) => item !== e.detail.from.categoryKey
    );

    this.selectedCategoriesValue = [
      ...selectedCategoriesValue,
      e.detail.to.categoryKey,
    ];
  }

  createCategoryElement(categoryKey, categoryName, categoryValue) {
    const templateTarget = CUSTOM_INPUT_TEMPLATES.includes(categoryKey)
      ? categoryKey
      : 'category';

    const template = this[
      `${templateTarget}InputTemplateTarget`
    ].content.cloneNode(true);

    this.replaceCategoryKey(template, categoryKey);
    this.replaceCategoryName(template, categoryName);

    if (categoryValue) {
      this.replaceCategoryValue(template, categoryValue);
    }

    return template;
  }

  replaceCategoryKey(template, categoryKey) {
    const categoryContainerNode = template.querySelector('.category-container');
    categoryContainerNode.dataset.categoryKey = categoryKey;

    const inputNode = template.querySelector('input[type="text"]');
    this.replaceAttributeCategory(inputNode, 'name', categoryKey);
    this.replaceAttributeCategory(inputNode, 'id', categoryKey);
  }

  replaceCategoryName(template, categoryName) {
    const categoryNode = template.querySelector('.category-name');
    this.replaceAttributeCategory(categoryNode, 'innerText', categoryName);
  }

  replaceCategoryValue(template, categoryValue) {
    const categoryNode = template.querySelector('.category-value');
    categoryNode.value = categoryValue;
  }

  replaceAttributeCategory(node, property, category) {
    node[property] = node[property].replace('__CATEGORY__', category);
  }

  focusLast() {
    const inputs = this.containerTarget.querySelectorAll('input[type="text"]');
    if (inputs.length) {
      inputs[inputs.length - 1].focus();
    }
  }
}
