import ApplicationController from 'controllers/share/application_controller';

export default class InterviewKitFormController extends ApplicationController {
  connect() {
    this.element.addEventListener('turbo:submit-end', async (event) => {
      window.scrollTo(0, 0);
      this.element.innerHTML = await event.detail.fetchResponse.response.text();
    });
  }
}
