import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['nextButton'];
  static outlets = ['common--modal'];

  connect() {
    if (this.hasNextButtonTarget) {
      document.addEventListener('keydown', this.checkIfNext);
    }
  }

  disconnect() {
    if (this.hasNextButtonTarget) {
      document.removeEventListener('keydown', this.checkIfNext);
    }
  }

  checkIfNext = (event) => {
    if (!event.shiftKey && event.key === 'Enter') {
      if (
        event.target.tagName.toUpperCase() == 'BODY' ||
        this.commonModalOutlet.open
      ) {
        event.preventDefault();
        event.stopPropagation();
        if (this.hasNextButtonTarget) {
          this.nextButtonTarget.click();
        }
      }
    }
  };
}
