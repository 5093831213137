import { Controller } from '@hotwired/stimulus';
import { findIndex, some } from 'lodash-es';

import { isMobile } from 'utils/device';
import { prefersReducedMotion } from 'utils/common';
import { observeElementVisible } from 'utils';

import './carousel.scss';

export default class Carousel extends Controller {
  static targets = ['image', 'item'];
  static values = {
    autoplay: Boolean,
    pagination: Boolean,
    arrows: Boolean,
    type: String,
    useContainerHeight: Boolean,
    initializeWhenViewport: Boolean,
    hasVideo: Boolean,
    heightRatio: Number,
    initialImageIndex: Number,
    splideClasses: Object,
  };

  connect() {
    window.addEventListener('splidejs-url', ({ detail: url }) => {
      if (this.splide) {
        const {
          Elements: { slides },
        } = this.splide.Components;
        const imageIndex = findIndex(
          slides,
          (slide) => slide.dataset.url === url
        );

        if (imageIndex > -1) {
          this.splide.go(imageIndex);
        }
      } else {
        const imageIndex = findIndex(
          this.imageTargets,
          (imageTarget) => imageTarget.dataset.splideLazy === url
        );
        this.initialImageIndexValue = imageIndex > -1 ? imageIndex : 0;
      }
    });

    if (this.initializeWhenViewportValue) {
      this.initializeObserver = observeElementVisible(this.element, () => {
        this.initializeCarousel();
      });
    } else {
      this.initializeCarousel();
    }
  }

  async initializeCarousel() {
    const [{ Splide }] = await Promise.all([
      import(/* webpackChunkName: 'splide' */ '@splidejs/splide'),
      import(
        /* webpackChunkName: 'splide-css' */ '@splidejs/splide/dist/css/splide.min.css'
      ),
    ]);

    let mountOptions = {};

    if (this.hasVideoValue) {
      const [{ Video }] = await Promise.all([
        import(
          /* webpackChunkName: 'splide-video' */ '@splidejs/splide-extension-video'
        ),
        import(
          /* webpackChunkName: 'splide-video-css' */ '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
        ),
      ]);
      mountOptions.Video = Video;
    }

    const hasMultipleItems = this.itemTargets.length > 1;
    const shouldAutoplay =
      this.autoplayValue &&
      prefersReducedMotion() === false &&
      hasMultipleItems;
    const speed = prefersReducedMotion() ? 0 : 400;
    const type = prefersReducedMotion() ? 'fade' : this.typeValue;
    const height =
      this.useContainerHeightValue && this.element.clientHeight > 0
        ? this.element.clientHeight
        : undefined;

    this.splide = new Splide(this.element, {
      type,
      arrows: this.arrowsValues && hasMultipleItems,
      speed,
      autoplay: shouldAutoplay,
      height,
      heightRatio: this.heightRatioValue,
      lazyLoad: 'nearby',
      pagination: this.paginationValue && hasMultipleItems,
      pauseOnHover: true,
      slideFocus: false,
      classes: this.splideClassesValue,
    }).mount(mountOptions);

    if (hasMultipleItems) {
      this.splide.Components.Autoplay?.pause();
    }

    this.splide.go(this.initialImageIndexValue);
    // Having images as hidden before mount makes them not take up any space and prevents page from jumping
    this.imageTargets.forEach((imageTarget) => {
      imageTarget.classList.remove('hidden');
    });

    this.autoplayObserver = new IntersectionObserver((entries) => {
      const isIntersecting = some(entries, 'isIntersecting');
      if (isIntersecting && shouldAutoplay) {
        this.splide.Components.Autoplay?.play();
      } else {
        this.splide.Components.Autoplay?.pause();
      }
    });

    this.autoplayObserver.observe(this.element);

    if (isMobile()) {
      document
        .querySelectorAll('.splide__pagination button')
        .forEach((button) => {
          button.setAttribute('disabled', true);
        });
    }
  }

  initialImageIndexValueChanged(index) {
    if (this.splide) {
      this.splide.go(index);
    }
  }

  disconnect() {
    if (this.initializeObserver) {
      this.initializeObserver.disconnect();
    }

    if (this.autoplayObserver) {
      this.autoplayObserver.disconnect();
    }

    if (this.splide) {
      this.splide.destroy(true);
    }

    // Restore DOM as it was before mounting, to make Turbo caching work as expected
    this.imageTargets.forEach((imageTarget) => {
      imageTarget.classList.add('hidden');
    });
  }
}
