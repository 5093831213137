import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    background: String,
    text: String,
    fontWeight: Number,
  };

  bodyFontSize = 21;

  fontSizeMap = ['LcValue', 100, 200, 300, 400, 500, 600, 700, 800, 900];

  async connect() {
    await import(
      /* webpackChunkName: 'contrast-check' */ './contrast_check.scss'
    );
    const { calcAPCA, fontLookupAPCA } = await import(
      /* webpackChunkName: 'apca-w3' */ 'apca-w3'
    );

    const apcaValue = calcAPCA(this.textValue, this.backgroundValue);
    const fontSizes = fontLookupAPCA(apcaValue);
    const minimumFontSize =
      fontSizes[this.fontSizeMap.indexOf(this.fontWeightValue)];

    const contrastOk = minimumFontSize <= this.bodyFontSize;

    if (!contrastOk) {
      this.element.classList.add('flex');
      this.element.classList.remove('hidden');
    }
  }
}
