import { Controller } from '@hotwired/stimulus';

import 'stylesheets/careersite/responsive-video';

export default class extends Controller {
  connect() {
    this.element
      .querySelectorAll('iframe[src*="youtube"], iframe[src*="vimeo"]')
      .forEach((iframe) => {
        window.requestAnimationFrame(() => {
          this.makeIframeResponsive(iframe);
        });
      });
  }

  makeIframeResponsive(iframe) {
    if (iframe.parentElement.classList.contains('responsive-video__wrapper')) {
      return false;
    }

    const height = iframe.offsetHeight;
    const width = iframe.offsetWidth;

    const wrapper = document.createElement('div');
    wrapper.classList.add('responsive-video__wrapper');

    iframe.parentNode.insertBefore(wrapper, iframe);
    iframe.classList.add('responsive-video__item');

    wrapper.appendChild(iframe);

    if (height && width) {
      iframe.parentNode.style.setProperty(
        '--aspectRatio',
        `${(height / width) * 100}%`
      );
    }
  }
}
