import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['careersite--form'];
  static values = {
    question: String,
  };

  onClick(e) {
    if (this.hasCareersiteFormOutlet) {
      this.careersiteFormOutlet.updateQualifyingAnswer(
        this.questionValue,
        e.target.value === 'true'
      );
    }
  }
}
