import { Controller } from '@hotwired/stimulus';
import { initializeZiggeo } from 'utils/ziggeo';

export default class extends Controller {
  static values = { ziggeoToken: String, ziggeoAuthToken: String };

  async connect() {
    const ziggeo = await initializeZiggeo(this.ziggeoTokenValue);

    const player = new ziggeo.Player({
      element: this.element,
      attrs: {
        video: this.element.dataset.token,
        'client-auth': this.ziggeoAuthTokenValue,
      },
    });

    player.activate();
  }
}
