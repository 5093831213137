import { Controller } from '@hotwired/stimulus';
import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import { createFocusTrap } from 'focus-trap';

export default class extends Controller {
  static targets = [
    'template',
    'overlay',
    'linkContainer',
    'spinnerTemplate',
    'errorTemplate',
  ];
  static values = {
    open: Boolean,
    markdown: String,
    turbo: Boolean,
    allowEventPropagation: Boolean,
    modalName: String,
    spinnerOnClose: Boolean,
  };

  modal = null;

  markdownValueChanged() {
    if (this.modal) {
      this.setMarkdownContent(this.markdownValue);
    }
  }

  async setMarkdownContent(markdown) {
    const [{ marked }, { default: DOMPurify }] = await Promise.all([
      import(/* webpackChunkName: 'marked' */ 'marked'),
      import(/* webpackChunkName: 'dompurify' */ 'dompurify'),
    ]);
    const markdownHtml = marked.parse(markdown);
    this.modal.querySelector('.modal-content').innerHTML = DOMPurify.sanitize(
      markdownHtml
    );
  }

  toggleScrollbar() {
    const scrollableWindow = document.body.scrollHeight > window.innerHeight;
    if (this.open) {
      this.body.classList.add('modal__container');
      if (scrollableWindow) {
        this.body.classList.add('modal__container--hide-scrollbar');
      }
    } else {
      this.body.classList.remove(
        'modal__container',
        'modal__container--hide-scrollbar'
      );
    }
  }

  openValueChanged() {
    if (this.modal) {
      this.modal.classList.toggle('modal--open', this.open);
      this.toggleScrollbar();

      if (this.open) {
        this.focusTrap.activate();
      } else {
        this.focusTrap.deactivate();
        if (this.spinnerOnCloseValue) {
          this.insertSpinner();
        }
      }
    }
  }

  async connect() {
    // Hide it until styles have loaded
    this.element.style.display = '';

    this.createOrFindModal();

    await import(/* webpackChunkName: 'modal' */ './modal.scss');

    this.linksOrButtons.forEach((element) =>
      element.addEventListener('click', this.handleLinkClick)
    );
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${scrollbarWidth()}px`
    );
  }

  disconnect() {
    if (!this.turboValue) {
      this.destroyModal();
    }
    this.linksOrButtons.forEach((element) =>
      element.removeEventListener('click', this.handleLinkClick)
    );
    this.focusTrap?.deactivate();
  }

  createOrFindModal() {
    if (!!this.existingModal && this.turboValue) {
      this.modal = this.existingModal;
      this.openValue = this.existingModal.classList.contains('modal--open');
      this.setModalEventListeners();
    } else {
      const node = this.template.cloneNode(true);
      this.modal = node.querySelector('div');
      this.setModalEventListeners();

      if (this.modalNameValue) {
        this.modal.id = this.modalNameValue;
      }

      this.body.appendChild(this.modal);
    }

    this.focusTrap = createFocusTrap(this.modal, {
      clickOutsideDeactivates: true,
      onDeactivate: () => {
        if (this.open) {
          this.toggleModal();
        }
      },
    });
  }

  destroyModal() {
    this.modal
      .querySelectorAll('[data-close=true]')
      .forEach((element) =>
        element.removeEventListener('click', this.toggleModal)
      );
    this.modal.remove();
  }

  setModalEventListeners = () => {
    this.modal
      .querySelectorAll('[data-close]')
      .forEach((element) =>
        element.addEventListener('click', this.toggleModal)
      );

    this.modal.addEventListener('click', (event) => {
      if (
        event.target.getAttribute('type') !== 'submit' &&
        event.target.tagName.toLowerCase() !== 'a'
      ) {
        if (!this.allowEventPropagationValue) {
          event.stopPropagation();
          event.preventDefault();
        }
      }

      // Close modal if overlay click
      if (event.target === this.modal) {
        this.toggleModal();
      }
    });

    this.modal.ontransitionend = () => {
      this.toggleScrollbar();
    };
  };

  handleLinkClick = (event) => {
    if (!this.allowEventPropagationValue) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.toggleModal();
  };

  toggleModal = () => {
    this.openValue = !this.openValue;
  };

  insertSpinner = () => {
    const spinnerNode = this.spinnerTemplate.cloneNode(true);
    const spinnerContent = spinnerNode.querySelector('div');
    this.modal.querySelector('turbo-frame').innerHTML =
      spinnerContent.outerHTML;
  };

  displayErrorContent = (turboFrame) => {
    if (turboFrame) {
      const errorNode = this.errorTemplate.cloneNode(true);
      const errorContent = errorNode.querySelector('div');
      turboFrame.innerHTML = errorContent.outerHTML;
    }
  };

  get open() {
    return this.openValue;
  }

  get linksOrButtons() {
    if (this.hasLinkContainerTarget) {
      return this.linkContainerTarget.querySelectorAll(
        'a:not([target="_blank"]), button:not([data-modal-ignore])'
      );
    } else {
      return [];
    }
  }

  get template() {
    return this.templateTarget.content;
  }

  get spinnerTemplate() {
    return this.spinnerTemplateTarget.content;
  }

  get errorTemplate() {
    return this.hasErrorTemplateTarget
      ? this.errorTemplateTarget.content
      : null;
  }

  get body() {
    return document.querySelector('body');
  }

  get existingModal() {
    return document.getElementById(this.modalNameValue);
  }
}
