import { Controller } from '@hotwired/stimulus';
import confetti from 'utils/confetti';

export default class extends Controller {
  static targets = [
    'canvas',
    'tooltip',
    'tooltipHeading',
    'tooltipSubheading',
    'wrapper',
  ];
  static values = {
    clickCount: Number,
    fiveClicksHeading: String,
    fiveClicksSubheading: String,
    tenClicksHeading: String,
    tenClicksSubheading: String,
    moreClicksHeading: String,
    moreClicksSubheading: String,
    thousandClicksHeading: String,
    thousandClicksSubheading: String,
  };

  clickData = {
    5: {
      heading: this.fiveClicksHeadingValue,
      subheading: this.fiveClicksSubheadingValue,
    },
    10: {
      heading: this.tenClicksHeadingValue,
      subheading: this.tenClicksSubheadingValue,
    },
    11: {
      heading: this.moreClicksHeadingValue,
      subheading: this.moreClicksSubheadingValue,
    },
    1000: {
      heading: this.thousandClicksHeadingValue,
      subheading: this.thousandClicksSubheadingValue,
    },
  };

  handleClickHoverText() {
    this.clickCountValue++;

    const clickInfo = this.clickData[this.clickCountValue];

    if (clickInfo) {
      const { heading, subheading } = clickInfo;
      this.updateTooltipContent(heading, subheading);
    }
  }

  updateTooltipContent(heading, subheading) {
    if (heading && subheading) {
      this.tooltipHeadingTarget.innerText = heading;
      this.tooltipSubheadingTarget.innerText = subheading;
      this.tooltipTarget.classList.add('scale-50');

      setTimeout(() => {
        this.tooltipTarget.classList.remove('scale-50');
      }, 100);
    }
  }

  renderConfetti() {
    this.handleClickHoverText();

    const canvas = document.createElement('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.className =
      'fixed inset-0 h-full w-full motion-reduce:hidden z-[30] pointer-events-none duration-500 transition-opacity';

    this.wrapperTarget.appendChild(canvas);

    confetti(canvas);

    setTimeout(() => {
      canvas.classList.add('opacity-0');
    }, 3000);

    setTimeout(() => {
      canvas.remove();
    }, 3500);
  }
}
