import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['mobile', 'desktop'];

  type = 'desktop';

  toggle() {
    if (this.type === 'desktop') {
      this.desktopTargets.forEach((target) => target.classList.add('hidden'));
      this.mobileTargets.forEach((target) => target.classList.remove('hidden'));
      this.type = 'mobile';
    } else {
      this.desktopTargets.forEach((target) =>
        target.classList.remove('hidden')
      );
      this.mobileTargets.forEach((target) => target.classList.add('hidden'));
      this.type = 'desktop';
    }
  }
}
