import ApplicationController from 'controllers/share/application_controller';

export default class PitchController extends ApplicationController {
  static targets = ['collapsed', 'expanded'];
  static values = { isAnonymous: Boolean };

  connect() {
    this.isCollapsed = this.isAnonymousValue;
  }

  handleToggle() {
    [this.collapsedTarget, this.expandedTarget].forEach((element) => {
      element.classList.toggle('hidden');
    });
  }
}
