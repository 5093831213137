import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modalButton', 'carousel'];

  connect() {
    this.modalButtonTargets.forEach((modalButton) => {
      modalButton.addEventListener('click', (event) => {
        const splideEvent = new CustomEvent('splidejs-url', {
          detail: event.target.dataset.url,
        });
        window.dispatchEvent(splideEvent);
      });
    });
  }
}
