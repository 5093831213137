import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = [
    'city',
    'departments',
    'departmentsButton',
    'consentEmail',
    'consentCheckbox',
    'socialButton',
    'socialAttributeInput',
  ];

  connect() {
    // Poor mans spam protection, city field needs to be removed from form.
    this.cityTarget.remove();

    this.toggleConsent();

    this.departmentsButtonTarget.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        this.continue();
      }
    });
  }

  disableSocialLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  departmentsButtonClicked(event) {
    event.preventDefault();
    this.continue();
  }

  continue() {
    const departmentsHeight = this.departmentsTarget.clientHeight;
    const consentEmailHeight = this.consentEmailTarget.clientHeight;

    if (departmentsHeight > consentEmailHeight) {
      this.element.style.height = `${departmentsHeight}px`;
    } else {
      this.element.style.height = `${consentEmailHeight}px`;
    }

    this.departmentsTarget.classList.add('animate__fadeOutUp', 'absolute');
    this.departmentsTarget.addEventListener('animationend', (event) => {
      if (event.animationName === 'fadeOutUp') {
        this.departmentsTarget.classList.add('hidden');
      }
    });
    this.departmentsButtonTarget.classList.add('animate__fadeOut');
    this.consentEmailTarget.classList.remove('invisible', 'absolute');
    this.consentEmailTarget.classList.add('animate__fadeInUp');
  }

  toggleConsent() {
    if (this.hasConsentCheckboxTarget) {
      this.socialButtonTargets.forEach((button) => {
        if (this.consentCheckboxTarget.checked) {
          button.removeAttribute('disabled');
          button.classList.remove('button--disabled');
          button.removeEventListener('click', this.disableSocialLinkClick);
        } else {
          button.setAttribute('disabled', true);
          button.classList.add('button--disabled');
          button.addEventListener('click', this.disableSocialLinkClick);
        }
      });
    }
  }
}
