import { compact, isEmpty } from 'lodash-es';

export async function generateSummaryMarkdown(profileData) {
  const [json2md, luxon] = await Promise.all([
    import('json2md'),
    import('luxon'),
  ]);

  const { DateTime } = luxon;

  const formatMonth = (month) =>
    DateTime.fromFormat(`${month}`, 'L').toFormat('LLL');

  const duration = (startDate, endDate) => {
    const startMonth = startDate?.month ? formatMonth(startDate.month) : null;
    const endMonth = endDate?.month ? formatMonth(endDate.month) : null;
    const hasStart = startMonth || startDate?.year;
    const hasEnd = endMonth || endDate?.year;

    return compact([
      startMonth,
      startDate?.year,
      hasStart ? '-' : null,
      endMonth,
      endDate?.year,
      hasStart && !hasEnd ? 'current' : null,
    ]).join(' ');
  };

  const position = ({ title, companyName, startDate, endDate, summary }) =>
    compact([
      {
        p: `**${compact([title, companyName]).join(', ')}**<br/>${duration(
          startDate,
          endDate
        )}`,
      },
      summary
        ? {
            p: `_${summary.replaceAll('\n', '<br />')}_`,
          }
        : null,
    ]);

  const currentPositions = profileData.positions
    .filter(({ isCurrent }) => isCurrent)
    .map(position);

  const pastPositions = profileData.positions
    .filter(({ isCurrent }) => !isCurrent)
    .map(position);

  const educations = profileData.educations.map(
    ({ schoolName, startDate, endDate, degree, fieldOfStudy }) => [
      {
        p: compact([`**${schoolName}**`, duration(startDate, endDate)]).join(
          ' '
        ),
      },
      { p: compact([degree, fieldOfStudy]).join(', ') },
    ]
  );

  const skills = profileData.skills.map(({ name }) => name).join(', ');

  const markdown = [];

  if (!isEmpty(profileData.summary)) {
    markdown.push({ h4: 'Summary' }, { p: profileData.summary });
  }

  if (!isEmpty(currentPositions)) {
    markdown.push(
      { h4: `Current position${currentPositions.length > 1 ? 's' : ''}` },
      ...currentPositions
    );
  }

  if (!isEmpty(pastPositions)) {
    markdown.push({ h4: 'Past positions' }, ...pastPositions);
  }

  if (!isEmpty(educations)) {
    markdown.push({ h4: 'Education' }, ...educations);
  }

  if (!isEmpty(skills)) {
    markdown.push({ h4: 'Endorsed skills' }, { p: skills });
  }

  return json2md.default(markdown);
}
