import { Controller } from '@hotwired/stimulus';
import { useWindowResize } from 'stimulus-use';

const SCREEN_SIZES = {
  small: 'small',
  large: 'large',
};

export default class extends Controller {
  static targets = ['mobile', 'desktop', 'container'];

  screenSize = '';

  connect() {
    useWindowResize(this);
  }

  disconnect() {
    window.removeEventListener('resize', this.renderJobsList);
  }

  renderJobsList(width) {
    const screenSize = width < 700 ? SCREEN_SIZES.small : SCREEN_SIZES.large;
    const shouldChange = screenSize !== this.screenSize;
    this.screenSize = screenSize;

    this.isSmallScreen = screenSize;
    if (shouldChange) {
      if (screenSize === SCREEN_SIZES.small) {
        this.containerTarget.innerHTML = this.mobileTarget.innerHTML;
      } else {
        this.containerTarget.innerHTML = this.desktopTarget.innerHTML;
      }
    }
  }

  windowResize({ width }) {
    this.renderJobsList(width);
  }
}
