import { Controller } from '@hotwired/stimulus';

export default class StoryVideoController extends Controller {
  static targets = [
    'audioIcon',
    'audioButton',
    'playPauseButton',
    'progressBar',
    'progressWrapper',
    'video',
    'coverImage',
  ];

  static values = {
    play: false,
    loopVideo: true,
    timelineWhenPaused: true,
    uuid: String,
    muted: { type: Boolean, default: false },
  };

  shouldPlay = false;

  connect() {
    this.loopVideoValue =
      this.videoTarget.getAttribute('data-loop-video') === 'true';
    this.timelineWhenPausedValue =
      this.videoTarget.getAttribute('data-timeline-when-paused') === 'true';

    if (this.timelineWhenPausedValue) {
      this.progressWrapperTarget.classList.remove('invisible');
    }
  }

  handlePlaying = () => {
    const { offsetWidth } = this.progressWrapperTarget;

    this.progressWrapperWidth = offsetWidth;

    this.videoTarget.classList.replace('opacity-0', 'opacity-100');

    const { currentTime, duration } = this.videoTarget;
    this.videoDuration = duration;
    this.animationStartTime = Date.now();
    this.videoStartTime = currentTime;
    this.updateProgressBar();
  };

  handleEnded = () => {
    // Loop story
    if (this.loopVideoValue) {
      this.play();
    }
  };

  handlePause = () => {
    cancelAnimationFrame(this.animationId);
  };

  updateProgressBar = () => {
    const elapsedTime = (Date.now() - this.animationStartTime) / 1000;
    const currentTime = elapsedTime + this.videoStartTime;

    if (currentTime <= this.videoDuration) {
      const progressBarWidth =
        this.progressWrapperWidth * (currentTime / this.videoDuration);

      this.progressBarTarget.style.transform = `translateX(${progressBarWidth}px)`;
      this.animationId = requestAnimationFrame(this.updateProgressBar);
    }
  };

  handleStoryChanged(e) {
    if (this.uuidValue === e.detail.storyId) {
      this.play({ fromBeginning: true });
    } else {
      this.stop();
    }
  }

  handleStoryAudioChanged(e) {
    const { muted } = e.detail;
    this.mutedValue = muted;
  }

  mutedValueChanged() {
    this.videoTarget.muted = this.mutedValue;
    if (this.mutedValue) {
      this.audioIconTarget.classList.replace('fa-volume', 'fa-volume-slash');
    } else {
      this.audioIconTarget.classList.replace('fa-volume-slash', 'fa-volume');
    }

    if (this.shouldPlay) {
      this.shouldPlay = false;
      this.play();
    }
  }

  async play({ fromBeginning = false } = {}) {
    if (fromBeginning) {
      this.videoTarget.currentTime = 0;
    }

    await this.videoTarget.play();
    if (this.hasCoverImageTarget) {
      this.coverImageTarget.remove();
    }
    this.audioButtonTarget.classList.replace('opacity-0', 'opacity-100');
    this.playValue = true;

    this.progressWrapperTarget.classList.remove('invisible');
  }

  stop() {
    this.videoTarget.pause();
    this.audioButtonTarget.classList.replace('opacity-100', 'opacity-0');
    this.playValue = false;
    if (!this.timelineWhenPausedValue) {
      this.progressWrapperTarget.classList.add('invisible');
    }
  }

  toggleAudio() {
    const event = new CustomEvent('story-audio-changed', {
      detail: { muted: !this.mutedValue },
    });
    window.dispatchEvent(event);
  }

  async playValueChanged() {
    if (this.playValue) {
      try {
        await this.play();
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // Play in muted value change callback
          this.shouldPlay = true;
          this.mutedValue = true;
        }
      }
    } else {
      this.stop();
    }

    this.playPauseButtonTarget.firstElementChild.classList.toggle(
      'opacity-0',
      this.playValue
    );

    this.playPauseButtonTarget.firstElementChild.classList.toggle(
      'opacity-100',
      !this.playValue
    );
  }

  togglePlay() {
    this.playValue = !this.playValue;
  }
}
