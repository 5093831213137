import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);

const context_components = require.context(
  '../../components',
  true,
  /_controller.js$/
);

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(context_components)
  )
);
