import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';
import { hasAcceptedCookieCategory } from 'utils/cookies';

export default class extends Controller {
  static values = {
    disabled: Boolean,
  };

  connect() {
    document.addEventListener(
      'teamtailor:cookies-changed',
      this.handleReferrerCookie.bind(this)
    );
    this.handleReferrerCookie();
  }

  handleReferrerCookie() {
    if (this.disabledValue || !hasAcceptedCookieCategory('analytics')) {
      if (!window.referrer) {
        window.referrer = this.referrerValue();
      }
      return;
    }

    if (!Cookies.get('referrer') && this.referrerValue()) {
      Cookies.set('referrer', this.referrerValue());
    }
  }

  referrerValue() {
    return this.utmSource() || this.documentReferrer();
  }

  utmSource() {
    const searchParams = new URL(window.location.href).searchParams;

    return searchParams.get('utm_source');
  }

  documentReferrer() {
    if (!document.referrer) {
      return null;
    }

    const referrerUrl = new URL(document.referrer);
    if (location.hostname !== referrerUrl.hostname) {
      return document.referrer.substr(0, 1000);
    }

    return null;
  }
}
