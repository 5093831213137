import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { debounce } from 'debounce';

export default class extends Controller {
  static values = {
    refreshPath: String,
    inEditor: Boolean,
  };

  connect() {
    this.element.addEventListener(
      'turbo:before-fetch-response',
      this.debouncedTriggerRefresh
    );
  }

  disconnect() {
    this.element.removeEventListener(
      'turbo:before-fetch-response',
      this.debouncedTriggerRefresh
    );
  }

  triggerRefresh = async (e) => {
    if (!this.inEditorValue) {
      // This logic is currently used to force page morphing after updating info or locations in questions card
      const pathname = e.detail.fetchResponse.location.pathname;
      const isQuestionPath =
        pathname.includes('connect/questions/info') ||
        pathname.includes('connect/questions/locations');
      if (this.refreshPathValue) {
        await Turbo.visit('profile/settings', {
          frame: 'connect_settings',
        });
        await Turbo.visit(this.refreshPathValue, { action: 'replace' });
      }
      this.refreshPathValue = isQuestionPath ? window.location.pathname : '';
    }
  };

  debouncedTriggerRefresh = debounce(this.triggerRefresh, 300);
}
