import { Controller } from '@hotwired/stimulus';
import { measureText, getFontShorthand } from 'update-input-width';

const ORIGINAL_INPUT_WIDTH = 32;
export default class QuestionsPresetFavouritesCategoryItemController extends Controller {
  static targets = ['container', 'input', 'colorInput'];

  static values = {
    input: String,
    font: String,
  };

  connect() {
    const { type, skipInputMethods } = this.inputTarget.dataset;

    if (!skipInputMethods) {
      this.font = getFontShorthand(this.inputTarget);
      if (this.inputTarget.value.length) {
        this.handleInputWidth();
      }
      if (type) {
        this.inputTarget.focus();
      }
    }
  }

  handleKeyDown(e) {
    this.handleIsEnterPressed(e);

    if (!this.isBackspacePressed(e) || this.inputTarget.value !== '') {
      if (!this.inputTarget.dataset.skipInputMethods) {
        this.handleInputWidth();
      }
      return;
    }
    e.preventDefault();

    const event = new CustomEvent('favourites-category-removed', {
      detail: { category: this.containerTarget.dataset.categoryKey },
    });

    this.containerTarget.parentNode.removeChild(this.containerTarget);

    window.dispatchEvent(event);
  }

  handleKeyUp(e) {
    if (!this.inputTarget.dataset.skipInputMethods) {
      this.handleInputWidth();
    }
    this.inputValue = e.currentTarget.value;
  }

  isBackspacePressed(e) {
    return e.key === 'Backspace' || e.keyCode === 8;
  }

  handleIsEnterPressed(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
    }
  }

  handleInputWidth() {
    const width = measureText(this.inputTarget.value, this.font);

    if (!this.inputTarget.dataset.skipInputMethods) {
      if (width > ORIGINAL_INPUT_WIDTH) {
        this.inputTarget.style.width = width + 'px';
      } else {
        this.inputTarget.style.width = ORIGINAL_INPUT_WIDTH + 'px';
      }
    }
  }

  handleCategoryChange(e) {
    e.preventDefault();

    window.dispatchEvent(
      new CustomEvent('favourites-category-change', {
        detail: {
          target: this.containerTarget,
          from: this.containerTarget.dataset,
          to: e.currentTarget.dataset,
        },
      })
    );
  }
}
