import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.inputTarget.value = this.inputTarget.dataset.initialValue;
      this.inputTarget.blur();
    }
  }
}
