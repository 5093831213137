import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  changeStack(event) {
    window.location = window.location.href.replace(
      window.location.host,
      event.target.value
    );
  }
}
