/* global ga */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  triggered = false;

  connect() {
    this.checkViewport();
    window.addEventListener('scroll', this.checkViewport);
  }

  disconnect() {
    window.removeEventListener('scroll', this.checkViewport);
  }

  checkViewport = () => {
    if (this.isInViewport() && !this.triggered) {
      this.triggerTrackingEvent();
    }
  };

  triggerTrackingEvent() {
    this.triggered = true;

    if (ga) {
      ga('send', 'event', {
        eventCategory: 'Teamtailor',
        eventAction: 'Connect button visible',
      });
    }
  }

  isInViewport = () => {
    const rect = this.buttonTarget.getBoundingClientRect();
    const html = document.documentElement;
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight) &&
      rect.right <= (window.innerWidth || html.clientWidth)
    );
  };
}
