import { Controller } from '@hotwired/stimulus';
import {
  computePosition,
  autoPlacement,
  shift,
  offset,
} from '@floating-ui/dom';

export default class extends Controller {
  static targets = ['button', 'container', 'input'];

  async connect() {
    const [EmojiMart, emojiMartData] = await Promise.all([
      import('emoji-mart'),
      import('@emoji-mart/data'),
    ]);

    const { translations } = this.containerTarget?.dataset;
    const i18n = translations ? JSON.parse(translations) : {};

    const picker = new EmojiMart.Picker({
      data: emojiMartData,
      theme: 'light',
      skinTonePosition: 'none',
      previewPosition: 'none',
      searchPosition: 'none',
      i18n,
      onEmojiSelect: (emoji) => {
        this.inputTarget.value = emoji.native;
        this.closePicker();
      },
      onClickOutside: () => {
        if (this.dialog.open) {
          this.closePicker();
        }
      },
    });

    this.dialog = document.createElement('dialog');
    this.dialog.classList.add('emoji-picker');

    this.dialog.appendChild(picker);
    document.getElementById('job-application-form').appendChild(this.dialog);
  }

  openPicker() {
    computePosition(this.element, this.dialog, {
      middleware: [autoPlacement(), offset(10), shift()],
    }).then(({ x, y }) => {
      Object.assign(this.dialog.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });

    this.dialog.show();
  }

  closePicker() {
    this.dialog.close();
  }

  toggle(event) {
    event.stopPropagation();

    if (this.dialog.open) {
      this.closePicker();
    } else {
      this.openPicker();
    }
  }
}
