import {
  exp,
  LineSymbolizer,
  PolygonSymbolizer,
  CenteredTextSymbolizer,
  CircleSymbolizer,
  GroupSymbolizer,
  LineLabelSymbolizer,
  OffsetTextSymbolizer,
  PolygonLabelSymbolizer,
  ShieldSymbolizer,
} from 'protomaps-leaflet';

export const theme = {
  earth: '#FFFBF6',
  glacier: '#ffffff',
  residential: '#F4F4F8',
  hospital: '#FFF6F6',
  cemetery: '#EFF2EE',
  school: '#F7F6FF',
  industrial: '#FFF9EF',
  wood: '#F4F9EF',
  grass: '#EBF9E3',
  park: '#E5F9D5',
  water: '#B7DFF2',
  sand: '#ebebeb',
  buildings: '#F2EDE8',
  highwayCasing: '#FFC3C3',
  majorRoadCasing: '#FFB9B9',
  mediumRoadCasing: '#FFCE8E',
  minorRoadCasing: '#cccccc',
  highway: '#FFCEBB',
  majorRoad: '#FFE4B3',
  mediumRoad: '#FFF2C8',
  minorRoad: '#ffffff',
  boundaries: '#9e9e9e',
  mask: '#dddddd',
  countryLabel: '#aaaaaa',
  cityLabel: '#6C6C6C',
  stateLabel: '#999999',
  neighbourhoodLabel: '#888888',
  landuseLabel: '#898989',
  waterLabel: '#41ABDC',
  naturalLabel: '#4B8F14',
  roadsLabel: '#888888',
  poisLabel: '#606060',
};

export function paintRules() {
  return [
    {
      dataLayer: 'water',
      symbolizer: new PolygonSymbolizer({
        fill: theme.water,
      }),
    },
    {
      dataLayer: 'earth',
      symbolizer: new PolygonSymbolizer({
        fill: theme.earth,
      }),
    },
    {
      dataLayer: 'natural',
      symbolizer: new PolygonSymbolizer({
        fill: theme.glacier,
      }),
      filter: (z, f) => {
        return f.props.natural == 'glacier';
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonSymbolizer({
        fill: theme.residential,
      }),
      filter: (z, f) => {
        return (
          f.props.landuse == 'residential' || f.props.place == 'neighbourhood'
        );
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonSymbolizer({
        fill: theme.hospital,
      }),
      filter: (z, f) => {
        return f.props.amenity == 'hospital';
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonSymbolizer({
        fill: theme.cemetery,
      }),
      filter: (z, f) => {
        return f.props.landuse == 'cemetery';
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonSymbolizer({
        fill: theme.school,
      }),
      filter: (z, f) => {
        return (
          f.props.amenity == 'school' ||
          f.props.amenity == 'kindergarten' ||
          f.props.amenity == 'university' ||
          f.props.amenity == 'college'
        );
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonSymbolizer({
        fill: theme.industrial,
      }),
      filter: (z, f) => {
        return f.props.landuse == 'industrial';
      },
    },
    {
      dataLayer: 'natural',
      symbolizer: new PolygonSymbolizer({
        fill: theme.wood,
      }),
      filter: (z, f) => {
        return f.props.natural == 'wood';
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonSymbolizer({
        fill: theme.grass,
      }),
      filter: (z, f) => {
        return f.props.landuse == 'grass';
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonSymbolizer({
        fill: theme.park,
      }),
      filter: (z, f) => {
        return f.props.leisure == 'park';
      },
    },
    {
      dataLayer: 'water',
      symbolizer: new PolygonSymbolizer({
        fill: theme.water,
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'lake';
      },
    },
    {
      dataLayer: 'natural',
      symbolizer: new PolygonSymbolizer({
        fill: theme.sand,
      }),
      filter: (z, f) => {
        return f.props.natural == 'sand';
      },
    },
    {
      dataLayer: 'buildings',
      symbolizer: new PolygonSymbolizer({
        fill: theme.buildings,
      }),
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.highwayCasing,
        width: exp(1.4, [
          [5, 1.5],
          [11, 4],
          [16, 9],
          [20, 40],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'highway';
      },
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.majorRoadCasing,
        width: exp(1.4, [
          [9, 3],
          [12, 4],
          [17, 8],
          [20, 22],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'major_road';
      },
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.mediumRoadCasing,
        width: exp(1.4, [
          [13, 3],
          [17, 6],
          [20, 18],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'medium_road';
      },
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.minorRoadCasing,
        width: exp(1.4, [
          [14, 2],
          [17, 5],
          [20, 15],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'minor_road';
      },
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.minorRoad,
        width: exp(1.4, [
          [14, 1],
          [17, 3],
          [20, 13],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'minor_road';
      },
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.mediumRoad,
        width: exp(1.4, [
          [13, 2],
          [17, 4],
          [20, 15],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'medium_road';
      },
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.majorRoad,
        width: exp(1.4, [
          [9, 2],
          [12, 3],
          [17, 6],
          [20, 20],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'major_road';
      },
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineSymbolizer({
        color: theme.highway,
        width: exp(1.4, [
          [5, 0.5],
          [11, 2.5],
          [16, 7],
          [20, 30],
        ]),
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'highway';
      },
    },
    {
      dataLayer: 'boundaries',
      symbolizer: new LineSymbolizer({
        color: theme.boundaries,
        width: 2,
        opacity: 0.4,
      }),
    },
    {
      dataLayer: 'mask',
      symbolizer: new PolygonSymbolizer({
        fill: theme.mask,
      }),
    },
  ];
}

export function labelRules() {
  return [
    {
      dataLayer: 'places',
      symbolizer: new CenteredTextSymbolizer({
        label_props: ['name'],
        fill: theme.countryLabel,
        lineHeight: 1.5,
        font: (z) => {
          if (z < 6) return '200 16px sans-serif';
          return '200 20px sans-serif';
        },
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'country';
      },
    },
    {
      dataLayer: 'places',
      symbolizer: new CenteredTextSymbolizer({
        label_props: ['name'],
        fill: theme.stateLabel,
        font: '300 16px sans-serif',
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'state';
      },
    },
    {
      id: 'cities_high',
      dataLayer: 'places',
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'locality';
      },
      minzoom: 7,
      symbolizer: new CenteredTextSymbolizer({
        label_props: ['name'],
        fill: theme.cityLabel,
        font: (z, f) => {
          if (f?.props['pmap:rank'] === 1) {
            if (z > 8) return '600 20px sans-serif';
            return '600 14px sans-serif';
          } else {
            if (z > 8) return '600 16px sans-serif';
            return '600 12px sans-serif';
          }
        },
      }),
      sort: (a, b) => {
        return a['pmap:rank'] - b['pmap:rank'];
      },
    },
    {
      id: 'cities_low',
      dataLayer: 'places',
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'locality';
      },
      maxzoom: 6,
      symbolizer: new GroupSymbolizer([
        new CircleSymbolizer({
          radius: 2,
          fill: theme.cityLabel,
          opacity: 0.7,
        }),
        new OffsetTextSymbolizer({
          label_props: ['name'],
          fill: theme.cityLabel,
          offsetX: 3,
          offsetY: -1,
          font: (z, f) => {
            if (f?.props['pmap:rank'] === 1) {
              if (z > 8) return '600 20px sans-serif';
              return '600 14px sans-serif';
            } else {
              if (z > 8) return '600 16px sans-serif';
              return '600 12px sans-serif';
            }
          },
        }),
      ]),
      sort: (a, b) => {
        return a['pmap:rank'] - b['pmap:rank'];
      },
    },
    {
      id: 'neighbourhood',
      dataLayer: 'places',
      symbolizer: new CenteredTextSymbolizer({
        label_props: ['name'],
        fill: theme.neighbourhoodLabel,
        font: '500 10px sans-serif',
        textTransform: 'uppercase',
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'neighbourhood';
      },
    },
    {
      dataLayer: 'landuse',
      symbolizer: new PolygonLabelSymbolizer({
        label_props: ['name'],
        fill: theme.landuseLabel,
        font: '300 12px sans-serif',
      }),
    },
    {
      dataLayer: 'water',
      symbolizer: new PolygonLabelSymbolizer({
        label_props: ['name'],
        fill: theme.waterLabel,
        font: 'italic 600 12px sans-serif',
      }),
    },
    {
      dataLayer: 'natural',
      symbolizer: new PolygonLabelSymbolizer({
        label_props: ['name'],
        fill: theme.naturalLabel,
        font: 'italic 300 12px sans-serif',
      }),
    },
    {
      dataLayer: 'roads',
      symbolizer: new LineLabelSymbolizer({
        label_props: ['name'],
        fill: theme.roadsLabel,
        font: '500 12px sans-serif',
      }),
      minzoom: 12,
    },
    {
      dataLayer: 'roads',
      symbolizer: new ShieldSymbolizer({
        label_props: ['ref'],
        font: '600 9px sans-serif',
        background: theme.highway,
        padding: 2,
        fill: theme.neighbourhoodLabel,
      }),
      filter: (z, f) => {
        return f.props['pmap:kind'] == 'highway';
      },
    },
    {
      dataLayer: 'pois',
      symbolizer: new GroupSymbolizer([
        new CircleSymbolizer({
          radius: 2,
          fill: theme.poisLabel,
        }),
        new OffsetTextSymbolizer({
          label_props: ['name'],
          fill: theme.poisLabel,
          offsetX: 2,
          offsetY: 2,
          font: '300 10px sans-serif',
        }),
      ]),
    },
  ];
}
