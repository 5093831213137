import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'submit',
    'submitText',
    'submitLoader',
    'skipLink',
    'editorSubmit',
  ];
  static values = { submitOnEnter: Boolean, editor: Boolean };

  initialFormState = null;
  submitted = false;
  scrollPositionY = null;

  connect() {
    this.setInitialFormState();

    this.element.addEventListener(
      'turbo:before-fetch-request',
      this.handleBeforeRequest
    );
    this.element.addEventListener(
      'turbo:before-fetch-response',
      this.handleBeforeResponse
    );

    if (this.hasSkipLinkTarget) {
      this.element.addEventListener(
        'turbo:submit-start',
        this.handleSubmitStart
      );
    }

    if (this.element.dataset.confirmLeaveMessage) {
      window.addEventListener('beforeunload', this.confirmLeave);
      document.addEventListener('turbo:before-visit', this.confirmBeforeVisit);
    }

    if (this.submitOnEnterValue) {
      this.element.addEventListener('keydown', this.checkIfSubmit.bind(this));
    }
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.confirmLeave);
    document.removeEventListener('turbo:before-visit', this.confirmBeforeVisit);
    document.removeEventListener('turbo:load', this.persistScroll);
  }

  setInitialFormState() {
    this.initialFormState = this.currentFormState;
  }

  formValuesChanged() {
    return this.initialFormState !== this.currentFormState;
  }

  checkIfSubmit(event) {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (this.editorValue && this.hasEditorSubmitTarget) {
        this.editorSubmitTarget.click();
      } else if (this.element.reportValidity()) {
        this.element.requestSubmit();
      }
    }
  }

  handleSubmitStart = (e) => {
    this.showLoader();
    if (!this.formValuesChanged()) {
      e.preventDefault();
      this.skipLinkTarget.click();
    }
  };

  handleBeforeRequest = (e) => {
    if (this.element !== e.target) {
      return false;
    }

    this.submitted = true;
    this.showLoader();
    this.submitTarget.disabled = true;
    this.scrollPositionY = window.scrollY;
  };

  handleBeforeResponse = () => {
    this.showDefault();
    this.submitTarget.disabled = false;
  };

  showLoader() {
    this.submitTextTarget.classList.add('opacity-0');
    this.submitTextTarget.classList.remove('opacity-100');
    this.submitLoaderTarget.classList.add('opacity-100');
    this.submitLoaderTarget.classList.remove('opacity-0');
  }

  showDefault() {
    this.submitTextTarget.classList.remove('opacity-0');
    this.submitTextTarget.classList.add('opacity-100');
    this.submitLoaderTarget.classList.remove('opacity-100');
    this.submitLoaderTarget.classList.add('opacity-0');
  }

  confirmLeave = (e) => {
    if (this.submitted) {
      return false;
    }

    if (this.formValuesChanged()) {
      e.preventDefault();
      e.returnValue = this.element.dataset.confirmLeaveMessage;
      return this.element.dataset.confirmLeaveMessage;
    }
  };

  confirmBeforeVisit = (e) => {
    if (this.submitted) {
      return false;
    }

    if (this.formValuesChanged()) {
      const result = confirm(this.element.dataset.confirmLeaveMessage);
      if (!result) {
        e.preventDefault();
      }
    }
  };

  persistScroll = () => {
    if (this.element.dataset.persistScroll && this.scrollPositionY !== null) {
      window.scroll(0, this.scrollPositionY);
      this.scrollPositionY = null;
    }

    let elementWithError = document.getElementsByClassName(
      'field_with_errors'
    )[0];
    if (elementWithError) {
      elementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  get currentFormState() {
    return JSON.stringify(this.formValues);
  }

  get formValues() {
    const valuesIterator = new FormData(this.element).values();
    const valuesArray = Array.from(valuesIterator).filter(Boolean);
    valuesArray.splice(valuesArray.indexOf(this.authenticityToken), 1);

    return valuesArray;
  }

  get authenticityToken() {
    try {
      return new FormData(this.element).get('authenticity_token');
    } catch {
      return '';
    }
  }
}
