import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      'turbo:before-morph-attribute',
      this.handleBodyStyle
    );
  }

  handleBodyStyle = (e) => {
    // Prevent the body style from being morphed to keep connect candidate color
    if (e.detail.attributeName == 'style' && e.target.tagName === 'BODY') {
      e.preventDefault();
    }
  };

  disconnect() {
    this.element.removeEventListener(
      'turbo:before-morph-attribute',
      this.handleBodyStyle
    );
  }
}
