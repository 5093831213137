import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['searchContainer', 'submitButton'];

  static values = {
    searchQuery: String,
  };

  toggleSearchStates() {
    if (this.hasSearchContainerTarget) {
      this.toggleSearchContainerState();
      this.toggleSubmitButtonState();
    }
  }

  toggleSearchContainerState() {
    this.searchContainerTarget.classList.toggle(
      'bg-company-primary-text',
      this.hasQueryValue
    );
    this.searchContainerTarget.classList.toggle(
      'bg-opacity-3',
      this.hasQueryValue
    );
  }

  toggleSubmitButtonState(focus = false) {
    this.submitButtonTarget.classList.toggle(
      'hidden',
      !this.hasQueryValue && !focus
    );
  }

  handleSearchInputChange(e) {
    this.searchQueryValue = e.target.value;
  }

  handleSearchInputBlur() {
    this.toggleSubmitButtonState(false);
  }

  handleSearchInputFocus() {
    this.toggleSubmitButtonState(true);
  }

  handleBefore = () => {
    this.element.querySelectorAll("input[type='hidden']").forEach((input) => {
      input.disabled = input.value === '';
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    const inputTarget = this.element.querySelector(
      `input[name=${e.target.dataset.field}]`
    );
    inputTarget.value = e.target.dataset.value || '';
    this.element.requestSubmit();
  };

  searchQueryValueChanged() {
    this.toggleSearchStates(this.searchQueryValue);
  }

  get searchQuery() {
    return this.searchQueryValue;
  }

  get hasQueryValue() {
    return this.searchQuery != '';
  }
}
