import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'fieldset', 'skipped', 'editorSubmit'];
  static values = { editor: Boolean };

  initialFormState = null;
  hasChanged = false;

  connect() {
    this.formTarget.addEventListener('submit', this.beforeSubmit);
    this.formTarget.addEventListener(
      'turbo:submit-start',
      this.handleSubmitStart
    );
    this.formTarget.addEventListener(
      'turbo:before-fetch-response',
      this.handleBeforeResponse
    );

    if (this.hasFormTarget) {
      this.formTarget.addEventListener('keyup', this.checkIfSubmit.bind(this));
      this.initialFormState = this.currentFormState;
    }
  }

  disconnect() {
    this.formTarget.removeEventListener('submit', this.beforeSubmit);
    this.formTarget.removeEventListener(
      'turbo:submit-start',
      this.handleSubmitStart
    );
    this.formTarget.removeEventListener(
      'turbo:before-fetch-response',
      this.handleBeforeResponse
    );
  }

  checkIfSubmit(event) {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (this.editorValue && this.hasEditorSubmitTarget) {
        this.editorSubmitTarget.click();
      } else if (this.formTarget.reportValidity()) {
        this.formTarget.requestSubmit();
      }
    }
  }

  beforeSubmit = (event) => {
    if (this.editorValue) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.formValuesChanged() && !this.hasChanged) {
      this.skippedTarget.value = 'true';
    }
  };

  handleSubmitStart = () => {
    if (this.hasFieldsetTarget) {
      this.fieldsetTarget.toggleAttribute('disabled', true);
    }
  };

  handleBeforeResponse = () => {
    if (this.hasFieldsetTarget) {
      this.fieldsetTarget.toggleAttribute('disabled', false);
    }
  };

  handleChange() {
    this.hasChanged = true;
  }

  formValuesChanged() {
    return this.initialFormState !== this.currentFormState;
  }

  get currentFormState() {
    const valuesIterator = new FormData(this.formTarget).values();
    const valuesArray = Array.from(valuesIterator).filter(Boolean);
    return JSON.stringify(valuesArray);
  }
}
