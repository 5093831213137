import { Controller } from '@hotwired/stimulus';

export default class MeetingEdit extends Controller {
  static values = {
    locale: String,
    elementToken: String,
    availabilityParams: Object,
    inviteUuid: String,
    tzid: String,
    dataCenter: String,
    timezones: Array,
    error: String,
  };

  static targets = [
    'noSlotContainer',
    'cronofy',
    'cantPickAny',
    'errorContainer',
  ];

  static classes = ['show', 'hide'];

  slotPicker;

  slotPickerOptions;

  async connect() {
    if (this.errorValue === 'NO_SLOTS') {
      this.noSlots();
      return;
    }

    if (this.elementTokenValue) {
      let currentTimezone;
      try {
        currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        // Browser does not support or block it
      }

      this.noSlotContainerTarget.classList.add(this.hideClass);
      const { DateTimePicker } = await import(
        /* webpackChunkName: 'cronofy-elements' */ 'cronofy-elements'
      );
      this.slotPickerOptions = {
        element_token: this.elementTokenValue,
        target_id: 'cronofy-slot-picker',
        availability_query: this.availabilityParamsValue,
        styles: {
          prefix: 'tt-slot-picker',
        },
        data_center: this.dataCenterValue,
        locale: this.getCalendarLocale(),
        tzid: currentTimezone || this.tzidValue,
        callback: (notification) => {
          this.handleNotification(notification);
        },
      };
      this.slotPicker = DateTimePicker(this.slotPickerOptions);
    } else {
      this.showError();
    }
  }

  noSlots = () => {
    this.noSlotContainerTarget.classList.remove(this.hideClass);
    this.noSlotContainerTarget.classList.add(this.showClass);
    this.cantPickAnyTarget.classList.add(this.hideClass);
    this.cronofyTarget.classList.add(this.hideClass);
    this.errorContainerTarget.classList.add(this.hideClass);
  };

  showError = () => {
    this.noSlotContainerTarget.classList.add(this.hideClass);
    this.cantPickAnyTarget.classList.add(this.hideClass);
    this.cronofyTarget.classList.add(this.hideClass);
    this.errorContainerTarget.classList.remove(this.hideClass);
    this.errorContainerTarget.classList.add(this.showClass);
  };

  getCalendarLocale = () => {
    const cronofySupportedLocales = [
      'en',
      'es',
      'fr',
      'de',
      'it',
      'nl',
      'fr-CA',
      'ja',
      'ru',
      'sv',
    ];
    if (cronofySupportedLocales.indexOf(this.localeValue) !== -1) {
      return this.localeValue;
    }

    return 'en';
  };

  handleNotification = ({ notification }) => {
    if (notification.type === 'slot_selected') {
      fetch('/schedule/' + this.inviteUuidValue + '/confirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(notification),
      }).then((response) => {
        if (response.ok) {
          window.location = '/schedule/' + this.inviteUuidValue;
        } else {
          this.showError();
        }
      });
    } else if (notification.type === 'no_slots_found') {
      this.noSlots();
    }
  };
}
