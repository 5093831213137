import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const url = new URL(location.href);
    if (url.searchParams.has('track')) {
      url.searchParams.delete('track');
      if (typeof history.replaceState === 'undefined') {
        document.location = url.href;
      } else {
        history.replaceState({}, document.title, url.href);
      }
    }
  }
}
