import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['previousButton', 'nextButton', 'pagination'];

  async connect() {
    const [
      { default: Swiper, Navigation, Keyboard, Pagination },
    ] = await Promise.all([
      import(/* webpackChunkName: 'swiper' */ 'swiper'),
      import(/* webpackChunkName: 'swiper-style' */ 'swiper/css/bundle'),
    ]);

    this.swiper = new Swiper(this.element, {
      modules: [Navigation, Keyboard, Pagination],
      spaceBetween: 100,
      slidesPerView: 1,
      centeredSlides: true,
      slideActiveClass: 'px-4 py-20 md:px-[25%]',
      keyboard: {
        enabled: true,
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.previousButtonTarget,
        disabledClass: '!hidden',
        hiddenClass: '!hidden',
        navigationDisabledClass: '!hidden',
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        type: 'bullets',
        bulletClass: 'swiper-pagination-bullet !bg-candidate-connect-color',
      },
    });
  }

  setActiveItem(value) {
    this.swiper.slideTo(value, 0, false);
  }
}
