import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { isEqual } from 'lodash-es';

export default class extends Controller {
  static targets = ['labels', 'headline', 'selectedOptionLabelContainer'];
  static values = {
    selectedOption: String,
    initialLabel: String,
    targetField: String,
    defaultLabel: String,
    isGeoboundFilterPresent: Boolean, // Only possible to be true for Region, Country and Location filters
    mapAreaTranslation: String,
  };
  static outlets = ['blocks--jobs--filters'];

  connect() {
    useClickOutside(this, { events: ['click'] });
  }

  clickOutside() {
    this.toggleClose();
  }

  selectedOptionLabelContainerTargetConnected(element) {
    element.innerHTML = this.isGeoboundFilterPresentValue
      ? this.mapAreaTranslationValue
      : this.initialLabelValue || this.defaultLabelValue;
  }

  toggleWidget() {
    if (this.labelsTarget.classList.contains('hidden')) this.toggleOpen();
    else this.toggleClose();
  }

  toggleOpen() {
    this.labelsTarget.classList.remove('hidden');
    this.headlineTarget.classList.add('hidden');
  }

  toggleClose() {
    this.labelsTarget.classList.add('hidden');
    this.headlineTarget.classList.remove('hidden');
  }

  setJobsFilterFormInput(value) {
    if (!this.hasTargetFieldValue) return;

    this.blocksJobsFiltersOutlet.setInputValue({
      field: this.targetFieldValue,
      value: value,
    });
    this.blocksJobsFiltersOutlet.resetCoordinateTargets(this.targetFieldValue);
  }

  highlightOption(buttonTarget) {
    const highlightedButtonTarget = this.element.querySelector(
      'button.border-company-link'
    );
    if (highlightedButtonTarget)
      this.unhighlightOption(highlightedButtonTarget);
    buttonTarget.classList.add('border-company-link');
    buttonTarget.classList.remove('border-transparent');
    buttonTarget.querySelector('div.icon-wrapper').classList.remove('hidden');
  }

  unhighlightOption(buttonTarget) {
    buttonTarget.classList.add('border-transparent');
    buttonTarget.classList.remove('border-company-link');
    buttonTarget.querySelector('div.icon-wrapper').classList.add('hidden');
  }

  selectOption(label, value) {
    this.selectedOptionValue = value;
    this.selectedOptionLabelContainerTarget.innerHTML = label;

    if (this.hasBlocksJobsFiltersOutlet)
      this.setJobsFilterFormInput(this.selectedOptionValue);
  }

  toggleOption(e) {
    let buttonTarget;

    if (e.target.tagName !== 'button')
      buttonTarget = e.target.closest('button');
    else buttonTarget = e.target;

    if (this.selectedOptionValue === e.params.value.toString()) {
      this.handleFiltersContentVisibilityOnDelete();
      this.selectOption(this.defaultLabelValue, ''); // unselect selected option
      this.unhighlightOption(buttonTarget);
    } else {
      this.handleFiltersContentVisibilityOnAdd(e.target.dataset.labelValue);
      this.selectOption(
        buttonTarget.getAttribute('data-label-value'),
        e.params.value
      );
      this.highlightOption(buttonTarget);
    }

    this.blocksJobsFiltersOutlet.handleMobileSelectedLocationFilters(
      this.targetFieldValue
    );
    e.stopPropagation();
  }

  handleFiltersContentVisibilityOnDelete() {
    if (
      Object.values(this.blocksJobsFiltersOutlet.visibleMobileFiltersValue)
        .length === 1
    ) {
      this.blocksJobsFiltersOutlet.visibleMobileFiltersValue = this.blocksJobsFiltersOutlet.defaultMobileFiltersValue;
    } else {
      // eslint-disable-next-line no-unused-vars
      const {
        // eslint-disable-next-line no-unused-vars
        [this.targetFieldValue]: _,
        ...visibleMobileFilters
      } = this.blocksJobsFiltersOutlet.visibleMobileFiltersValue;
      this.blocksJobsFiltersOutlet.visibleMobileFiltersValue = visibleMobileFilters;
    }
  }

  handleFiltersContentVisibilityOnAdd(labelValue) {
    if (
      isEqual(
        this.blocksJobsFiltersOutlet.visibleMobileFiltersValue,
        this.blocksJobsFiltersOutlet.defaultMobileFiltersValue
      )
    ) {
      this.blocksJobsFiltersOutlet.visibleMobileFiltersValue = {
        [this.targetFieldValue]: labelValue,
      };
    } else {
      this.blocksJobsFiltersOutlet.visibleMobileFiltersValue = {
        ...this.blocksJobsFiltersOutlet.visibleMobileFiltersValue,
        [this.targetFieldValue]: labelValue,
      };
    }
  }

  reset() {
    const buttonTarget = this.element.querySelector(
      'button.border-company-link'
    );

    this.selectOption(this.defaultLabelValue, ''); // unselect selected option
    if (buttonTarget) {
      this.unhighlightOption(buttonTarget);
    }

    this.resetMobileFilterContent();
  }

  resetMobileFilterContent() {
    if (
      !isEqual(
        this.blocksJobsFiltersOutlet.visibleMobileFiltersValue,
        this.blocksJobsFiltersOutlet.defaultMobileFiltersValue
      )
    ) {
      this.blocksJobsFiltersOutlet.visibleMobileFiltersValue = this.blocksJobsFiltersOutlet.defaultMobileFiltersValue;
    }
  }
}
