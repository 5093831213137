import { Controller } from '@hotwired/stimulus';

const LOCATION_FIELDS = ['location', 'region', 'country'];
const COORDINATE_FIELDS = [
  'geoboundTopLeftLat',
  'geoboundTopLeftLon',
  'geoboundBottomRightLat',
  'geoboundBottomRightLon',
];

export default class extends Controller {
  static outlets = ['common--options-widget'];
  static targets = [
    'searchContainer',
    'submitButton',
    'searchInput',
    'regionInput',
    'locationInput',
    'countryInput',
    ...COORDINATE_FIELDS,
  ];

  static classes = ['desktopDropdownOptionsDivider'];

  static values = {
    searchQuery: String,
    interactiveMap: Boolean,
    hasSearch: Boolean,
    usingMap: Boolean,
    visibleMobileFilters: Object,
    defaultMobileFilters: Object,
    moreCountText: String,
    locale: String,
  };

  connect() {
    if (this.hasSearchValue) {
      this.setInputWidth();
    }
    if (this.interactiveMapValue) {
      this.getFacetedSearchData();
    }
  }

  getFacetedSearchData = () => {
    const localeString = this.localeValue ? `/${this.localeValue}` : '';
    const queryString = new URL(window.location.href).search;
    fetch(`${localeString}/jobs/faceted_search_data${queryString}`)
      .then((response) => response.json())
      .then((data) => {
        this.fillDropdownsWithFacetedSearchCounts(data.faceted_search_counts);
      })
      .catch(() => {});
  };

  fillDropdownsWithFacetedSearchCounts = (faceted_search_counts) => {
    // Maybe Fill based on screen width?
    this.fillDesktopDropdowns(faceted_search_counts);
    this.fillMobileDropdowns(faceted_search_counts);
  };

  fillDesktopDropdowns = (faceted_search_counts) => {
    for (const filterKey in faceted_search_counts) {
      // Get matching dropdown div
      const dropdownDiv = this.element.querySelector(
        `[data-common--dropdown-id-value="${filterKey}-dropdown"]`
      );

      if (!dropdownDiv) continue; // If company filter is visible, extra faceted search data might be retrieved

      // Get dropdown options div
      const dropdownOptionsDiv = dropdownDiv.querySelector(
        '[data-common--dropdown-target="dropdown"]'
      );

      // Get dropdown options
      const options = dropdownOptionsDiv.querySelectorAll('button');

      const filterOptionsCounts = faceted_search_counts[filterKey];

      // Used to know if the span should be added between active and disabled buttons
      let hasDisabledOptions = false;

      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const option_value = option.getAttribute('data-value');

        // 'All' option
        if (i == 0) {
          option.querySelector(
            'span'
          ).innerHTML = `(${filterOptionsCounts['All']})`;
          continue;
        }

        // Faceted search data exists for this option
        if (filterOptionsCounts[option_value]) {
          const count = filterOptionsCounts[option_value];
          option.querySelector('span').innerHTML = `(${count})`;
        } else {
          // if it's the first disabled button
          if (!hasDisabledOptions) {
            hasDisabledOptions = true;
            this.getDesktopDropdownOptionsDivider(dropdownOptionsDiv);
          }
          // Remove from DOM
          option.remove();
          // Change it and add it back to DOM
          option.setAttribute('disabled', 'true');
          option.classList.add('opacity-50');
          option.classList.remove(
            'hover:bg-company-secondary',
            'hover:text-company-secondary'
          );
          option.style.cursor = 'not-allowed';
          option.querySelector('span').classList.remove('opacity-50');
          option.querySelector('span').innerHTML = `(0)`;
          dropdownOptionsDiv.appendChild(option);
        }
      }
    }
  };

  getDesktopDropdownOptionsDivider(dropdownOptionsDiv) {
    let span = document.createElement('span');
    span.classList.add(...this.desktopDropdownOptionsDividerClasses);
    dropdownOptionsDiv.appendChild(span);
  }

  fillMobileDropdowns = async (faceted_search_counts) => {
    for (const filterKey in faceted_search_counts) {
      // Get mobile filters div
      const mobileFiltersDiv = document.querySelector('#jobs-filters-mobile');

      // Get matching dropdown div
      const dropdownDiv = mobileFiltersDiv.querySelector(
        `[data-common--options-widget-target-field-value="${filterKey}"]`
      );

      // Get labels div
      const labelsDiv = dropdownDiv.querySelector(
        `[data-common--options-widget-target="labels"]`
      );

      // Get dropdown options div
      const dropdownOptionsDiv = labelsDiv.lastElementChild;

      // Get dropdown options
      const options = dropdownOptionsDiv.querySelectorAll('button');

      const filterOptionsCounts = faceted_search_counts[filterKey];
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const option_value = option.getAttribute(
          'data-common--options-widget-value-param'
        );

        // Faceted search data exists for this option
        if (filterOptionsCounts[option_value]) {
          const count = filterOptionsCounts[option_value];
          option.querySelector('span').innerHTML = `(${count})`;
          option.removeAttribute('disabled');
          option.classList.remove('opacity-50');
          option.querySelector('span').classList.add('opacity-50');
        } else {
          // Remove from DOM
          option.remove();
          // Change it and add it back to DOM
          option.setAttribute('disabled', 'true');
          option.classList.add('opacity-50');
          option.querySelector('span').innerHTML = `(0)`;
          option.querySelector('span').classList.remove('opacity-50');
          dropdownOptionsDiv.appendChild(option);
        }
      }
    }
  };

  toggleSearchStates() {
    if (this.hasSearchContainerTarget) {
      this.toggleSearchContainerState();
      this.toggleSubmitButtonState();
    }
  }

  toggleSearchContainerState() {
    if (!this.interactiveMapValue) {
      this.searchContainerTarget.classList.toggle(
        'bg-company-primary-text',
        this.hasQueryValue
      );
      this.searchContainerTarget.classList.toggle(
        'bg-opacity-3',
        this.hasQueryValue
      );
    }
  }

  toggleSubmitButtonState(focus = false) {
    this.submitButtonTarget.classList.toggle(
      'hidden',
      !this.hasQueryValue && !focus
    );
  }

  handleSearchInputChange(e) {
    this.searchQueryValue = e.target.value;
  }

  handleSearchInputBlur() {
    this.toggleSubmitButtonState(false);
    this.setInputWidth();
  }

  handleSearchInputFocus() {
    this.toggleSubmitButtonState(true);
    if (this.interactiveMapValue) {
      this.searchInputTarget.removeAttribute('size');
    }
  }

  handleBefore = () => {
    this.element.querySelectorAll("input[type='hidden']").forEach((input) => {
      input.disabled = input.value === '';
    });
  };

  handleExternalSubmit = () => {
    this.element.requestSubmit();
  };

  setInputValue = ({ field, value }) => {
    const inputTarget = this.element.querySelector(`input[name=${field}]`);
    inputTarget.value = value || '';
  };

  resetCoordinateTargets = (field) => {
    const resetCoordinateTargets =
      this.usingMapValue &&
      this.interactiveMapValue &&
      LOCATION_FIELDS.includes(field);

    if (resetCoordinateTargets) this.setCordinateTargetValue();
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setInputValue(e.target.dataset);
    this.resetCoordinateTargets(e.target.dataset.field);
    this.element.requestSubmit();
  };

  handleMapChange = (e) => {
    const { coordinates } = e.detail;
    this.setCordinateTargetValue(coordinates);
    this.resetInputTarget();
    this.element.requestSubmit();
    this.handleMobileSelectedLocationFilters();
  };

  // This method is used to handle the transition between selected locations and Map area
  handleMobileSelectedLocationFilters(targetField) {
    if (this.hasCommonOptionsWidgetOutlet) {
      const locationTargets = ['region', 'location', 'country'];
      if (targetField) {
        const filteredLocationTargets = locationTargets.filter(
          (locationTarget) => locationTarget !== targetField
        );
        this.commonOptionsWidgetOutlets.forEach((optionWidget) => {
          if (
            filteredLocationTargets.includes(optionWidget.targetFieldValue) &&
            optionWidget.selectedOptionLabelContainerTarget.innerHTML ===
              optionWidget.mapAreaTranslationValue
          ) {
            optionWidget.selectedOptionLabelContainerTarget.innerHTML =
              optionWidget.defaultLabelValue;
          }
        });
      } else {
        this.commonOptionsWidgetOutlets.forEach((optionWidget) => {
          if (locationTargets.includes(optionWidget.targetFieldValue)) {
            optionWidget.selectedOptionLabelContainerTarget.innerHTML =
              optionWidget.mapAreaTranslationValue;
          }
        });
      }
    }
  }

  resetInputTarget = () => {
    LOCATION_FIELDS.forEach((field) => {
      const inputTarget = this[`${field}InputTarget`];
      if (inputTarget && inputTarget.value) {
        inputTarget.value = null;
      }
    });
  };

  searchQueryValueChanged() {
    this.toggleSearchStates(this.searchQueryValue);
  }

  setInputWidth() {
    const shouldResizeSearchInput =
      this.interactiveMapValue && !this.searchInputTarget.value;

    if (shouldResizeSearchInput) {
      this.searchInputTarget.setAttribute(
        'size',
        this.searchInputTarget.getAttribute('placeholder').length
      );
    }
  }

  setCordinateTargetValue(coordinates) {
    COORDINATE_FIELDS.forEach((coordinate) => {
      const target = this[`${coordinate}Target`];
      target.value = coordinates ? coordinates[coordinate] : null;
    });
  }

  visibleMobileFiltersValueChanged() {
    if (
      this.activeFiltersContentElement &&
      this.extraActiveFiltersCountElement
    ) {
      const activeFilterValues = Object.values(this.visibleMobileFiltersValue);
      const inputValues = activeFilterValues
        .map((activeFilter) => activeFilter.trim())
        .filter(Boolean);
      const inputValuesLength = inputValues.length;
      if (inputValuesLength <= this.displayedFiltersCount) {
        this.setMobileFilterText(
          this.extractFiltersTextData(inputValues).content,
          ''
        );
        this.activeFiltersContentElement.classList.add('max-w-[90%]');
      } else {
        this.setMobileFilterText(
          this.extractFiltersTextData(inputValues).content,
          `· ${this.moreCountTextValue.replace(
            'nbr',
            this.extractFiltersTextData(inputValues).moreCount
          )}`
        );
        this.activeFiltersContentElement.classList.remove('max-w-[90%]');
      }
    }
  }

  setMobileFilterText(content, moreCountText) {
    if (this.activeFiltersContentElement) {
      this.activeFiltersContentElement.innerHTML = content;
    }

    if (this.extraActiveFiltersCountElement) {
      this.extraActiveFiltersCountElement.innerHTML = moreCountText;
    }
  }

  extractFiltersTextData(values) {
    return {
      content: values.slice(0, this.displayedFiltersCount).join(' · '),
      moreCount: Math.max(values.length - this.displayedFiltersCount, 0),
    };
  }

  get defaultMobileFilterText() {
    return this.extractFiltersTextData(
      Object.values(this.visibleMobileFiltersValue)
    );
  }

  get searchQuery() {
    return this.searchQueryValue;
  }

  get hasQueryValue() {
    return this.searchQuery != '';
  }

  get activeFiltersContentElement() {
    return this.element.querySelector('.activeFiltersContent');
  }

  get extraActiveFiltersCountElement() {
    return this.element.querySelector('.extraActiveFiltersCount');
  }

  get displayedFiltersCount() {
    return 2;
  }
}
