import { Controller } from '@hotwired/stimulus';

import './scale.scss';

export default class extends Controller {
  static targets = ['button', 'scale'];
  additionalFormData = {};

  connect() {
    this.updateFormData = this.updateFormData.bind(this);
    this.scaleTarget
      .closest('form')
      .addEventListener('formdata', this.updateFormData);
  }

  updateFormData(event) {
    const formDataEntries = Object.entries(this.additionalFormData);
    if (formDataEntries.length > 0) {
      formDataEntries.forEach(([key, value]) => {
        if (value === null) {
          event.formData.delete(key);
        } else {
          event.formData.set(key, value);
        }
      });
    }
  }

  addValue(target, value) {
    const answerAttr = target.closest('.question')?.firstElementChild?.name;
    const formData = {};
    formData[answerAttr.replace('form_question_id', 'numeric_value')] = value;
    this.additionalFormData = formData;

    const form = this.scaleTarget.closest('form');
    new FormData(form); //fire event listener
  }

  toggleActive(button, isActive) {
    if (isActive) {
      button.classList.remove('active');
      if (button.previousElementSibling) {
        button.previousElementSibling.classList.replace(
          'border-white',
          'border-gray-500'
        );
      }
    } else {
      if (button.previousElementSibling) {
        button.previousElementSibling.classList.replace(
          'border-gray-500',
          'border-white'
        );
      }
      button.classList.add('active');
    }
  }

  rate(event) {
    event.preventDefault();
    const target =
      event.target.tagName === 'SPAN'
        ? event.target.parentElement
        : event.target;

    let value = event.params.rating;
    const currentActive = this.buttonTargets.filter((button) => {
      return button.classList.contains('active');
    })?.[0];

    if (currentActive) {
      this.toggleActive(currentActive, true);
    }

    if (currentActive !== target) {
      this.toggleActive(target, false);
    } else {
      value = null;
    }

    this.addValue(target, value);
  }
}
