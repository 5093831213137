import { Turbo } from '@hotwired/turbo-rails';
import { Controller } from '@hotwired/stimulus';

const getModifiedUrlIfNeeded = (destinationUrl) => {
  if (
    location.search.includes('editor=true') &&
    !destinationUrl.includes('editor=true')
  ) {
    const parsed = new URL(destinationUrl);
    parsed.searchParams.set('editor', true);
    return parsed.toString();
  }

  return null;
};
export default class extends Controller {
  static values = {
    sectionId: String,
    scrollPosition: Number,
    pageId: String,
  };

  connect() {
    window.addEventListener('turbo:before-visit', this.handleTurboBeforeVisit);
    window.addEventListener(
      'turbo:before-render',
      this.handleTurboBeforeRender
    );
    window.addEventListener('turbo:render', this.handleTurboRender);
    window.addEventListener('message', this.handleMessage);
    document.addEventListener('turbo:load', this.handleTurboLoad);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mousemove', this.handleMouseMove);
    document.documentElement.addEventListener('click', this.handleClick);
    window.navigation?.addEventListener('navigate', this.handleNavigate);
  }

  disconnect() {
    window.removeEventListener(
      'turbo:before-visit',
      this.handleTurboBeforeVisit
    );
    window.removeEventListener(
      'turbo:before-render',
      this.handleTurboBeforeRender
    );
    window.addEventListener('turbo:render', this.handleTurboRender);
    window.removeEventListener('message', this.handleMessage);
    document.removeEventListener('turbo:load', this.handleTurboLoad);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousemove', this.handleMouseMove);
    document.documentElement.removeEventListener('click', this.handleClick);
    window.navigation?.removeEventListener('navigate', this.handleNavigate);
  }

  handleTurboBeforeRender = (event) => {
    const newPageId = event.detail.newBody.querySelector('#editor-controller')
      .dataset['careersite-EditorPageIdValue'];

    /**
     * dont set pageIdValue here, because it will change it in the cache of the from page
     * we do it in `turbo:render` event instead
     */
    this.newPageId = newPageId;
  };

  handleTurboRender = () => {
    if (this.newPageId !== this.pageIdValue) {
      this.pageIdValue = this.newPageId;
    }
  };

  handleNavigate = (event) => {
    // to catch navigations with back/forward button
    window.parent.postMessage(
      {
        message: 'navigateEvent',
        destinationUrl: event.destination.url,
      },
      '*'
    );
  };

  handleTurboBeforeVisit = (event) => {
    const modifiedUrlWithEditorParam = getModifiedUrlIfNeeded(event.detail.url);
    if (modifiedUrlWithEditorParam) {
      event.preventDefault();
      Turbo.visit(modifiedUrlWithEditorParam);
    }
  };

  handleClick = (event) => {
    const linkWithTurboDisabled = event.target.closest('a[data-turbo=false]');
    if (linkWithTurboDisabled) {
      const modifiedUrlWithEditorParam = getModifiedUrlIfNeeded(
        linkWithTurboDisabled.href
      );
      if (modifiedUrlWithEditorParam) {
        event.preventDefault();
        location = modifiedUrlWithEditorParam;
      }
    }
    window.parent.postMessage(
      {
        message: 'click',
        clientX: event.clientX,
        clientY: event.clientY,
        pointerType: event.pointerType,
      },
      '*'
    );
  };

  handleMouseMove = (event) => {
    this.mousePosition = {
      x: event.clientX,
      y: event.clientY,
    };

    const activeSectionEl = document
      .elementFromPoint(this.mousePosition.x, this.mousePosition.y)
      ?.closest('[data-section-model]');

    if (activeSectionEl?.dataset.sectionModel) {
      window.parent.postMessage(
        {
          message: 'mouseenter-section',
          sectionRect: activeSectionEl?.getBoundingClientRect(),
          sectionId: activeSectionEl?.dataset.sectionModel,
          sectionSubType: activeSectionEl?.dataset.sectionSubType,
          sectionName: activeSectionEl?.dataset.sectionName,
        },
        '*'
      );
    }
  };

  handleScroll = () => {
    let data = {};

    if (this.mousePosition) {
      const activeSectionEl = document
        .elementFromPoint(this.mousePosition.x, this.mousePosition.y)
        ?.closest('[data-section-model]');

      if (activeSectionEl?.dataset.sectionModel) {
        data.activeSectionRect = activeSectionEl?.getBoundingClientRect();
        data.sectionId = activeSectionEl?.dataset.sectionModel;
        data.sectionSubType = activeSectionEl?.dataset.sectionSubType;
        data.sectionName = activeSectionEl?.dataset.sectionName;
      }
    }

    window.parent.postMessage(
      {
        message: 'scroll',
        ...data,
      },
      '*'
    );
  };

  handleTurboLoad = () => {
    const section = getSectionElement(this.sectionIdValue);
    if (section) {
      this.scrollToSection(section);
      this.sectionIdValue = null;
    } else if (this.scrollPositionValue) {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: this.scrollPositionValue,
          behavior: 'smooth',
        });
        this.scrollPositionValue = null;
      }, 1000);
    }

    window.parent.postMessage({ message: 'iframeLoaded' }, '*');
  };

  handleMessage = async (e) => {
    const originHost = event.origin.substring(event.origin.indexOf('.') + 1);

    const host = window.location.hostname.substring(
      window.location.hostname.indexOf('.') + 1
    );

    if (originHost !== host) {
      return;
    }

    const data = this.parseData(e.data);

    if (data.reload) {
      if (data.sectionId) {
        await this.reloadSection(data.sectionId);
      } else {
        this.reload();
      }
    } else if (data.delete && data.sectionId) {
      await this.deleteSection(data.sectionId);
    } else if (data.sectionId) {
      const section = getSectionElement(data.sectionId);
      this.scrollToSection(section);
    } else if ('scrollAnchor' in data) {
      this.scrollToAnchor(data.scrollAnchor);
    }
  };

  scrollToAnchor(anchor) {
    const anchorElement = anchor
      ? document.getElementById(anchor)
      : document.body;
    window.requestAnimationFrame(() => {
      window.scroll({ top: anchorElement.offsetTop, behavior: 'smooth' });
    });
  }

  parseData(data) {
    try {
      return JSON.parse(data);
    } catch (err) {
      return data;
    }
  }

  reload(sectionId) {
    if (sectionId) {
      this.sectionIdValue = sectionId;
    }

    this.scrollPositionValue = window.scrollY;

    Turbo.visit(window.location, { action: 'replace' });
  }

  pageIdValueChanged() {
    window.parent.postMessage(
      {
        message: 'pageIdChanged',
        pageId: this.pageIdValue,
        currentUrl: window.location.href,
      },
      '*'
    );
  }

  async reloadSection(sectionId) {
    const response = await fetch(`/sections/${sectionId}?editor=true`);
    const html = await response.text();

    const section = getSectionElement(sectionId);

    if (section && html) {
      await this.replaceSection(section, sectionId, html);
    } else if (html) {
      this.reload(sectionId);
    }
  }

  async replaceSection(sectionElement, sectionId, html) {
    const container = document.createElement('div');
    container.innerHTML = html.trim();
    sectionElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    sectionElement.replaceWith(container.firstChild);
  }

  deleteSection(sectionId) {
    const section = getSectionElement(sectionId);
    if (section) {
      section.remove();
    }
  }

  scrollToSection(section) {
    if (section) {
      window.requestAnimationFrame(() => {
        section.scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
    }
  }
}

function getSectionElement(sectionId) {
  if (sectionId) {
    return document.getElementById(`section-${sectionId}`);
  } else {
    return null;
  }
}
