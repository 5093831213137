import { Controller } from '@hotwired/stimulus';

export default class UploadPreviewController extends Controller {
  static targets = [
    'urlInput',
    'progress',
    'name',
    'deleteInput',
    'idInput',
    'link',
  ];

  static values = {
    deleteName: String,
    downloadUrl: String,
    id: Number,
    model: String,
    name: String,
    number: Number,
    parent: String,
    url: String,
  };

  connect() {
    this.replaceInputFieldNames();
    if (this.element.classList.contains('dz-complete')) {
      this.showFileName();
    }
  }

  replaceInputFieldNames() {
    if (this.parentName) {
      this.urlInputTarget.setAttribute('name', this.inputName(this.name));
      this.idInputTarget.setAttribute('name', this.inputName('id'));
      this.deleteInputTarget.setAttribute(
        'name',
        this.inputName(this.deleteName)
      );
    }
  }

  inputName(fieldName) {
    if (this.parentName) {
      return `${this.parentName}[${fieldName}]`;
    }

    return undefined;
  }

  showFileName() {
    if (this.hasProgressTarget) {
      this.progressTarget.remove();
    }
    this.nameTarget.classList.remove('hidden');
    this.nameTarget.classList.add('flex');
  }

  numberValueChanged() {
    this.replaceInputFieldNames();
  }

  idValueChanged() {
    if (this.id) {
      this.idInputTarget.value = this.id;
      this.idInputTarget.disabled = false;
    }
  }

  urlValueChanged() {
    if (!this.id && this.url) {
      this.urlInputTarget.disabled = false;
      this.urlInputTarget.value = this.url;
    }
    if (this.url) {
      this.showFileName();
    }
  }

  downloadUrlValueChanged() {
    if (this.dowloadUrl) {
      this.linkTarget.classList.remove('pointer-events-none');
      this.linkTarget.href = this.dowloadUrl;
    }
  }

  get parentName() {
    if (this.parent && this.model) {
      return `${this.parent}[${this.model}][${this.number}]`;
    } else {
      return undefined;
    }
  }

  get parent() {
    return this.parentValue;
  }

  get model() {
    return this.modelValue;
  }

  get number() {
    return this.numberValue || 0;
  }

  get name() {
    return this.nameValue;
  }

  get deleteName() {
    return this.deleteNameValue;
  }

  get url() {
    return this.urlValue;
  }

  get dowloadUrl() {
    return this.downloadUrlValue;
  }

  get id() {
    return this.idValue > 0 ? this.idValue : undefined;
  }
}
