import { Controller } from '@hotwired/stimulus';
import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import { createFocusTrap } from 'focus-trap';

import { stopAllPlayers } from 'career/video_player';

export default class extends Controller {
  static targets = ['template', 'overlay'];
  static values = { open: Boolean };

  modal = null;
  playerContainer = null;

  connect() {
    this.createModal();
    document.addEventListener('keydown', this.handleKeydown);
  }

  disconnect() {
    this.destroyModal();
    document.removeEventListener('keydown', this.handleKeydown);
    this.focusTrap?.deactivate();
  }

  openValueChanged() {
    if (this.modal) {
      this.playerContainer.classList.toggle('scale-100', this.open);
      this.playerContainer.classList.toggle('scale-50', !this.open);
      this.modal.classList.toggle('opacity-0', !this.open);
      this.modal.classList.toggle('pointer-events-none', !this.open);
      this.body.style.overflow = this.open ? 'hidden' : '';
      this.body.style.paddingRight = this.open ? `${scrollbarWidth()}px` : '';

      stopAllPlayers();

      if (this.open) {
        this.focusTrap.activate();
      } else {
        this.focusTrap.deactivate();
      }
    }
  }

  createModal() {
    const node = this.template.cloneNode(true);
    this.modal = node.querySelector('div');
    this.playerContainer = this.modal.querySelector('[data-player-container]');
    this.modal
      .querySelectorAll('[data-close]')
      .forEach((element) =>
        element.addEventListener('click', this.toggleModal)
      );

    this.playerController = this.modal.querySelector(
      "[data-controller='common--video-player']"
    );

    this.body.appendChild(this.modal);

    this.focusTrap = createFocusTrap(this.modal, {
      onDeactivate: () => {
        if (this.open) {
          this.toggleModal();
        }
      },
    });
  }

  destroyModal() {
    this.modal
      .querySelectorAll('[data-close=true]')
      .forEach((element) =>
        element.removeEventListener('click', this.toggleModal)
      );
    this.modal.remove();
  }
  handleKeydown = (event) => {
    const isEscape =
      event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27;
    if (isEscape && this.open) {
      this.toggleModal();
    }
  };

  toggleModal = () => {
    this.openValue = !this.openValue;
  };

  get open() {
    return this.openValue;
  }

  get template() {
    return this.templateTarget.content;
  }

  get body() {
    return document.querySelector('body');
  }
}
