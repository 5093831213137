import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    noFrameRefresh: { type: Boolean, default: false },
  };

  connect() {
    this.element.addEventListener(
      'turbo:before-fetch-request',
      this.preventFrameRefresh
    );
  }

  disconnect() {
    this.element.removeEventListener(
      'turbo:before-fetch-request',
      this.preventFrameRefresh
    );
  }

  preventFrameRefresh = (event) => {
    const isLoading = event.target.querySelector('#spinner');
    if (
      this.noFrameRefreshValue &&
      event.target.tagName === 'TURBO-FRAME' &&
      !isLoading
    ) {
      event.preventDefault();
    }
  };
}
