import { Controller } from '@hotwired/stimulus';
import { observeElementVisible } from 'utils';

import './locations_map.scss';

export default class LocationsMap extends Controller {
  static values = {
    selectedLocation: String,
    locations: Array,
  };
  static targets = [
    'map',
    'locationSelect',
    'smallMarkerIcon',
    'largeMarkerIcon',
    'details',
  ];

  async connect() {
    observeElementVisible(this.element, () => {
      this.initializeMap();
    });
    if (this.hasLocationSelectTarget) {
      this.selectedLocationValueChanged();
    }
  }

  async initializeMap() {
    await Promise.all([
      import(/* webpackChunkName: 'leaflet-js' */ 'leaflet'),
      import(/* webpackChunkName: 'leaflet-css' */ 'leaflet/dist/leaflet.css'),
    ]);

    await Promise.all([
      import(
        /* webpackChunkName: 'leaflet-markercluster-js' */ 'leaflet.markercluster'
      ),
      import(
        /* webpackChunkName: 'leaflet-markercluster-css' */ 'leaflet.markercluster/dist/MarkerCluster.css'
      ),
    ]);

    // eslint-disable-next-line no-undef
    this.leaflet = L;

    if (this.hasLocationSelectTarget) {
      this.locationSelectTarget.addEventListener('change', (event) => {
        this.selectedLocationValue = event.target.value;
      });
    }

    this.map = this.leaflet.map(this.mapTarget, this.mapOptions);
    this.map.attributionControl.setPrefix(false);
    this.tileLayer.addTo(this.map);
    this.zoomControl.addTo(this.map);

    this.markers = {};

    this.markerClusterGroup = this.leaflet.markerClusterGroup({
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: false,
    });

    this.locationsValue.forEach(({ id, lat, long }) => {
      this.markers[id] = this.createMarker(id, lat, long);
    });

    this.map.addLayer(this.markerClusterGroup);

    this.positionMap(Object.values(this.markers));

    document.querySelectorAll('.leaflet-control-zoom a').forEach((element) => {
      element.setAttribute('tabindex', '-1');
    });
  }

  selectedLocationValueChanged() {
    if (!this.hasLocationSelectTarget) return;

    this.showLocationInfo(this.selectedLocationValue);
    if (this.hasLocationSelectTarget) {
      this.locationSelectTarget.value = this.selectedLocationValue;
    }
    if (this.markers && this.markers[this.selectedLocationValue]) {
      this.positionMap([this.markers[this.selectedLocationValue]]);
    }
  }

  showLocationInfo(locationId) {
    const src = this.detailsTarget.getAttribute('src');
    let url = new URL(src);
    url.searchParams.set('location_id', locationId);
    this.detailsTarget.setAttribute('src', url.toString());
  }

  get markerIcon() {
    return this.leaflet.divIcon({
      html: this.largeMarkerIconTarget.innerHTML,
      className: '',
      iconAnchor: [12, 32],
    });
  }

  createMarker(id, lat, long) {
    const marker = this.leaflet.marker([lat, long], {
      icon: this.markerIcon,
      keyboard: false,
    });

    marker.on('click', () => {
      this.selectedLocationValue = id;
    });

    this.markerClusterGroup.addLayer(marker);

    return marker;
  }

  positionMap(markers) {
    const group = new this.leaflet.featureGroup(markers);

    this.map.fitBounds(group.getBounds(), {
      paddingTopLeft: [10, 40],
      paddingBottomRight: [10, 10],
      maxZoom: 13,
    });
  }

  get tileLayer() {
    return this.leaflet.tileLayer(
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      {
        attribution: [
          '&copy; <a href="https://www.openstreetmap.org/copyright" tabindex="-1">OpenStreetMap</a>',
          ' &copy; <a href="https://carto.com/attribution" tabindex="-1">CARTO</a>',
        ],
      }
    );
  }

  get zoomControl() {
    return new this.leaflet.Control.Zoom({ position: 'topright' });
  }

  get mapOptions() {
    return {
      scrollWheelZoom: false,
      zoomControl: false,
      keyboard: false,
      touchZoom: true,
      doubleClickZoom: true,
      tap: false,
      dragging: !this.leaflet.Browser.mobile,
    };
  }
}
