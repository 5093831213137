import { Controller } from '@hotwired/stimulus';
import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import { createFocusTrap } from 'focus-trap';
import { isMobile } from 'utils/device';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['draggable', 'minimizeButton'];
  static classes = ['minimizeButtonHidden', 'overlay'];
  static values = {
    overlay: Boolean,
  };

  topOffset = 40;
  overlayDuration = 300;

  async connect() {
    this.element.style.setProperty('--topOffset', `${this.topOffset}px`);
    this.element.style.setProperty(
      '--overlayDuration',
      `${this.overlayDuration}ms`
    );

    import('./overlay.scss');

    this.element.addEventListener('animationend', () => {
      if (!this.overlayValue) {
        this.finishAnimateOut();
      }
    });

    if (this.hasDraggableTarget) {
      this.setupDraggable();
    }

    if (!isMobile()) {
      this.focusTrap = createFocusTrap(this.element, {
        allowOutsideClick: true,
        onDeactivate: () => {
          if (this.overlayValue) {
            this.closeOverlay();
          }
        },
      });
    }

    useClickOutside(this, { events: ['click'] });
  }

  disconnect() {
    this.focusTrap?.deactivate();
  }

  clickOutside() {
    this.closeOverlay();
  }

  animateIn() {
    // Make job ad not scrollable and add padding to the body to compensate scroll bar
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth()}px`;

    this.element.classList.remove('formOverlayAnimateOut');
    this.element.classList.add('formOverlayAnimateIn', ...this.overlayClasses);
    this.element.style.height = `calc(100vh - ${this.topOffset})`;

    requestAnimationFrame(() => {
      this.element.classList.add('formOverlayAnimationPlaying');
    });
  }

  startAnimateOut() {
    this.element.classList.remove('formOverlayAnimateIn');
    this.element.classList.add(
      'formOverlayAnimateOut',
      'formOverlayAnimationPlaying'
    );
    // Rest of teardown happens in finishAnimateOut which is called by transitionend handler above
  }

  finishAnimateOut() {
    this.element.classList.remove(
      ...this.overlayClasses,
      'formOverlayAnimationPlaying',
      'formOverlayAnimateOut'
    );
    this.element.style.height = '';

    // Make job ad scrollable again
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  }

  setupDraggable() {
    this.draggableTarget.addEventListener(
      'touchstart',
      () => {
        this.element.classList.remove(
          'formOverlayAnimationPlaying',
          'formOverlayAnimateIn'
        );
        this.element.classList.add('formOverlayAnimateOut');
      },
      false
    );

    this.draggableTarget.addEventListener(
      'touchmove',
      (event) => {
        const yPos = event.touches[0].clientY - this.topOffset;
        const time = (this.overlayDuration * yPos) / window.innerHeight;
        this.element.style.animationDelay = `-${time}ms`;
      },
      false
    );
    this.draggableTarget.addEventListener('touchend', () => {
      this.closeOverlay();
    });
  }

  overlayValueChanged(overlayValue, previousOverlayValue) {
    this.minimizeButtonTarget.classList.toggle(
      ...this.minimizeButtonHiddenClasses,
      !overlayValue
    );

    if (overlayValue) {
      this.animateIn();
      this.focusTrap?.activate();
    } else if (!overlayValue && previousOverlayValue === true) {
      this.startAnimateOut();
      this.focusTrap?.deactivate();
    }
  }

  closeOverlay() {
    const closeEvent = new CustomEvent('overlay-job-application-close');
    window.dispatchEvent(closeEvent);
  }
}
