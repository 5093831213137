import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    window.addEventListener(
      'message',
      (event) => {
        let data;
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          // Event data was not JSON, do nothing
        }

        if (data) {
          const params = new URLSearchParams(data);
          const baseUrl = this.element.src.split('?')[0];
          this.element.src = `${baseUrl}?${params.toString()}`;
        }
      },
      false
    );
  }
}
