import ApplicationController from 'controllers/share/application_controller';

export default class InputTextController extends ApplicationController {
  static targets = ['input'];

  connect() {
    this.inputTarget.addEventListener(
      'invalid',
      () => {
        this.inputTarget.classList.add(
          'InputText__input--onInvalidHasBeenTriggered'
        );
      },
      true
    );
  }
}
