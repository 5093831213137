import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['visible', 'hidden'];
  static values = {
    visible: Boolean,
  };

  visibleValueChanged(visibleValue) {
    this.element.style.visibility = '';
    this.element.classList.toggle(this.visibleClass, visibleValue);
    this.element.classList.toggle(this.hiddenClass, !visibleValue);
  }
}
