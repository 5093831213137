import { Controller } from '@hotwired/stimulus';
import { arrangeCollageItems } from 'utils/collage';

export default class TileLinkCollage extends Controller {
  static targets = ['link'];

  async connect() {
    await this.renderGallery();

    window.addEventListener('resize', this.renderGallery);
  }

  disconnect() {
    window.removeEventListener('resize', this.renderGallery);
  }

  renderGallery = async () => {
    const galleryHeight = await arrangeCollageItems(this.linkTargets);
    this.linkTargets.forEach((link) => {
      link.classList.add('animate__fadeIn');
      link.classList.remove('invisible');
    });
    this.element.style.height = `${galleryHeight}px`;
  };
}
