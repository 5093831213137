import { Controller } from '@hotwired/stimulus';

import oauthWindow from 'utils/oauthWindow';
import { generateSummaryMarkdown } from 'utils/linkedin';

export default class extends Controller {
  static values = { oauthUrl: String };
  static targets = ['previewSummary', 'summaryModal'];

  async connect() {
    // Set function global so "Apply with LinkedIn" widget can call it
    window.applyWithLinkedInCallback = this.applyWithLinkedInCallback.bind(
      this
    );

    window.addEventListener('message', this.handleLinkedInOauthCallback, false);
  }

  disconnect() {
    window.removeEventListener('message', this.handleLinkedInOauthCallback);
  }

  setJobApplicationFormValues(attributes) {
    const form = document.getElementById('job-application-form');
    form.setAttribute(
      'data-careersite--form-social-data-value',
      JSON.stringify(attributes)
    );
  }

  async applyWithLinkedInCallback(profileData) {
    const markdown = await generateSummaryMarkdown(profileData);
    this.setJobApplicationFormValues({
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      phoneNumber: profileData.phoneNumber,
      emailAddress: profileData.emailAddress,
      linkedinUid: profileData.memberIdentifier,
      profileImageUrl: profileData.profileImageUrl,
      linkedinUrl: profileData.publicProfileUrl,
      markdownSummary: markdown,
    });
  }

  linkedInOauthClicked() {
    this.window = oauthWindow(this.oauthUrlValue, 'LinkedIn authentication');
  }

  handleLinkedInOauthCallback = (event) => {
    if (this.window === event.source) {
      const {
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        email: emailAddress,
        linkedin_uid: linkedinUid,
        social_image_url: profileImageUrl,
      } = event.data;

      this.setJobApplicationFormValues({
        firstName,
        lastName,
        phoneNumber,
        emailAddress,
        linkedinUid,
        profileImageUrl,
      });
    }
  };
}
