import { Controller } from '@hotwired/stimulus';

import { isValidSongLink } from 'utils/song-embed';

const QUESTION_TYPES = {
  PRESET_SONG: 'Question::Preset::Song',
};

export default class extends Controller {
  static values = {
    type: String,
    unsupportedSongLinkString: String,
  };

  handleInput(event) {
    const value = event.target.value;

    if (this.typeValue === QUESTION_TYPES.PRESET_SONG) {
      if (!value || isValidSongLink(value)) {
        this.element.setCustomValidity('');
      } else {
        this.element.setCustomValidity(this.unsupportedSongLinkStringValue);
      }
    }
  }
}
