import { Controller } from '@hotwired/stimulus';
import { debounce } from 'debounce';

export default class extends Controller {
  static outlets = ['common--modal', 'blocks--jobs--filters'];
  static targets = ['searchField'];

  initialize() {
    this.handleSearchInputChange = debounce(
      this.handleSearchInputChange,
      500
    ).bind(this);
  }

  handleSearchInputChange(e) {
    if (this.hasBlocksJobsFiltersOutlet)
      this.blocksJobsFiltersOutlet.setInputValue({
        field: 'query',
        value: e.target.value,
      });
  }

  handleSubmit() {
    this.commonModalOutlet.toggleModal();
    const event = new CustomEvent('jobs-filters-mobile-submit');
    window.dispatchEvent(event);
  }

  resetAllWidgets() {
    const event = new CustomEvent('jobs-filters-mobile-reset-all');
    window.dispatchEvent(event);
  }

  resetSearchField() {
    this.searchFieldTarget.value = '';
    if (this.hasBlocksJobsFiltersOutlet)
      this.blocksJobsFiltersOutlet.setInputValue({
        field: 'query',
        value: '',
      });
  }
}
