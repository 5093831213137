import { Controller } from '@hotwired/stimulus';
import { observeElementVisible } from 'utils';

export default class extends Controller {
  static values = {
    lazyLoad: Boolean,
  };

  connect() {
    if (this.lazyLoadValue) {
      observeElementVisible(this.element, () => {
        this.element.setAttribute('src', this.element.getAttribute('data-src'));
      });
    }
  }
}
