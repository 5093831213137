import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['yes'];

  connect() {
    if (this.onIosDevice()) {
      this.yesTarget.oninvalid = () => this.radioIsInvalid();
    }
  }

  radioIsInvalid() {
    this.element.scrollIntoView();
  }

  onIosDevice() {
    return /iPhone|iPod|iPad/.test(navigator.platform);
  }
}
