import ApplicationController from 'controllers/share/application_controller';

export default class RangeController extends ApplicationController {
  static targets = ['input', 'handle', 'dot', 'hasBeenChanged'];

  async connect() {
    const bubble = document.createElement('div');
    bubble.classList.add('Range__bubble');

    const rangeInstance = this;

    // prettier-ignore
    const { default: rangeSlider } = await import(
      /* webpackChunkName: 'rangeslider' */ 'rangeslider-pure'
    );

    const hasBeenChangedTarget = this.hasBeenChangedTarget;

    rangeSlider.create(this.inputTarget, {
      polyfill: true,
      rangeClass: 'Range__track',
      handleClass: 'Range__handle',
      min: Number(this.inputTarget.min),
      max: Number(this.inputTarget.max),
      value: 0,
      onInit: function () {
        this.handle.appendChild(bubble);
      },
      onSlideEnd() {
        rangeInstance.active = false;
        bubble.classList.remove('isActive');
      },
      onSlideStart: function () {
        hasBeenChangedTarget.value = 'true';
      },
      onSlide: function (value) {
        bubble.textContent = value;

        if (!rangeInstance.active) {
          bubble.classList.add('hasInteraction');
          rangeInstance.active = true;
          setTimeout(() => {
            if (rangeInstance.active) {
              bubble.classList.add('isActive');
            }
          }, 150);
        }
      },
    });
  }
}
