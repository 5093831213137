import { Controller } from '@hotwired/stimulus';

const CREDENTIALS_KEY = 'credentials';

export default class extends Controller {
  static targets = ['tokenInput', 'firstName', 'lastName', 'email'];
  static values = {
    candidateEndpoint: String,
  };

  connect() {
    this.refreshToken();
    window.addEventListener('storage', this.onStorage, false);
  }

  disconnect() {
    window.removeEventListener('storage', this.onStorage);
  }

  onStorage = (data) => {
    if (data.key == CREDENTIALS_KEY) {
      this.refreshToken();
    }
  };

  refreshToken() {
    const { token } = this.credentials;
    if (token) {
      this.tokenInputTarget.value = token;
      this.fetchCandidateData(token);
    } else {
      this.tokenInputTarget.value = null;
    }
  }

  fetchCandidateData(token) {
    fetch(this.candidateEndpointValue + '/' + token)
      .then((response) => response.json())
      .then((data) => {
        const {
          candidate: { firstName, lastName, email },
        } = data;
        if (firstName && this.hasFirstNameTarget) {
          this.firstNameTarget.value = firstName;
        }
        if (lastName && this.hasLastNameTarget) {
          this.lastNameTarget.value = lastName;
        }
        if (email && this.hasEmailTarget) {
          this.emailTarget.value = email;
        }
      });
  }

  get credentials() {
    const credentialsString = localStorage.getItem(CREDENTIALS_KEY);
    if (credentialsString) {
      return JSON.parse(credentialsString);
    }
    return false;
  }
}
