import { Controller } from '@hotwired/stimulus';
import { some } from 'lodash-es';

export default class extends Controller {
  connect() {
    this.observer = new IntersectionObserver(
      (entries) => {
        const isIntersecting = some(entries, 'isIntersecting');
        if (isIntersecting) {
          this.element.classList.add('animate__fadeIn');
          this.observer.disconnect();
        }
      },
      {
        threshold: 0.5,
      }
    );

    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
  }
}
