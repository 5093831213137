import { Controller } from '@hotwired/stimulus';

import {
  acceptCookies,
  acceptedCookieCategories,
  disableAllCookies,
} from 'utils/cookies';

export default class extends Controller {
  static values = {
    checkedCategories: Array,
    insideIframe: Boolean,
  };

  static targets = ['categoryInput'];

  async connect() {
    import(/* webpackChunkName: 'cookie-preferences' */ './preferences.scss');

    this.element.addEventListener('cancel', (event) => {
      event.preventDefault();
    });
  }

  setCategoryInputs() {
    this.categoryInputTargets.forEach((input) => {
      input.checked = this.checkedCategoriesValue.includes(input.id);
    });
  }

  handleCategoryToggle({ target }) {
    if (target.checked) {
      this.checkedCategoriesValue = [...this.checkedCategoriesValue, target.id];
    } else {
      this.checkedCategoriesValue = this.checkedCategoriesValue.filter(
        (category) => category !== target.id
      );
    }
  }

  handleAccept() {
    acceptCookies(this.checkedCategoriesValue, this.insideIframeValue);
    this.close();
  }

  handleDecline() {
    disableAllCookies(this.insideIframeValue);
    this.close();
  }

  open() {
    this.saveUserScrollPosition();
    this.scrollPageToTopForModalToWorkInSafari();

    this.checkedCategoriesValue = acceptedCookieCategories();
    this.setCategoryInputs();
    this.element.showModal();
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.resetScrollToUsersOriginalPosition();
    this.element.close();
    document.body.style.overflow = '';
  }

  saveUserScrollPosition() {
    this.originalScrollPosition = document.documentElement.scrollTop;
  }

  scrollPageToTopForModalToWorkInSafari() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  resetScrollToUsersOriginalPosition() {
    document.body.scrollTop = this.originalScrollPosition;
    document.documentElement.scrollTop = this.originalScrollPosition;
  }
}
