import { Controller } from '@hotwired/stimulus';

import {
  acceptAllCookies,
  disableAllCookies,
  hasMadeCookieChoice,
} from 'utils/cookies';

export default class extends Controller {
  static values = {
    insideIframe: Boolean,
    blockScripts: Boolean,
    takeoverModal: Boolean,
    showCookieWidget: Boolean,
  };

  connect() {
    if (!hasMadeCookieChoice()) {
      if (this.showCookieWidgetValue) {
        this.open();
      } else {
        acceptAllCookies(this.insideIframeValue);
      }
    }

    this.element.addEventListener('cancel', (event) => {
      event.preventDefault();
    });
  }

  acceptAll() {
    acceptAllCookies(this.insideIframeValue);
    this.close();
  }

  disableAll() {
    disableAllCookies(this.insideIframeValue);
    this.close();
  }

  openPreferences() {
    this.close();
    window.dispatchEvent(new Event('open-cookie-preferences'));
  }

  open() {
    if (this.takeoverModalValue) {
      this.element.showModal();
    } else {
      this.element.show();
    }

    this.element.classList.add('flex');

    if (this.takeoverModalValue) {
      document.body.style.overflow = 'hidden';
    }
  }

  close() {
    this.element.close();
    this.element.classList.remove('flex');
    document.body.style.overflow = '';
  }
}
