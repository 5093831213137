import { Controller } from '@hotwired/stimulus';

import oauthWindow from 'utils/oauthWindow';

export default class extends Controller {
  static values = { oauthUrl: String };

  async connect() {
    window.addEventListener('message', this.handleSeekOauthCallback, false);
  }

  disconnect() {
    window.removeEventListener('message', this.handleSeekOauthCallback);
  }

  setJobApplicationFormValues(attributes) {
    const form = document.getElementById('job-application-form');
    form.setAttribute(
      'data-careersite--form-social-data-value',
      JSON.stringify(attributes)
    );
  }

  seekOauthClicked() {
    this.window = oauthWindow(this.oauthUrlValue, 'Seek authentication');
  }

  handleSeekOauthCallback = (event) => {
    if (this.window === event.source) {
      const {
        first_name: firstName,
        last_name: lastName,
        email: emailAddress,
        phone: phoneNumber,
        id: seekApplicationId,
        token: seekToken,
        resume_url: resumeUrl,
        resume_filename: resumeFilename,
      } = event.data;

      this.setJobApplicationFormValues({
        emailAddress,
        firstName,
        lastName,
        phoneNumber,
        resumeUrl,
        resumeFilename,
        seekApplicationId,
        seekToken,
      });
    }
  };
}
