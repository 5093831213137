import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (this.element.classList.contains('alert--success')) {
      this.element.addEventListener('animationend', this.removeElement);
    }
  }

  removeElement = () => {
    this.element.removeEventListener('animationend', this.removeElement);
    this.element.remove();
  };
}
