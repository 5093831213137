import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['common--modal'];

  connect() {
    this.element.addEventListener(
      'turbo:frame-missing',
      this.displayErrorContent
    );
  }

  disconnect() {
    this.element.removeEventListener(
      'turbo:frame-missing',
      this.displayErrorContent
    );
  }

  displayErrorContent = (e) => {
    if (this.hasCommonModalOutlet) {
      e.preventDefault();
      this.commonModalOutlet.displayErrorContent(this.element);
    }
  };
}
