import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';
import { observeElementVisible } from 'utils';

export default class StoryPreviewController extends Controller {
  connect() {
    observeElementVisible(this.element, this.fetchUnloadedStory, {
      root: this.element.parentElement.parentElement,
      rootMargin: '150%',
    });
  }

  fetchUnloadedStory = () => {
    fetch(this.element.href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  };
}
