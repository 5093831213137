import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  get isRequiredCheckboxQuestion() {
    return (
      this.hasCheckboxTarget &&
      this.checkboxTargets.find((element) => element.required)
    );
  }

  get checkedCheckboxes() {
    return this.checkboxTargets.filter((element) => element.checked);
  }

  get uncheckedCheckboxes() {
    return this.checkboxTargets.filter((element) => !element.checked);
  }

  connect() {
    // When a question is required and checkboxes we need to set/unset required attribute manually
    // https://stackoverflow.com/questions/6218494/using-the-html5-required-attribute-for-a-group-of-checkboxes
    if (this.isRequiredCheckboxQuestion) {
      this.checkboxTargets.forEach((element) => {
        element.addEventListener('change', this.checkboxChange);
      });

      if (this.checkedCheckboxes.length > 0) {
        this.setAllUncheckedOptional();
      }
    }
  }

  checkboxChange = ({ target }) => {
    const { checked } = target;

    if (this.checkedCheckboxes.length === 0) {
      this.setAllUncheckedRequired();
    } else if (checked) {
      target.setAttribute('required', 'required');
      this.setAllUncheckedOptional();
    } else if (!checked) {
      target.removeAttribute('required');
    }
  };

  setAllUncheckedOptional = () => {
    this.uncheckedCheckboxes.forEach((element) => {
      element.removeAttribute('required');
    });
  };

  setAllUncheckedRequired = () => {
    this.uncheckedCheckboxes.forEach((element) => {
      element.setAttribute('required', 'required');
    });
  };

  disconnect() {
    if (this.isRequiredCheckboxQuestion) {
      this.checkboxTargets.forEach((element) => {
        element.removeEventListener('change', this.checkboxChange);
      });
    }
  }
}
