import { Controller } from '@hotwired/stimulus';
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import { prefersReducedMotion } from '../../javascript/utils/common';

const IGNORE_TAGS = ['STYLE', 'LINK'];
export default class SliderController extends Controller {
  static classes = [
    'dotWrapper',
    'dot',
    'navWrapper',
    'pauseBtn',
    'playIcon',
    'pauseIcon',
    'hideIcon',
    'hasSlides',
  ];

  static values = {
    strings: Object,
    isGeneratingScreenshot: Boolean,
  };

  slideVariableTextRects = {};

  sliderRect = {};

  connect() {
    const reduceMotion = prefersReducedMotion();

    let slides = Array.from(this.element.children).filter((child) => {
      return !IGNORE_TAGS.includes(child.tagName);
    });

    slides.forEach((child, i) => {
      const variableTextWrapperEl = child.querySelector(
        '[data-variableTextWrapper]'
      );
      if (variableTextWrapperEl) {
        const observer = new ResizeObserver((entries) => {
          entries.forEach((entry) => {
            this.slideVariableTextRects[i] = entry.contentRect;
          });
        });

        observer.observe(variableTextWrapperEl);
      }
      child.classList.add('keen-slider__slide');
    });

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.sliderRect = entry.contentRect;
      });
    });

    observer.observe(this.element);

    if (this.isGeneratingScreenshotValue) {
      slides = [1, 2, 3, 4];
    }

    if (slides.length > 1) {
      this.element.classList.add(...this.hasSlidesClasses);
      const slider = new KeenSlider(this.element, {
        loop: true,
        defaultAnimation: reduceMotion ? { duration: 0 } : { duration: 700 },
      });
      this.slider = slider;

      slider.on('dragStarted', () => {
        this.pause();
      });

      slider.on('dragEnded', () => {
        if (this.isManuallyPaused) return;
        this.play();
      });

      slider.on('slideChanged', () => {
        const activeIndex = slider.track.details.rel;

        this.setTabIndex();

        const haveToPushDownDots =
          this.slideVariableTextRects[activeIndex]?.height /
            this.sliderRect.height >
          0.4;

        if (activeIndex === 0 || !haveToPushDownDots) {
          delete navWrapper.dataset.toggled;
        } else if (haveToPushDownDots) {
          navWrapper.dataset.toggled = '';
        }

        dotWrapper.childNodes.forEach((dot, index) => {
          if (index === activeIndex) {
            dot.dataset.active = '';

            if (this.isManuallyPaused) {
              this.pause();
            }
          } else {
            const animations = this.getFullWidthAnimations(dot);
            animations.forEach((a) => a.cancel());

            delete dot.dataset.active;
          }
        });
      });

      const dotWrapper = document.createElement('div');

      dotWrapper.classList.add(...this.dotWrapperClasses);

      slides.forEach((_, i) => {
        const dot = document.createElement('button');

        dot.title = this.stringsValue.dots[i];

        dot.addEventListener('animationend', (event) => {
          if (
            event.animationName === 'fullWidth' &&
            'active' in event.target.dataset &&
            event.pseudoElement === '::after'
          ) {
            slider.next();
          }
        });

        dot.addEventListener('click', async () => {
          slider.moveToIdx(i);
        });

        dot.classList.add(...this.dotClasses);
        dotWrapper.append(dot);
      });

      const pauseBtn = document.createElement('button');

      pauseBtn.title = this.stringsValue.pause;

      this.pauseBtn = pauseBtn;

      pauseBtn.addEventListener('click', () => {
        if (this.pauseIcon.classList.contains('hidden')) {
          this.isManuallyPaused = false;
          this.play();
          pauseBtn.title = this.stringsValue.pause;
        } else {
          this.isManuallyPaused = true;
          this.pause();
          pauseBtn.title = this.stringsValue.play;
        }
      });
      pauseBtn.classList.add(...this.pauseBtnClasses);
      const pauseIconI = document.createElement('i');
      const playIconI = document.createElement('i');

      const faIReplaceMO = new MutationObserver((mutationsList) => {
        mutationsList.forEach(({ addedNodes }) => {
          addedNodes.forEach((node) => {
            if (node.tagName === 'svg') {
              if (node.classList.contains('fa-pause')) {
                this.pauseIcon = node;
              } else if (node.classList.contains('fa-play')) {
                this.playIcon = node;
              }
            }
          });
        });
      });
      faIReplaceMO.observe(pauseBtn, { childList: true });

      pauseIconI.classList.add(...this.pauseIconClasses);
      playIconI.classList.add(...this.playIconClasses);
      pauseBtn.append(pauseIconI, playIconI);

      const navWrapper = document.createElement('div');

      navWrapper.classList.add(...this.navWrapperClasses);
      navWrapper.append(dotWrapper);

      navWrapper.append(pauseBtn);

      this.element.parentElement.append(navWrapper);

      this.dotWrapper = dotWrapper;

      const pauseElements = Array.from(
        this.element.querySelectorAll('button,a')
      ).filter((el) => {
        const a =
          !Array.from(dotWrapper.childNodes).includes(el) && el !== pauseBtn;
        return a;
      });
      this.pauseElements = pauseElements;

      this.setTabIndex();

      pauseElements.forEach((el) => {
        if ([...dotWrapper.childNodes, pauseBtn].includes(el)) return;

        el.addEventListener('mouseenter', () => {
          this.pause();
        });
        el.addEventListener('focusin', () => {
          this.pause();
        });

        el.addEventListener('mouseleave', () => {
          if (this.isManuallyPaused) return;
          this.play();
        });
        el.addEventListener('focusout', () => {
          if (this.isManuallyPaused) return;
          this.play();
        });
      });

      setTimeout(() => {
        dotWrapper.childNodes[0].dataset.active = '';
        if (this.isGeneratingScreenshotValue) {
          this.pause();
        }
      });
    }
  }

  setTabIndex() {
    const activeSlideEl = this.slider.slides[this.slider.track.details.rel];
    this.pauseElements.forEach((el) => {
      if (activeSlideEl.contains(el)) {
        el.tabIndex = 0;
      } else {
        el.tabIndex = -1;
      }
    });
  }

  getFullWidthAnimations(el) {
    return el.getAnimations({ subtree: true }).filter((a) => {
      return (
        a.animationName === 'fullWidth' && a.effect.pseudoElement === '::after'
      );
    });
  }

  isManuallyPaused = false;

  pause() {
    if (this.isManuallyPaused) {
      this.pauseIcon.classList.add(...this.hideIconClasses);
      this.playIcon.classList.remove(...this.hideIconClasses);
    }

    this.getFullWidthAnimations(this.dotWrapper)[0]?.pause();
  }

  play() {
    this.pauseIcon.classList.remove(...this.hideIconClasses);
    this.playIcon.classList.add(...this.hideIconClasses);

    this.getFullWidthAnimations(this.dotWrapper)[0]?.play();
  }
}
