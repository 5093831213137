/* global hcaptcha */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    apiKey: String,
  };

  async connect() {
    await import(/* webpackChunkName: 'captcha' */ './captcha.scss');

    hcaptcha.render('h-captcha', {
      sitekey: this.apiKeyValue,
    });

    const captchaModalTitle = 'Main content of the hCaptcha challenge';
    const iFramesList = [].slice.call(document.getElementsByTagName('iframe'));
    const captchaChallengeEL = iFramesList.find(
      (e) => e.title === captchaModalTitle
    );

    captchaChallengeEL.parentElement.parentElement.classList.add(
      'captcha__modal'
    );
  }
}
