import { Controller } from '@hotwired/stimulus';

export default class Carousel extends Controller {
  static targets = ['item'];
  static classes = ['page'];
  static values = {
    pagination: Boolean,
    type: String,
    arrows: Boolean,
    hasPadding: Boolean,
    hasDrag: Boolean,
    hasAutoplay: Boolean,
  };

  connect() {
    this.initializeCarousel();
  }

  async initializeCarousel() {
    const [{ Splide }] = await Promise.all([
      import(/* webpackChunkName: 'splide' */ '@splidejs/splide'),
      import(
        /* webpackChunkName: 'splide-css' */ '@splidejs/splide/dist/css/splide.min.css'
      ),
    ]);

    this.splide = new Splide(this.element, {
      type: this.typeValue,
      arrows: this.arrowsValue,
      autoplay: this.hasAutoplayValue,
      pagination: this.paginationValue,
      drag: this.hasDragValue,
      padding: this.hasPaddingValue ? { right: '2rem' } : {},
      classes: {
        page: `splide__pagination__page ${this.pageClasses.join(' ')}`,
      },
    });

    this.splide.on('pagination:mounted', (paginationData) => {
      paginationData.items.forEach((item) => {
        item.button.addEventListener('click', (event) => {
          event.stopPropagation();
          event.preventDefault();
        });
      });
    });

    this.splide.mount();
  }

  disconnect() {
    if (this.splide) {
      this.splide.destroy(true);
    }
  }
}
