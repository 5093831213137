import ApplicationController from 'controllers/share/application_controller';
import debounce from 'debounce';

export default class MobileMenuController extends ApplicationController {
  connect() {
    this.setMenuBodyOffset();
    window.addEventListener('resize', debounce(this.setMenuBodyOffset, 250));
  }

  setMenuBodyOffset = () => {
    const menuHeight = this.element.getBoundingClientRect().height;

    document.body.style.paddingBottom = `${menuHeight ? menuHeight + 16 : 0}px`;
  };
}
