import { Controller } from '@hotwired/stimulus';
import './type_select.scss';

import { createSongEmbedIframe } from 'utils/song-embed';

export default class CandidateFlavourTypeSelectController extends Controller {
  static targets = [
    'customPickerBlock',
    'questionContainer',
    'questionTemplate',
    'questionTypeContainer',
    'questionTypeOption',
    'button',
    'buttonWrapper',
    'buttonMessage',
    'buttonIcon',
    'dropdown',
  ];

  static classes = ['loading'];

  static values = {
    optionsCount: Number,
    selectedClassnames: Object,
    noSelectedClassnames: Array,
  };

  initialize() {
    this.questionsCount = this.optionsCountValue;
  }

  handleClick(e) {
    e.preventDefault();
    const template = this.getTargetByQuestionType(
      this.questionTemplateTargets,
      e.currentTarget.dataset.questionType
    );

    this.questionContainerTarget.appendChild(template.content.cloneNode(true));
    e.currentTarget.style.display = 'none';
    this.optionsCountValue--;

    const addedElement = this.questionContainerTarget.lastElementChild;

    if (addedElement.dataset.questionType === 'song') {
      const input = addedElement.querySelector('input[type="text"]');
      this.listenForSongInput(input);
    }
  }

  toggle(e) {
    e.preventDefault();
    this.toggleDropdown();
  }

  handleDropdownClosed(e) {
    const { byOutsideClick, id } = e.detail;
    if (id === 'question-type-select-dropdown' && byOutsideClick) {
      this.removeRotationClass();
    }
  }

  optionsCountValueChanged() {
    if (this.optionsCountValue !== this.questionsCount) {
      this.buttonWrapperTarget.classList.remove(
        ...this.noSelectedClassnamesValue
      );
      this.buttonWrapperTarget.classList.add(
        ...this.selectedClassnamesValue.button
      );
      this.buttonMessageTarget.classList.add('hidden');

      this.dropdownTarget.classList.remove('w-full');

      this.buttonIconTarget.classList.add(
        ...this.selectedClassnamesValue.buttonIcon
      );

      this.buttonTarget.classList.add('px-20');
    }

    if (this.optionsCountValue === 0) {
      this.customPickerBlockTarget.classList.add('hidden');
    } else {
      this.customPickerBlockTarget.classList.remove('hidden');
    }
  }

  toggleDropdown() {
    this.buttonWrapperTarget.classList.toggle('rotate-45');
  }

  removeRotationClass() {
    this.buttonWrapperTarget.classList.remove('rotate-45');
  }

  getTargetByQuestionType(targets, questionType) {
    return targets.find(
      (target) => target.dataset.questionType === questionType
    );
  }

  removeQuestion(e) {
    e.preventDefault();

    const questionContainer = this.getTargetByQuestionType(
      this.questionTypeContainerTargets,
      e.currentTarget.dataset.questionType
    );
    const questionOption = this.getTargetByQuestionType(
      this.questionTypeOptionTargets,
      e.currentTarget.dataset.questionType
    );

    questionContainer.remove();
    questionOption.style.display = 'block';
    this.optionsCountValue++;
  }

  listenForSongInput(input) {
    const showEmbedIframe = (input, embedIframe) => {
      input.style.visibility = 'hidden';
      input.style.height = '0';
      input.style.padding = '0';
      input.classList.remove(this.loadingClass);
      embedIframe.style.opacity = 1;
      embedIframe.style.position = '';
    };

    const insertEmbed = (embedIframe) => {
      embedIframe.onload = () => {
        setTimeout(() => {
          showEmbedIframe(input, embedIframe);
        }, 1250);
      };
      embedIframe.style.opacity = 0;
      embedIframe.style.position = 'absolute';
      input.classList.add(this.loadingClass);
      input.blur();
      input.parentNode.appendChild(embedIframe);
    };

    input.addEventListener('input', (event) => {
      const url = event.target.value;

      const embedIframe = createSongEmbedIframe(url);

      if (embedIframe) {
        insertEmbed(embedIframe);
      }
    });
  }
}
