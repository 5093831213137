import { Controller } from '@hotwired/stimulus';
import { observeElementVisible } from 'utils';
import {
  paintRules as protomapsPaintRules,
  labelRules as protomapsLabelRules,
} from './protomap_style';

import './jobs.scss';
import { debounce } from 'debounce';

const MAP_ZOOM_CONTROL_CONFIG = {
  position: 'bottomright',
};

const CURRENT_LOCATION_ZOOM_LEVEL = 13;
const SPLIT_VIEW_MEDIA_BREAKPOINT = 1024;

export default class Jobs extends Controller {
  static outlets = ['blocks--jobs--filters'];

  static classes = [
    'overflowAuto',
    'overflowHidden',
    'siblingElementPadding',
    'disabledMapViewToggle',
  ];

  static targets = [
    'map',
    'smallMarkerIcon',
    'largeMarkerIcon',
    'currentLocation',
    'jobsListContainer',
    'jobsContainer',
    'largeScreenSplitViewFilter',
    'smallScreenSplitViewFilter',
    'splitViewCandidate',
  ];
  static values = {
    showMap: Boolean,
    splitView: Boolean,
    splitViewCandidate: Boolean,
    firstBlock: Boolean,
    locations: Array,
    headquarter: Object,
    geobounds: Object,
  };

  async connect() {
    if (this.showMapValue) {
      observeElementVisible(this.element, () => {
        this.initializeMap();
      });
    }

    if (this.splitViewValue) {
      this.setFilterComponent();
      this.setupSplitView();
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (
              window.innerWidth >= SPLIT_VIEW_MEDIA_BREAKPOINT &&
              this.hasJobsListContainerTarget
            ) {
              if (entry.isIntersecting) {
                setTimeout(
                  () => {
                    this.jobsListContainerTarget.classList.add(
                      this.overflowAutoClass
                    );
                    this.jobsListContainerTarget.classList.remove(
                      this.overflowHiddenClass
                    );
                  },
                  this.isFirefox ? 350 : 0
                );
              } else {
                this.jobsListContainerTarget.classList.remove(
                  this.overflowAutoClass
                );
                this.jobsListContainerTarget.classList.add(
                  this.overflowHiddenClass
                );
              }
            }
          });
        },
        {
          threshold: 0.95,
        }
      );
      this.observer.observe(this.jobsContainerTarget);
      window.addEventListener('resize', this.handleResizeForSplitView, 100);
    }
  }

  handleResizeForSplitView = () => {
    this.setFilterComponent();
    this.setMapDesktopMinZoom();
  };

  setFilterComponent = () => {
    if (window.innerWidth >= SPLIT_VIEW_MEDIA_BREAKPOINT) {
      this.removeHiddenFilter(true);
      this.insertFilter(this.largeScreenSplitViewFilterTarget, true);
    } else {
      this.removeHiddenFilter(false);
      this.insertFilter(this.smallScreenSplitViewFilterTarget, false);
    }
  };

  setMapDesktopMinZoom = () => {
    if (this.map) {
      this.map.setMinZoom(this.mapMinZoom);
    }
  };

  insertFilter = (templateElement, largeScreen = true) => {
    if (
      !this.hasBlocksJobsFiltersOutlet ||
      largeScreen !== this.largeScreenSplitViewFormPresent
    ) {
      const templateContent = document.importNode(
        templateElement.content,
        true
      );
      templateElement.parentNode.insertBefore(templateContent, templateElement);
    }
  };

  removeHiddenFilter = (largeScreen = true) => {
    if (
      this.hasBlocksJobsFiltersOutlet &&
      largeScreen !== this.largeScreenSplitViewFormPresent
    ) {
      this.blocksJobsFiltersOutlet.element.remove();
    } else {
      return false;
    }
  };

  setupSplitView() {
    const mainElement = document.querySelector('main');
    let jobsSectionIndex;
    Array.from(mainElement.children).forEach((childElement, childIndex) => {
      if (childElement.contains(this.element)) {
        jobsSectionIndex = childIndex;
        childElement.style.scrollMarginTop = `-${this.jobsContainerTarget.offsetTop}px`;
      } else {
        if (jobsSectionIndex && childIndex === jobsSectionIndex + 1) {
          childElement.classList.add(this.siblingElementPaddingClass);
        }
      }
    });
  }

  async initializeMap() {
    await Promise.all([
      import(/* webpackChunkName: 'leaflet-js' */ 'leaflet'),
      import(/* webpackChunkName: 'leaflet-css' */ 'leaflet/dist/leaflet.css'),
    ]);

    await Promise.all([
      import(
        /* webpackChunkName: 'leaflet-markercluster-js' */ 'leaflet.markercluster'
      ),
      import(
        /* webpackChunkName: 'leaflet-markercluster-css' */ 'leaflet.markercluster/dist/MarkerCluster.css'
      ),
    ]);

    // eslint-disable-next-line no-undef
    this.leaflet = L;
    this.protomapsL = require('protomaps-leaflet');

    const hasMapInstance = !!this.mapTarget.storedMapInstance;

    if (hasMapInstance) {
      this.map = this.mapTarget.storedMapInstance;
      this.clearMarkers();
    } else {
      this.map = this.leaflet.map(this.mapTarget, this.mapOptions);
      this.tileLayer.addTo(this.map);
      this.zoomControl.addTo(this.map);

      this.mapTarget.storedMapInstance = this.map;
    }

    this.map.attributionControl.setPrefix(false);

    this.setMarkerClusters();

    !hasMapInstance && Object.entries(this.geoboundsValue)?.length === 4;

    if (!(hasMapInstance && this.hasGeoBoundCoordinates)) {
      this.positionMap(Object.values(this.markers));
    }

    this.map.on(
      'zoomend',
      debounce(() => {
        if (this.map.getZoom() < this.mapMinZoom) {
          this.positionMap(Object.values(this.markers));
        }
        this.handlingMapCoordinatesChange();
        if (!this.leaflet.Browser.mobile) {
          this.handleZoomControlDisabledState();
        }
      }, 100)
    );

    this.map.on('dragend', debounce(this.handlingMapCoordinatesChange, 100));
  }

  handlingMapCoordinatesChange = () => {
    if (!this.isPositioningMap) {
      const { _northEast, _southWest } = this.map.getBounds();
      const northEast = _northEast.wrap();
      const southWest = _southWest.wrap();

      const coordinates = {
        geoboundTopLeftLat: northEast.lat,
        geoboundTopLeftLon: southWest.lng,
        geoboundBottomRightLat: southWest.lat,
        geoboundBottomRightLon: northEast.lng,
      };

      const event = new CustomEvent('jobs-map-changed', {
        detail: { coordinates },
      });

      window.dispatchEvent(event);
    }
  };

  // This is needed because turbo frame brakes the default leaflet beahvior
  handleZoomControlDisabledState = () => {
    const minZoomControl = this.element.querySelector(
      '.leaflet-control-zoom-out'
    );
    const maxZoomControl = this.element.querySelector(
      '.leaflet-control-zoom-in'
    );
    if (this.map.getZoom() === this.mapMinZoom) {
      minZoomControl.classList.add('leaflet-disabled');
      maxZoomControl.classList.remove('leaflet-disabled');
    } else if (this.map.getZoom() === this.mapMaxZoom) {
      maxZoomControl.classList.add('leaflet-disabled');
      minZoomControl.classList.remove('leaflet-disabled');
    } else {
      maxZoomControl.classList.remove('leaflet-disabled');
      minZoomControl.classList.remove('leaflet-disabled');
    }
  };

  setMarkerClusters() {
    this.markers = {};

    this.markerClusterGroup = this.leaflet.markerClusterGroup({
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: false,
      zoomToBoundsOnClick: false,
    });

    this.markerClusterGroup.on('click', (a) => {
      if (this.map.getZoom() === this.mapMaxZoom) {
        if (!this.splitViewValue || window.innerWidth < 1024) {
          this.jobsListContainerTarget.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        this.map.setView(a.layer.getLatLng(), this.mapMaxZoom);
      }
    });

    this.markerClusterGroup.on('clusterclick', (a) => {
      if (
        this.map.getZoom() === this.mapMaxZoom &&
        (!this.splitViewValue || window.innerWidth < 1024)
      ) {
        this.jobsListContainerTarget.scrollIntoView({ behavior: 'smooth' });
      }
      a.layer.zoomToBounds({
        paddingTopLeft: [48, 48],
        paddingBottomRight: [48, 48],
      });
    });

    for (const { id, lat, long, count } of this.locationsValue) {
      for (let i = 0; i < count; i++) {
        this.markers[id] = this.createMarker(id, lat, long);
      }
    }

    this.map.addLayer(this.markerClusterGroup);
  }

  get filterFormElement() {
    return this.element.querySelector('.splitViewFiltersComponent');
  }

  get largeScreenSplitViewFormPresent() {
    if (this.hasBlocksJobsFiltersOutlet) {
      return this.blocksJobsFiltersOutlet.element.classList.contains(
        'splitViewFiltersComponent'
      );
    } else {
      return false;
    }
  }

  get markerIcon() {
    return this.leaflet.divIcon({
      html: this.largeMarkerIconTarget.innerHTML,
      className: '', // to remove unnecessary squared border style on marker
      iconAnchor: [12, 32],
    });
  }

  get tileLayer() {
    return this.protomapsL.leafletLayer({
      url: 'https://tiles.teamtailor-cdn.com/protomaps/{z}/{x}/{y}.pbf',
      paint_rules: protomapsPaintRules(),
      label_rules: protomapsLabelRules(),
    });
  }

  get zoomControl() {
    return new this.leaflet.Control.Zoom(MAP_ZOOM_CONTROL_CONFIG);
  }

  get mapOptions() {
    return {
      scrollWheelZoom: false,
      zoomControl: false,
      keyboard: false,
      touchZoom: true,
      doubleClickZoom: true,
      zoomSnap: 0.1,
      tap: false,
      minZoom: this.mapMinZoom,
      maxZoom: 18,
      worldCopyJump: true,
      maxBounds: [
        [-90, -180],
        [90, 180],
      ],
      maxBoundsViscosity: 1,
    };
  }

  get mapMinZoom() {
    return this.leaflet.Browser.mobile ? 1 : this.desktopMinZoom;
  }

  get desktopMinZoom() {
    return this.splitViewValue &&
      window.innerWidth >= SPLIT_VIEW_MEDIA_BREAKPOINT
      ? 2
      : 3;
  }

  get hasGeoBoundCoordinates() {
    return Object.entries(this.geoboundsValue)?.length === 4;
  }

  get mapMaxZoom() {
    return this.map.getMaxZoom();
  }

  get isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  createMarker(id, lat, long) {
    const marker = this.leaflet.marker([lat, long], {
      icon: this.markerIcon,
      keyboard: false,
    });

    this.markerClusterGroup.addLayer(marker);

    return marker;
  }

  positionMap(markers) {
    this.isPositioningMap = true;
    const group = new this.leaflet.featureGroup(markers);

    if (Object.entries(this.geoboundsValue)?.length === 4) {
      const {
        bottom_right_lat,
        bottom_right_lon,
        top_left_lat,
        top_left_lon,
      } = this.geoboundsValue;
      this.map.fitBounds([
        [bottom_right_lat, top_left_lon],
        [top_left_lat, bottom_right_lon],
      ]);
    } else if (this.locationsValue?.length) {
      this.map.fitBounds(group.getBounds(), {
        paddingTopLeft: [48, 48],
        paddingBottomRight: [48, 48],
        maxZoom: 13,
      });
    } else {
      const { lat, long } = this.headquarterValue;
      this.map.setView([lat, long], 5);
    }

    setTimeout(() => {
      this.isPositioningMap = false;
    }, 500);
  }

  clearMarkers() {
    this.map.eachLayer((layer) => {
      if (layer._featureGroup) {
        this.map.removeLayer(layer);
      }
    });
  }

  successGeoLocationCallback = (position) => {
    const {
      coords: { latitude, longitude },
    } = position;
    const latLng = { lat: latitude, lng: longitude };
    this.map.setView(latLng, CURRENT_LOCATION_ZOOM_LEVEL);
    this.currentLocationTarget.disabled = false;
  };

  errorGeoLocationCallback = () => {
    this.currentLocationTarget.disabled = false;
  };

  handleCurrentLocation(e) {
    e.preventDefault();
    this.currentLocationTarget.disabled = true;

    navigator.geolocation.getCurrentPosition(
      this.successGeoLocationCallback,
      this.errorGeoLocationCallback
    );
  }

  handleViewChange() {
    this.splitViewCandidateValue = !this.splitViewCandidateValue;
    delete this.mapTarget.dataset.turboPermanent;
    this.blocksJobsFiltersOutlet.setInputValue({
      field: 'split_view',
      value: this.splitViewCandidateValue.toString(),
    });
    this.blocksJobsFiltersOutlet.element.requestSubmit();
    this.disableMapViewToggleButton();
  }

  disableMapViewToggleButton() {
    this.splitViewCandidateTarget.disabled = true;
    this.splitViewCandidateTarget.classList.add(
      this.disabledMapViewToggleClass
    );
  }

  disconnect() {
    if (this.splitViewValue) {
      this.observer?.disconnect();
      window.removeEventListener('resize', this.handleResizeForSplitView);
    }

    if (this.map) {
      this.map.off('dragend');
      this.map.off('zoomend');
    }
  }
}
