import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
export default class extends Controller {
  static targets = ['zone', 'urlInput', 'deleteInput', 'preview', 'deleteBtn'];

  static values = {
    file: Object,
    hiddenInputClass: Array,
  };

  async connect() {
    // prettier-ignore
    const [{ default: Dropzone }, { captureException }] = await Promise.all([
      import(
        /* webpackChunkName: 'dropzone' */ 'dropzone'
      ),
      import(/* webpackChunkName: 'sentry-browser' */ '@sentry/browser'),
    ])

    Dropzone.autoDiscover = false;

    this.dropZone = new Dropzone(this.zoneTarget, {
      acceptedFiles: 'image/gif, image/jpeg, image/png',
      autoProcessQueue: false,
      clickable: true,
      thumbnailWidth: null,
      thumbnailHeight: null,
      hiddenInputContainer: this.zoneTarget,
      maxFiles: 1,
      parallelUploads: 1,
      paramName: 'file',
      previewsContainer: this.zoneTarget,
      previewTemplate: this.previewTarget.innerHTML,
      uploadMultiple: false,
      url: '/',
      accept: (file, done) => {
        post('/uploads/presigned_data?type=image', {
          responseType: 'json',
        })
          .then((response) => response.json)
          .then((data) => {
            file.uploadURL = data.url;
            file.params = data.fields;

            const escapedFileName = file.name
              .replace(/[^a-z0-9_\-.]/gi, '_')
              .replace(/_{2,}/g, '_')
              .toLowerCase();

            file.params.key = file.params.key.replace(
              '${filename}',
              escapedFileName
            );
            return this.searchForXSS(file, this.translationsValue?.xssError);
          })
          .then((file) => {
            done();
            setTimeout(() => this.dropZone.processFile(file));
          })
          .catch((error) => {
            captureException(error);
            done(error);
          });
      },
    });

    if (this.fileValue?.url) {
      this.dropZone.options.maxFiles = 0;
      this.dropZone.displayExistingFile(
        this.fileValue,
        this.fileValue.url,
        null,
        null,
        null
      );
    }

    this.makeHiddenInputAccessible();
    this.bindEvents();
  }

  disconnect() {
    this.dropZone.disable();
  }

  makeHiddenInputAccessible() {
    this.dropZone.hiddenFileInput.style.visibility = '';
    this.dropZone.hiddenFileInput.style.width = '100%';
    this.dropZone.hiddenFileInput.style.height = '100%';
    this.dropZone.hiddenFileInput.style.cursor = 'pointer';
    this.dropZone.hiddenFileInput.style.opacity = '0';
    this.dropZone.hiddenFileInput.classList.add(...this.hiddenInputClassValue);
  }

  bindEvents() {
    this.dropZone.on('processing', this.handleProcessing);
    this.dropZone.on('removedfile', this.handleRemovedFile);
    this.dropZone.on('maxfilesexceeded', this.handleMaxFilesExceeded);
    this.dropZone.on('success', this.handleSuccess);
    this.dropZone.on('uploadprogress', this.handleUploadProgress);
    this.dropZone.on('error', this.handleError);
  }

  searchForXSS(file, errorMessage = 'XSS error') {
    return new Promise((resolve, reject) => {
      if (!file || file.type !== 'application/pdf') {
        resolve(file);
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        const contents = e.target.result;
        const jsInjection = contents.match(/\/JS(\s*)\(/);

        if (jsInjection) {
          reject(errorMessage);
        } else {
          resolve(file);
        }
      };
      reader.readAsText(file);
    });
  }

  updateProgress = (file, percent) => {
    const circleElement = file.previewElement.querySelector('circle');
    const offset = file.circumference - (percent / 100) * file.circumference;
    circleElement.style.strokeDashoffset = offset;
  };

  initializeUploadProgress = (file) => {
    const circleElement = file.previewElement.querySelector('circle');
    file.radius = circleElement.r.baseVal.value;
    file.circumference = file.radius * 2 * Math.PI;
    circleElement.style.strokeDasharray = `${file.circumference} ${file.circumference}`;
    circleElement.style.strokeDashoffset = `${file.circumference}`;
    circleElement.classList.remove('opacity-0');
  };

  handleMaxFilesExceeded = (files) => {
    this.deleteBtnTargets.forEach((btn) => btn.click());
    this.dropZone.options.maxFiles = 1;
    this.dropZone.addFile(files);
  };

  handleUploadProgress = (file, progress) => {
    this.updateProgress(file, progress);
  };

  handleProcessing = (file) => {
    let params = file.params;
    params['Content-Type'] = file.type;

    this.dropZone.options.params = params;
    this.dropZone.options.url = file.uploadURL;

    this.initializeUploadProgress(file);
  };

  handleRemovedFile = () => {
    this.makeHiddenInputAccessible();
    this.deleteInputTarget.removeAttribute('disabled');
  };

  handleSuccess = (data) => {
    data.previewElement.querySelector('circle').remove();
    const fileUrl = decodeURIComponent(data.xhr.getResponseHeader('Location'));

    this.urlInputTarget.value = fileUrl.split('/tmp/').pop();

    data.previewElement.setAttribute(
      'data-forms--inputs--upload-preview-url-value',
      fileUrl
    );

    this.deleteInputTarget.disabled = true;

    this.makeHiddenInputAccessible();
  };

  handleError = () => {
    this.makeHiddenInputAccessible();
  };
}
