import { Controller } from '@hotwired/stimulus';
import { isEmpty } from 'lodash-es';
import { initializeZiggeo } from 'utils/ziggeo';
import './ziggeo.scss';

// Extensions supported by Ziggeo; https://ziggeo.com/features/video-uploads/
const ALLOWED_EXTENSIONS = [
  'mp4',
  'mpg',
  'avi',
  'mov',
  'mkv',
  'wmv',
  'ogv',
  'webm',
  'flv',
];

export default class extends Controller {
  static values = { ziggeoToken: String, ziggeoAuthToken: String };
  static targets = ['recorder', 'videoToken'];
  static outlets = ['careersite--form'];

  async connect() {
    const ziggeo = await initializeZiggeo(this.ziggeoTokenValue);

    this.recorder = new ziggeo.Recorder({
      element: this.recorderTarget,
      attrs: this.recorderAttributes,
    });

    this.recorder.activate();
    this.bindEvents();
  }

  disconnect() {
    this.recorder.destroy();
  }

  bindEvents() {
    this.videoTokenTarget.form
      .querySelector('button[type="submit"],input[type="submit"]')
      .addEventListener('click', this.onClick);

    this.recorder.on('countdown recording uploading', () => {
      this.processing = true;
    });

    this.recorder.on('verified', () => {
      this.processing = false;
      this.videoTokenTarget.value = this.recorder.get('video');
    });

    this.recorder.on('error', () => {
      this.processing = false;
    });

    this.videoTokenTarget.addEventListener('keydown', this.onVideoTokenKeydown);

    if (this.hasCareersiteFormOutlet) {
      this.careersiteFormOutlet.handleFormContent();
    }
  }

  onClick = () => {
    const {
      processingMessage,
      requiredMessage,
    } = this.videoTokenTarget.dataset;

    if (this.processing) {
      this.videoTokenTarget.setCustomValidity(processingMessage);
    } else if (
      this.videoTokenTarget.required &&
      isEmpty(this.videoTokenTarget.value)
    ) {
      this.videoTokenTarget.setCustomValidity(requiredMessage);
    } else {
      this.videoTokenTarget.setCustomValidity('');
    }
  };

  onVideoTokenKeydown = (e) => {
    // Prevent keyboard input in video token field, only set by JS after video is uploaded.
    if (e.key !== 'Enter' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };

  get recorderAttributes() {
    let videoToken = this.videoTokenTarget.value;
    let attrs = {
      responsive: true,
      theme: 'modern',
      'flip-camera': true,
      picksnapshots: false,
      allowedextensions: ALLOWED_EXTENSIONS,
      'client-auth': this.ziggeoAuthTokenValue,
    };

    if (videoToken.length !== 0) {
      attrs = {
        ...attrs,
        video: videoToken,
        rerecordable: true,
        recordermode: false,
      };
    }

    return attrs;
  }
}
