import { Controller } from '@hotwired/stimulus';
import { passwordStrength, defaultOptions } from 'check-password-strength';

export default class extends Controller {
  static targets = ['container', 'bar'];

  checkPassword(event) {
    defaultOptions[2].minDiversity = 3;

    const value = event.target.value;

    this.containerTarget.classList.toggle('active', value.length > 0);

    const result = passwordStrength(value, defaultOptions);

    this.barTargets.forEach((bar, index) =>
      bar.classList.toggle('active', index <= result.id)
    );
  }
}
