import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { country: String, initialized: Boolean };

  phoneInput = null;
  initializationStarted = false;

  async connect() {
    this.errorMap = [
      this.element.dataset.invalidNumber,
      this.element.dataset.invalidCountryCode,
      this.element.dataset.tooShort,
      this.element.dataset.tooLong,
      this.element.dataset.invalidNumber,
    ];

    this.elementEventListeners = [
      ['input', this.handleChange],
      ['input', this.checkValidity],
      ['countrychange', this.checkValidity],
      ['open:countrydropdown', this.countryDropdownOpened],
      ['close:countrydropdown', this.countryDropdownClosed],
    ];

    if (!this.initializationStarted) {
      // Need this flag to avoid double initialization
      this.initializationStarted = true;
      this.phoneInput = await this.initializeTelInput();
      this.phoneInput.selectedFlag.setAttribute('tabindex', '-1');

      this.elementEventListeners.forEach((listener) => {
        this.element.addEventListener(...listener);
      });

      this.initializedValue = true;
    }
  }

  disconnect() {
    this.elementEventListeners.forEach((listener) => {
      this.element.removeEventListener(...listener);
    });

    if (this.phoneInput) {
      this.phoneInput.destroy();
    }
  }

  countryDropdownOpened = () => {
    // Detects if dropdown overflows window, if so, set max width
    const dropdownElement = this.phoneInput.countryList;
    const dropdownRect = dropdownElement.getBoundingClientRect();
    const windowWidth = document.documentElement.clientWidth;
    if (dropdownRect.x + dropdownRect.width > windowWidth) {
      const width = windowWidth - dropdownRect.x - 15;
      dropdownElement.style.width = `${width}px`;
      dropdownElement.style.whiteSpace = 'normal';
    }
    this.element.classList.add('ring-2', 'ring-gray-800');
  };

  countryDropdownClosed = () => {
    const dropdownElement = this.phoneInput.countryList;
    dropdownElement.style.width = '';
    dropdownElement.style.whiteSpace = '';
    this.element.classList.remove('ring-2', 'ring-gray-800');
  };

  handleChange = () => {
    // Set country code if user entered number without it
    this.element.value = this.phoneInput.getNumber();
    this.checkPhoneValidity();
  };

  checkPhoneValidity = () => {
    const number = this.phoneInput.getNumber();
    if (this.phoneInput.isValidNumber() || number === '') {
      this.element.setCustomValidity('');
    } else {
      const errorCode = this.phoneInput.getValidationError();
      this.element.setCustomValidity(
        this.errorMap[errorCode] || this.element.dataset.invalidNumber
      );
    }
  };

  async initializeTelInput() {
    const [{ default: intlTelInput }] = await Promise.all([
      import('intl-tel-input'),
      import('intl-tel-input/build/js/utils.js'),
      import('intl-tel-input/build/css/intlTelInput.css'),
    ]);

    return intlTelInput(this.element, {
      nationalMode: false,
      customContainer: this.element.dataset.wrapperClass,
      initialCountry: this.initialCountry,
    });
  }

  get initialCountry() {
    return this.countryValue || 'auto';
  }
}
