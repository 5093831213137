import { Controller } from '@hotwired/stimulus';
import { some } from 'lodash-es';

export default class extends Controller {
  static targets = ['person'];

  connect() {
    this.observer = new IntersectionObserver((entries) => {
      const isIntersecting = some(entries, 'isIntersecting');
      if (isIntersecting) {
        this.personTargets.forEach((element) =>
          this.setBackgroundImage(element)
        );

        this.observer.disconnect();
      }
    });

    this.observer.observe(this.element);
  }

  setBackgroundImage(element) {
    const backgroundImageUrl = element.dataset['backgroundImageUrl'];

    if (backgroundImageUrl) {
      element.style.backgroundImage = `url(${backgroundImageUrl})`;
    }
  }
}
