function isOther(element) {
  return element.id.substring(element.id.length - 'other'.length) == 'other';
}

function hideOther(otherElement) {
  otherElement.className = 'hidden';
  otherElement.value = null;
  otherElement.disabled = 'disabled';
}

function showOther(otherElement) {
  otherElement.className = '';
  otherElement.disabled = undefined;
}

function setupOtherRadioButton(element, previous) {
  const radios = document.querySelectorAll(
    `input[type="radio"][name="${element.name}"]`
  );
  const prev = previous;
  radios.forEach((radio) => {
    radio.addEventListener('change', function () {
      if (this.checked && prev == this) {
        showOther(element);
      } else {
        hideOther(element);
      }
    });
  });
  if (previous.checked) {
    element.className = '';
  }
}

function setupOtherCheckbox(element, previous) {
  previous.addEventListener('change', function () {
    if (this.checked) {
      showOther(element);
    } else {
      hideOther(element);
    }
  });
  if (previous.checked) {
    element.className = '';
  }
}

export function setupJuicyFormOtherOption() {
  const juicyInputs = document.querySelectorAll('.juicy-input > input');

  juicyInputs.forEach((element) => {
    const sibling = element.nextElementSibling;
    if (sibling && sibling.tagName.toLowerCase() == 'label') {
      for (let i = 0; i < sibling.children.length; i++) {
        const childElement = sibling.children[i];
        if (childElement.type === 'text' && isOther(childElement)) {
          if (element.type === 'radio') {
            setupOtherRadioButton(childElement, element);
          } else if (element.type === 'checkbox') {
            setupOtherCheckbox(childElement, element);
          }
        }
      }
    }
  });
}

export function setupJuicyFormConditional() {
  const conditionalQuestions = document.querySelectorAll(
    '.question[data-question-show-if-uuid]'
  );

  conditionalQuestions.forEach((questionWrapper) => {
    const refQuestionUuid = questionWrapper.dataset.questionShowIfUuid;
    const refQuestionValue = questionWrapper.dataset.questionShowIfValue;

    const radioSelector = `[data-question-uuid="${refQuestionUuid}"] input[type="radio"]`;

    document.querySelectorAll(radioSelector).forEach(function (el) {
      el.addEventListener('change', function () {
        if (el.checked) {
          const shouldShowQuestion = el.value === refQuestionValue;
          questionWrapper.classList.toggle('hidden', !shouldShowQuestion);
        }
      });
    });

    const checkboxSelector = `[data-question-uuid="${refQuestionUuid}"] input[value="${refQuestionValue}"]`;
    document.querySelectorAll(checkboxSelector).forEach(function (el) {
      el.addEventListener('change', function () {
        questionWrapper.classList.toggle('hidden', !el.checked);
      });
    });
  });
}

export default function setupListeners() {
  document.addEventListener('DOMContentLoaded', function () {
    setupJuicyFormOtherOption();
    setupJuicyFormConditional();
  });

  document.addEventListener('turbo:load', function () {
    setupJuicyFormOtherOption();
    setupJuicyFormConditional();
  });
}
