import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'rolesWrapper',
    'departmentsWrapper',
    'roleInput',
    'jobSubscriptions',
    'allDepartmentsRoles',
    'departmentAndRolesWrapper',
    'subscribe',
    'allDepartmentsRolesTooltip',
    'emailFrequency',
  ];

  static values = {
    jobSubscriptions: Object,
  };

  assignjobSubscriptionsTargetValue() {
    this.jobSubscriptionsTarget.value = JSON.stringify(
      this.jobSubscriptionsValue
    );
  }

  toggleSubscribe(e) {
    const { checked } = e.target;
    this.departmentAndRolesWrapperTarget.classList.toggle('hidden', !checked);
    this.emailFrequencyTarget.classList.toggle('hidden', !checked);
    this.subscribeTarget.classList.toggle('mb-4', checked);
    this.subscribeTarget.classList.toggle('mb-16', !checked);
  }

  handleAllDepartmentsAndRoles(e) {
    const { checked } = e.target;
    this.toggleAllDepartmentsAndRoles(checked);
  }

  handleChangeDepartment(e) {
    const { value, checked } = e.target;
    this.toggleDepartment(value, checked);
  }

  handleConnectChange(e) {
    const { departmentId, roleId } = e.detail;
    const departmentInt = Number(departmentId);
    const roleInt = roleId ? Number(roleId) : 0;

    if (departmentInt && !roleInt) {
      this.connectChangeDepartments(departmentId);
    } else if (departmentInt && roleInt) {
      this.connectChangeRole(departmentId, roleId);
    } else {
      this.allDepartmentsRolesTarget.checked = true;
      this.toggleAllDepartmentsAndRoles(true);
    }
  }

  toggleAllDepartmentsAndRoles(checked) {
    this.departmentsWrapperTargets.forEach((departmentTarget) => {
      departmentTarget.classList.toggle('hidden', checked);
      const rolesTarget = this.getDepartmentRolesWrapper(
        departmentTarget.dataset.id
      );
      if (rolesTarget) {
        rolesTarget.classList.toggle('hidden', true);
      }

      if (checked) {
        const checkboxes = departmentTarget.querySelectorAll('input');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }
    });

    this.allDepartmentsRolesTooltipTarget.dataset.tooltipDisabledValue = !checked;

    this.jobSubscriptionsValue = {
      all: checked,
    };

    this.assignjobSubscriptionsTargetValue();
  }

  toggleDepartment(value, checked, singleRole) {
    const rolesTarget = this.getDepartmentRolesWrapper(value);
    this.toggleDepartmentCheckboxIcon(value, !checked);

    if (rolesTarget) {
      this.changeRoles(rolesTarget, checked, true, singleRole);
      rolesTarget.classList.toggle('hidden', !checked);
    }

    if (checked) {
      this.jobSubscriptionsValue = {
        ...this.jobSubscriptionsValue,
        all: false,
        [value]: {
          all: singleRole ? false : true,
          ...(singleRole && { roles: [singleRole] }),
        },
      };
    } else {
      const jobSubscriptions = this.jobSubscriptionsValue;
      delete jobSubscriptions[value];
      this.jobSubscriptionsValue = jobSubscriptions;
    }

    this.hasAllDepartmentsAndRoles();
    this.assignjobSubscriptionsTargetValue();
  }

  hasAllDepartmentsAndRoles() {
    const departmentIds = this.departmentsWrapperTargets.map(
      (departmentTarget) => departmentTarget.dataset.id
    );
    const hasAll = departmentIds.every(
      (departmentId) => this.jobSubscriptionsValue[departmentId]?.all
    );

    if (hasAll) {
      this.allDepartmentsRolesTarget.checked = true;
      this.toggleAllDepartmentsAndRoles(true);
    }
  }

  getDepartmentRolesWrapper(departmentId) {
    return this.rolesWrapperTargets.find(
      (rolesTarget) => rolesTarget.dataset.department === departmentId
    );
  }

  getDepartmentWrapper(departmentId) {
    return this.departmentsWrapperTargets.find(
      (departmentTarget) => departmentTarget.dataset.id === departmentId
    );
  }

  getDepartmentCheckbox(departmentId) {
    return this.getDepartmentWrapper(departmentId).querySelector('input');
  }

  toggleDepartmentCheckboxIcon(departmentId, minusIcon) {
    const departmentCheckbox = this.getDepartmentCheckbox(departmentId);
    departmentCheckbox.classList.toggle(
      'form-box-label__checkbox-minus',
      minusIcon
    );
  }

  changeRoles(rolesTarget, checked, checkAllRoles, singleRole) {
    const rolesCheckboxes = rolesTarget.querySelectorAll('input');
    rolesCheckboxes.forEach((roleCheckbox) => {
      const isSingleRole = roleCheckbox.value === singleRole;

      roleCheckbox.checked = this.isRoleCheckboxChecked(
        singleRole,
        isSingleRole,
        checkAllRoles,
        checked
      );
    });
  }

  isRoleCheckboxChecked(singleRole, isSingleRole, checkAllRoles, checked) {
    if (singleRole) {
      return isSingleRole;
    }

    return !checkAllRoles ? false : checked;
  }

  handleChangeRole(e) {
    const {
      value,
      checked,
      dataset: { forDepartment },
    } = e.target;

    this.handleSingleRole(forDepartment, value, checked);
    this.assignjobSubscriptionsTargetValue();
  }

  handleSingleRole(departmentId, value, checked) {
    let newRoles = [];
    const department = this.jobSubscriptionsValue[departmentId];
    const roleIds = this.getDepartmentRolesIds(departmentId);
    if (department?.all) {
      newRoles = [...roleIds.filter((role) => role !== value)];
    } else {
      const roles = department?.roles || [];
      newRoles = checked
        ? [...roles, value]
        : roles.length && roles.filter((roleId) => roleId !== value);

      if (newRoles.length === 0) {
        const departmentCheckbox = this.getDepartmentCheckbox(departmentId);
        departmentCheckbox.checked = false;
        this.toggleDepartment(departmentId, false);
        return;
      }
    }

    const allRoles = roleIds.length === newRoles.length;
    this.toggleDepartmentCheckboxIcon(departmentId, !allRoles);
    const hasSpecificRoles = !allRoles && newRoles.length > 0;

    if (!hasSpecificRoles) {
      this.toggleDepartment(departmentId, true);
    } else {
      this.jobSubscriptionsValue = {
        ...this.jobSubscriptionsValue,
        [departmentId]: {
          all: false,
          roles: [...newRoles],
        },
      };
    }
  }

  getDepartmentRolesIds(departmentId) {
    const rolesWrapper = this.getDepartmentRolesWrapper(departmentId);
    return Array.from(rolesWrapper.querySelectorAll('input')).map(
      (role) => role.value
    );
  }

  connectChangeDepartments(selectedDepartmentId, roleId) {
    this.jobSubscriptionsValue = { all: false };
    this.allDepartmentsRolesTarget.checked = false;

    this.rolesWrapperTargets.forEach((rolesWrapper) => {
      rolesWrapper.classList.toggle('hidden', true);
      rolesWrapper
        .querySelectorAll('input')
        .forEach((input) => (input.checked = false));
    });

    this.departmentsWrapperTargets.forEach((departmentWrapper) => {
      const isThisDepartment =
        departmentWrapper.dataset.id === selectedDepartmentId;
      departmentWrapper.classList.toggle('hidden', false);
      departmentWrapper.querySelector('input').checked = isThisDepartment;
      if (isThisDepartment) {
        this.toggleDepartment(selectedDepartmentId, true, roleId);
      }
    });
  }

  connectChangeRole(departmentId, roleId) {
    this.allDepartmentsRolesTarget.checked = false;
    this.connectChangeDepartments(departmentId, roleId);
    this.toggleDepartmentCheckboxIcon(departmentId, true);
    this.assignjobSubscriptionsTargetValue();
  }
}
