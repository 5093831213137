import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['root'];
  static values = {
    css: String,
    html: String,
  };

  async connect() {
    const shadow = this.rootTarget.attachShadow({ mode: 'open' });

    const style = document.createElement('style');
    style.textContent = this.cssValue;

    shadow.appendChild(style);
    shadow.innerHTML += this.htmlValue;
  }
}
