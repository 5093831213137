import { Controller } from '@hotwired/stimulus';
import confetti from 'utils/confetti';

export default class extends Controller {
  connect() {
    this.element.width = window.innerWidth;
    this.element.height = window.innerHeight;
    confetti(this.element);
  }
}
