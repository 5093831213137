import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tooltip'];
  static values = {
    text: String,
    disabled: Boolean,
    position: { type: String, default: 'top' },
    touch: { type: Boolean, default: true },
  };

  async connect() {
    if (this.textValue && !this.disabledValue) {
      await this.renderTooltip();
    }
  }

  async renderTooltip() {
    const [tippy] = await Promise.all([
      import(/* webpackChunkName: 'tippy.js' */ 'tippy.js'),
      import(
        /* webpackChunkName: 'tooltip' */ 'stylesheets/common/tooltip.scss'
      ),
    ]);

    this.tippyInstance = tippy.default(this.element, {
      content: this.textValue,
      duration: [100, 0],
      touch: this.touchValue,
      placement: this.positionValue,
    });
  }

  disabledValueChanged() {
    if (!this.tippyInstance) {
      return;
    }

    if (this.disabledValue) {
      this.tippyInstance.disable();
    } else {
      this.tippyInstance.enable();
    }
  }
}
