import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    inEditor: Boolean,
  };
  connect() {
    // when browsers support @starting-style we can probably remove this ready class
    if (this.inEditorValue) {
      // raf needed for when reloading in editor
      requestAnimationFrame(() => {
        this.element.classList.add('ready');
      });
    } else {
      this.element.classList.add('ready');
    }
    document.addEventListener('turbo:visit', this.handleVisit);
  }

  handleVisit(event) {
    const isReload = window.location.href === event.detail.url;

    if (isReload) {
      document.body.classList.remove('ready');
    }
  }

  disconnect() {
    document.addEventListener('turbo:visit', this.handleVisit);
  }
}
