import { Controller } from '@hotwired/stimulus';
import debounce from 'debounce';
import { observeElementVisible } from 'utils';

const LOAD_NEXT_PAGE_MARGIN = 200;
const SCROLL_LENGTH_MULTIPLIER = 0.4;

export default class StoryFeedController extends Controller {
  static targets = ['container', 'leftButton', 'rightButton', 'moreButton'];

  connect() {
    this.checkNavButtonsEvent = new Event('checkNavButtons');

    observeElementVisible(this.element, () => {
      import(/* webpackChunkName: 'swiper-core' */ 'swiper');
      import(/* webpackChunkName: 'swiper-style' */ 'swiper/css/bundle');
    });

    this.element.addEventListener(
      'checkNavButtons',
      debounce(this.toggleNavButtons, 1000)
    );

    this.element.dispatchEvent(this.checkNavButtonsEvent);
  }

  getScrollPosition() {
    const { scrollLeft, offsetWidth, scrollWidth } = this.containerTarget;
    const scrollDiff = scrollLeft + offsetWidth;

    const startReached = scrollLeft == 0;
    const endReached = scrollWidth == scrollDiff;
    const shouldLoadMore = scrollWidth - scrollDiff < LOAD_NEXT_PAGE_MARGIN;

    return {
      startReached,
      endReached,
      shouldLoadMore,
    };
  }

  handleScroll() {
    this.element.dispatchEvent(this.checkNavButtonsEvent);

    const { shouldLoadMore } = this.getScrollPosition();

    if (this.hasMoreButtonTarget && shouldLoadMore) {
      this.moreButtonTarget.click();
      this.moreButtonTarget.remove();
    }
  }

  toggleNavButtons = () => {
    const { endReached, startReached } = this.getScrollPosition();
    this.leftButtonTarget.classList.toggle('sm:block', !startReached);
    this.rightButtonTarget.classList.toggle('sm:block', !endReached);
  };

  get scrollLength() {
    const { offsetWidth } = this.containerTarget;
    return parseInt(offsetWidth * SCROLL_LENGTH_MULTIPLIER);
  }

  left() {
    this.scrollContainer(-this.scrollLength);
  }

  right() {
    this.scrollContainer(this.scrollLength);
  }

  scrollContainer(value) {
    this.containerTarget.scrollBy({ left: value, behavior: 'smooth' });
  }
}
