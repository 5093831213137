import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'dropdown'];
  isOpen = false;

  outsideClick(e) {
    if (e && !this.buttonTarget.contains(e.target)) {
      this.isOpen = false;
      this.updateClasses();
    }
  }

  toggle(e) {
    e.preventDefault();
    this.isOpen = !this.isOpen;
    this.updateClasses();
  }

  updateClasses() {
    this.dropdownTarget.classList.toggle('hidden', !this.isOpen);
    this.dropdownTarget.classList.toggle('flex', this.isOpen);
  }
}
