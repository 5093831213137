import textFit from 'textfit';
import FontFaceObserver from 'fontfaceobserver';

export default function fitTextToElement(element) {
  const initialFontSize = window.getComputedStyle(element).fontSize;
  const maxSize = parseInt(initialFontSize, 10);

  fitText(element, maxSize);
  fitTextWhenFontLoaded(element, maxSize);

  return () => {
    fitText(element, maxSize);
  };
}

function fitText(element, maxSize) {
  if (element) {
    textFit(element, {
      multiLine: true,
      minFontSize: 16,
      maxFontSize: maxSize,
      widthOnly: true,
    });

    if (element.firstChild.style.fontSize === '16px') {
      element.style.hyphens = 'auto';
    } else {
      element.style.hyphens = 'inherit';
    }
  }
}

function fitTextWhenFontLoaded(element, maxSize) {
  const fontObserver = new FontFaceObserver(titleFont(element));

  fontObserver.load().then(
    () => {
      fitText(element, maxSize);
    },
    () => {}
  );
}

function titleFont(element) {
  const fontFamily = getComputedStyle(element).getPropertyValue('font-family');
  return fontFamily.split(',')[0];
}
