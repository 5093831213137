import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.setViewportHeight();
    window.addEventListener('resize', this.setViewportHeight);
  }

  setViewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
}
