import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'rolesWrapper',
    'departmentsWrapper',
    'allRoleInput',
    'roleInput',
  ];

  handleChangeDepartment(event) {
    const selectedDepartmentId = event.target.value;

    if (this.element.dataset.department === selectedDepartmentId) {
      return;
    }

    this.rolesWrapperTargets.forEach((rolesTarget) => {
      rolesTarget.classList.toggle(
        'hidden',
        rolesTarget.dataset.department !== selectedDepartmentId
      );
    });

    const selectedDepartmentElement = this.departmentsWrapperTargets.find(
      (departmentTarget) => departmentTarget.dataset.id === selectedDepartmentId
    );

    if (selectedDepartmentElement) {
      selectedDepartmentElement.parentNode.parentNode.scroll({
        top: selectedDepartmentElement.offsetTop,
        behavior: 'smooth',
      });
    }

    this.resetRole(selectedDepartmentId);
    this.handleSubscription(selectedDepartmentId);
  }

  handleChangeRole(event) {
    const {
      value,
      dataset: { forDepartment },
    } = event.target;
    this.handleSubscription(forDepartment, value);
  }

  handleSubscription(departmentId, roleId) {
    window.dispatchEvent(
      new CustomEvent('primay-connect-change', {
        detail: {
          departmentId,
          roleId,
        },
      })
    );
  }

  resetRole(selectedDepartmentId) {
    this.roleInputTargets.forEach((target) => {
      target.checked = false;
    });
    this.allRoleInputTargets.forEach((target) => {
      target.checked = target.dataset.forDepartment === selectedDepartmentId;
    });
  }
}
