import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static classes = ['smoothScroll'];

  handleScrollDown(event) {
    event.stopPropagation();
    event.preventDefault();

    this.scrollToContent();
  }

  scrollToContent() {
    window.scroll({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  }

  handleScrollToJobs() {
    document.documentElement.classList.add(this.smoothScrollClass);

    const abortController = new AbortController();

    window.addEventListener(
      'scroll',
      () => {
        if (this.scrollTimeout) {
          clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = setTimeout(() => {
          document.documentElement.classList.remove(this.smoothScrollClass);
          abortController.abort();
        }, 150);
      },
      { signal: abortController.signal, passive: true }
    );
  }
}
