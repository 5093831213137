import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleIcon', 'bodyContainer', 'button'];
  static values = { open: Boolean };

  connect() {
    this.bodyContainerTarget.style.maxHeight = 0;
  }

  toggle() {
    this.openValue = !this.openValue;
    this.buttonTarget.setAttribute(
      'aria-expanded',
      this.openValue ? 'true' : 'false'
    );
  }

  openValueChanged() {
    if (this.openValue) {
      this.showItem();
    } else {
      this.hideItem();
    }
  }

  hideItem() {
    this.toggleIconTarget.classList.remove('-rotate-45');
    this.bodyContainerTarget.classList.remove('mb-4');
    this.bodyContainerTarget.style.maxHeight = 0;
    setTimeout(() => this.bodyContainerTarget.classList.add('hidden'), 500);
  }

  showItem() {
    this.bodyContainerTarget.classList.remove('hidden');
    this.toggleIconTarget.classList.add('-rotate-45');
    this.bodyContainerTarget.classList.add('mb-4');
    this.bodyContainerTarget.style.maxHeight = `${this.bodyContainerTarget.scrollHeight}px`;
  }
}
