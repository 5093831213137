import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { anchor: String };
  static outlets = ['common--modal', 'connect--modal-wrapper'];

  toggleSettingsModal() {
    if (this.hasCommonModalOutlet) {
      this.commonModalOutlet.toggleModal();
      setTimeout(this.scrollToAnchor, 50);
    }
  }

  scrollToAnchor = () => {
    if (this.anchorValue && this.hasConnectModalWrapperOutlet) {
      const element = this.connectModalWrapperOutlet.element.querySelector(
        `#${this.anchorValue}`
      );
      if (element) {
        element.scrollIntoView();
      }
    }
  };
}
