import { Controller } from '@hotwired/stimulus';
import { createFocusTrap } from 'focus-trap';

export default class extends Controller {
  static targets = ['trigger', 'menu', 'burger', 'button'];
  static values = {
    open: Boolean,
  };

  connect() {
    document.addEventListener('keydown', this.handleKeydown);
    this.focusTrap = createFocusTrap(this.element, { allowOutsideClick: true });

    addEventListener('turbo:before-fetch-response', () => {
      this.openValue = false;
    });
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown);
    this.focusTrap?.deactivate();
  }

  toggle(e) {
    e.preventDefault();
    this.openValue = !this.openValue;
  }

  handleKeydown = (event) => {
    const isEscape =
      event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27;
    if (isEscape && this.isOpen) {
      this.toggle(event);
    }
  };

  openValueChanged() {
    this.menuTarget.classList.toggle('hidden', !this.isOpen);
    document
      .querySelector('body')
      .classList.toggle('overflow-hidden', this.isOpen);

    this.burgerTarget.classList.toggle('header-menu-burger--open', this.isOpen);
    this.buttonTarget.classList.toggle('header-menu-button--open', this.isOpen);

    if (this.focusTrap && this.isOpen) {
      this.focusTrap.activate();
    } else if (this.focusTrap) {
      this.focusTrap.deactivate();
    }
  }

  get isOpen() {
    return this.openValue;
  }
}
