import { Controller } from '@hotwired/stimulus';

export default class MeetingShow extends Controller {
  static values = {
    inviteUuid: String,
    status: String,
    location: Object,
  };

  static targets = [
    'attendYesButton',
    'attendNoButton',
    'attendMaybeButton',
    'attendIcon',
    'map',
    'largeMarkerIcon',
  ];

  static classes = [
    'accepted',
    'tentative',
    'declined',
    'iconAttend',
    'iconAttendYes',
    'iconAttendMaybe',
    'iconAttendDeclined',
  ];

  async connect() {
    this.setAttendClasses(this.statusValue);

    if (Object.keys(this.locationValue).length !== 0 && this.mapTarget) {
      this.leaflet = (
        await Promise.all([
          import(/* webpackChunkName: 'leaflet-js' */ 'leaflet'),
          import(
            /* webpackChunkName: 'leaflet-css' */ 'leaflet/dist/leaflet.css'
          ),
        ])
      )[0];

      this.map = this.leaflet.map(this.mapTarget, this.mapOptions);
      this.map.attributionControl.setPrefix(false);
      this.tileLayer.addTo(this.map);
      this.zoomControl.addTo(this.map);

      const { lat, long, address, name, city } = this.locationValue;

      const popupString = address || city || name;

      this.map.setView([lat, long], 13);

      this.leaflet
        .marker([lat, long], {
          icon: this.markerIcon,
        })
        .addTo(this.map)
        .bindPopup(popupString, { offset: [0, -18] })
        .openPopup();
    }
  }

  attend(event) {
    event.preventDefault();
    const { status } = event.params;
    fetch('/schedule/' + this.inviteUuidValue + '/status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    }).then(() => {
      this.setAttendClasses(status);
    });
  }

  setAttendClasses(status) {
    this.resetClasses();

    if (status === 'accepted') {
      this.attendYesButtonTarget.ariaPressed = true;
      this.attendYesButtonTarget.classList.add(...this.acceptedClasses);
      this.attendIconTarget.classList.add(...this.iconAttendYesClasses);
    } else if (status === 'tentative') {
      this.attendMaybeButtonTarget.ariaPressed = true;
      this.attendMaybeButtonTarget.classList.add(...this.tentativeClasses);
      this.attendIconTarget.classList.add(...this.iconAttendMaybeClasses);
    } else if (status === 'declined') {
      this.attendNoButtonTarget.ariaPressed = true;
      this.attendNoButtonTarget.classList.add(...this.declinedClasses);
      this.attendIconTarget.classList.add(...this.iconAttendDeclinedClasses);
    } else {
      this.attendIconTarget.classList.add(...this.iconAttendClasses);
    }
  }

  resetClasses() {
    this.attendYesButtonTarget.classList.remove(...this.acceptedClasses);
    this.attendMaybeButtonTarget.classList.remove(...this.tentativeClasses);
    this.attendNoButtonTarget.classList.remove(...this.declinedClasses);
    this.attendYesButtonTarget.ariaPressed = false;
    this.attendMaybeButtonTarget.ariaPressed = false;
    this.attendNoButtonTarget.ariaPressed = false;
    this.attendIconTarget.classList.remove(...this.iconAttendClasses);
    this.attendIconTarget.classList.remove(...this.iconAttendYesClasses);
    this.attendIconTarget.classList.remove(...this.iconAttendMaybeClasses);
    this.attendIconTarget.classList.remove(...this.iconAttendDeclinedClasses);
  }

  get markerIcon() {
    return this.leaflet.divIcon({
      html: this.largeMarkerIconTarget.innerHTML,
      className: '',
      iconAnchor: [10, 24],
    });
  }

  get tileLayer() {
    return this.leaflet.tileLayer(
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      {
        attribution: [
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          ' &copy; <a href="https://carto.com/attribution">CARTO</a>',
        ],
      }
    );
  }

  get zoomControl() {
    return new this.leaflet.Control.Zoom({ position: 'topright' });
  }

  get mapOptions() {
    return {
      scrollWheelZoom: false,
      zoomControl: false,
      keyboard: true,
      touchZoom: true,
      doubleClickZoom: true,
      tap: false,
      dragging: !this.leaflet.Browser.mobile,
    };
  }
}
