import { Controller } from '@hotwired/stimulus';

export default class QuestionsPresetFavouritesCategoryDropdownController extends Controller {
  static targets = ['categorySelect', 'trigger'];

  static values = {
    hideOnDisable: Boolean,
  };

  outsideClick(e) {
    if (
      this.triggerTarget.classList.contains('rotate-45') &&
      !this.triggerTarget.contains(e.target)
    ) {
      this.triggerTarget.classList.remove('rotate-45');
    }
  }

  handleSelectedCategoriesChange(e) {
    const { selectedCategories } = e.detail;

    this.categorySelectTargets.forEach((element) => {
      const { categoryKey } = element.dataset;

      if (selectedCategories.indexOf(categoryKey) >= 0) {
        element.classList.add('hidden');
      } else {
        element.classList.remove('hidden');
      }
    });

    this.handleDisabledState(selectedCategories);
  }

  handleDisabledState(selectedCategories) {
    this.toggleDisabled(
      selectedCategories.length === this.categorySelectTargets.length
    );
  }

  toggleDisabled(disabled) {
    if (this.triggerTarget.disabled === disabled) {
      return;
    }

    this.triggerTarget.disabled = disabled;
    if (this.hideOnDisableValue) {
      this.triggerTarget.classList.toggle('hidden');
    }
  }

  rotateTrigger(e) {
    e.preventDefault();
    if (this.triggerTarget.dataset.iconTrigger) {
      this.triggerTarget.classList.toggle('rotate-45');
    }
  }
}
