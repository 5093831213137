import { get, some } from 'lodash-es';

export function observeElementVisible(element, callback, options) {
  let observer;
  observer = new IntersectionObserver((entries) => {
    const isIntersecting = some(entries, 'isIntersecting');
    if (isIntersecting) {
      callback(element);
      observer.disconnect();
    }
  }, options);

  observer.observe(element);
  return observer;
}

export function observeElementIntersecting(element, callback, options) {
  const observer = new IntersectionObserver((entries) => {
    const details = get(entries, '[0]', {});
    callback(details);
  }, options);

  observer.observe(element);
  return observer;
}

export function elementInViewport(element) {
  const html = document.documentElement;
  const rect = element.getBoundingClientRect();

  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= html.clientHeight &&
    rect.left <= html.clientWidth &&
    rect.right <= html.clientWidth
  );
}
