import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];
  static values = { theme: String };

  static classes = ['selected', 'hover'];

  options = ['light', 'dark', 'system'];

  connect() {
    this.changeTheme(this.themeValue);
  }

  changeTheme(theme) {
    if (theme === 'system') {
      const darkMode = window.matchMedia('(prefers-color-scheme: dark)')
        .matches;
      this.handleThemeMode(darkMode ? 'dark' : 'light');
    } else {
      this.handleThemeMode(theme);
    }
  }

  handleThemeMode(theme) {
    document.body.classList.remove(
      theme === 'dark' ? 'theme-light' : 'theme-dark'
    );
    document.body.classList.add(`theme-${theme}`);
  }

  onThemeClick(e) {
    const { theme, preview } = e.target.dataset;
    this.changeTheme(theme);
    if (preview) {
      e.target.classList.add(...this.selectedClasses);
      this.buttonTargets.forEach((button) => {
        if (button.dataset.theme !== theme) {
          button.classList.remove(...this.selectedClasses);
        }
        if (button.disabled) {
          button.disabled = false;
          button.classList.add(...this.hoverClasses);
        }
      });
    }
  }
}
