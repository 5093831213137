import { Controller } from '@hotwired/stimulus';

const KEY_CODE_RETURN = 13;
const KEY_CODE_SPACE = 32;
export default class extends Controller {
  static targets = [
    'currentDomain',
    'domainButton',
    'domainField',
    'dropdown',
    'emailEdit',
    'emailField',
    'fullEmailField',
    'loading',
    'submit',
  ];

  isDropdownOpen = false;

  connect() {
    import(/* webpackChunkName: 'autojoin-form' */ './form.scss');

    this.emailEditTarget.addEventListener('keydown', this.handleKeydown);
    this.emailEditTarget.addEventListener('input', this.updateEmailField);
    this.emailEditTarget.addEventListener('blur', this.handleBlur);
  }

  disconnect() {
    this.emailEditTarget.removeEventListener('keydown', this.handleKeydown);
    this.emailEditTarget.removeEventListener('input', this.updateEmailField);
    this.emailEditTarget.removeEventListener('blur', this.handleBlur);
  }

  updateEmailField = () => {
    this.emailFieldTarget.value = this.emailEditTarget.textContent;
    this.updateFullEmailField();
  };

  updateFullEmailField = () => {
    if (this.emailFieldTarget.value.length > 0) {
      this.fullEmailFieldTarget.value = `${this.emailFieldTarget.value}@${this.domainFieldTarget.value}`;
    } else {
      this.fullEmailFieldTarget.value = '';
    }
  };

  handleKeydown = (event) => {
    if ([KEY_CODE_RETURN, KEY_CODE_SPACE].includes(event.keyCode)) {
      event.preventDefault();
    }

    if (event.keyCode === KEY_CODE_RETURN) {
      this.submitTarget.click();
      this.emailEditTarget.blur();
    }
  };

  handleBlur = () => {
    if (this.hasDropdownTarget) {
      setTimeout(() => {
        if (document.activeElement !== this.emailEditTarget) {
          this.isDropdownOpen = false;
          this.updateDropdownClasses();
        }
      }, 150);
    }
  };

  handleBefore = () => {
    this.handleBlur();
    this.toggleLoader(true);
  };

  handleComplete = () => {
    this.toggleLoader(false);
  };

  toggleLoader(showLoader) {
    this.submitTarget.classList.toggle('hidden', showLoader);
    this.submitTarget.classList.toggle('flex', !showLoader);
    this.loadingTarget.classList.toggle('flex', showLoader);
    this.loadingTarget.classList.toggle('hidden', !showLoader);
  }

  focusEmailField() {
    this.emailEditTarget.focus();
    if (this.hasDropdownTarget) {
      this.isDropdownOpen = true;
      this.updateDropdownClasses();
    }
  }

  changeDomain(e) {
    const domain = e.target.dataset.domain;
    this.currentDomainTarget.textContent = `@${domain}`;
    this.domainFieldTarget.value = domain;
    this.updateFullEmailField();
    this.focusEmailField();
  }

  updateDropdownClasses() {
    this.dropdownTarget.classList.toggle('hidden', !this.isDropdownOpen);
  }
}
