import { Controller } from '@hotwired/stimulus';
export default class StorySwipeController extends Controller {
  static targets = ['item', 'previousButton', 'nextButton'];

  static values = {
    initialized: false,
    currentId: String,
    useCase: String,
  };

  get currentItem() {
    const currentSlideIndex = this.swiper?.realIndex;
    return this.swiper?.slides[currentSlideIndex];
  }

  async connect() {
    const [
      { default: Swiper, Navigation, Keyboard, History },
    ] = await Promise.all([
      import(/* webpackChunkName: 'swiper' */ 'swiper'),
      import(/* webpackChunkName: 'swiper-style' */ 'swiper/css/bundle'),
    ]);

    this.element.classList.remove('opacity-0');

    const currentItemElement = this.getItemById(this.currentIdValue);
    const initialSlide = this.itemTargets.indexOf(currentItemElement);
    const inlineextraLeftOffset = 16;

    this.swiper = new Swiper(this.element, {
      modules: [Navigation, History, Keyboard],
      ...(this.useCaseValue === 'modal'
        ? {
            initialSlide,
            slidesPerView: 1,
            centeredSlides: true,
            history: {
              enabled: true,
              replaceState: true,
              key: 'stories',
              keepQuery: true,
            },
            breakpoints: {
              640: {
                slidesPerView: 4,
                allowTouchMove: false,
              },
            },

            keyboard: {
              enabled: true,
            },
          }
        : this.useCaseValue === 'inline'
        ? {
            breakpoints: {
              640: {
                slidesOffsetBefore: inlineextraLeftOffset,
                slidesPerView: 4,
                allowTouchMove: false,
              },
            },
          }
        : {}),
      slidesPerView: 1,
      maxBackfaceHiddenSlides: false,
      allowTouchMove: true,

      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.previousButtonTarget,
        disabledClass: '!hidden',
        hiddenClass: '!hidden',
        navigationDisabledClass: '!hidden',
      },
    });

    this.swiper.on('slideChange', this.handleSlideChange);

    this.swiper.on('slideChangeTransitionEnd', () => {
      const storyId = this.currentItem.dataset.storyId;
      const event = new CustomEvent('story-changed', { detail: { storyId } });
      window.dispatchEvent(event);
    });

    if (this.useCaseValue === 'inline') {
      [this.previousButtonTarget, this.nextButtonTarget].forEach((el, i) => {
        el.addEventListener(
          'click',
          (e) => {
            if (this.swiper.params.centeredSlides) return;
            e.preventDefault();
            e.stopPropagation();

            const currTranslate = this.swiper.getTranslate();
            const slideWidth = this.swiper.slidesSizesGrid[0];
            if (i) {
              this.swiper.translateTo(currTranslate - slideWidth, 300);
            } else {
              this.swiper.translateTo(currTranslate + slideWidth, 300);
            }
          },
          true
        );
      });

      this.swiper.slides.forEach((slideEl, i) => {
        slideEl.addEventListener(
          'click',
          (e) => {
            this.scrollIntoView();

            const slideWidth = this.swiper.slidesSizesGrid[0];

            if (!this.swiper.params.centeredSlides) {
              e.preventDefault();
              e.stopPropagation();
              const currTranslate = this.swiper.getTranslate();
              this.swiper.params.centeredSlides = true;
              this.swiper.once('update', () => {
                this.swiper.setTranslate(currTranslate);

                const center = slideWidth * 2 + inlineextraLeftOffset;
                const k = center - slideWidth * i;
                this.swiper.translateTo(k, 300);

                if (this.swiper.realIndex !== i) {
                  this.swiper.slideToClickedSlide();
                } else {
                  this.handleSlideChange();
                }
              });
              this.swiper.update();
            }
          },
          true
        );
      });
    }
  }

  scrollIntoView() {
    this.element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  handleSlideChange = () => {
    if (this.useCaseValue === 'inline' && window.innerWidth < 640) {
      this.scrollIntoView();
    }

    const currentItem = this.currentItem;
    this.swiper.slides.forEach((slideEl) =>
      slideEl.classList.remove('sm:!scale-100', 'sm:mx-12')
    );
    currentItem.classList.add('sm:!scale-100', 'sm:mx-12');
  };

  getItemById(uuid) {
    return this.itemTargets.find((el) => el.dataset.storyId === uuid);
  }

  getItemIndex(itemEl) {
    return this.swiper?.slides.indexOf(itemEl);
  }

  goToItem(event) {
    let index = parseInt(event.currentTarget.dataset.index, 10);
    const uuid = event.currentTarget.dataset.storyId;
    if (uuid) {
      const item = this.getItemById(uuid);
      index = this.getItemIndex(item);
    }
    this.swiper.slideTo(index);
  }
}
