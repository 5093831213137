/* global ga */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String,
    text: String,
    isJob: Boolean,
  };

  handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    let provider = e.currentTarget.getAttribute('data-provider');
    this.trackSharingEvent(provider);
    this.popup(provider);
  }

  trackSharingEvent(provider) {
    if (window.ga) {
      let action = (provider += this.isJobValue ? ': job' : '');

      ga('send', 'event', {
        eventCategory: 'Sharing',
        eventAction: action,
        eventLabel: this.urlValue,
      });
    }
  }

  popup(provider) {
    if (provider === 'Facebook') {
      this.popupFacebook();
    } else if (provider === 'Twitter') {
      this.popupTwitter();
    } else if (provider === 'LinkedIn') {
      this.popupLinkedIn();
    }
  }

  popupFacebook() {
    let url = 'https://www.facebook.com/sharer/sharer.php?u=' + this.encodedUrl;
    this.openPopup(url, 900, 500);
  }

  popupTwitter() {
    let url =
      'https://twitter.com/intent/tweet?text=' + '&url=' + this.encodedUrl;

    this.openPopup(url, 650, 360);
  }

  popupLinkedIn() {
    let url =
      'https://www.linkedin.com/shareArticle?mini=true&url=' +
      this.encodedUrl +
      '&token=&isFramed=true';

    this.openPopup(url, 550, 550);
  }

  openPopup(url, width, height) {
    window.open(
      url,
      '',
      'toolbar=0,status=0,width=' + width + ',height=' + height
    );
  }

  get encodedUrl() {
    return encodeURIComponent(this.urlValue);
  }
}
