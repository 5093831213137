import { Controller } from '@hotwired/stimulus';

export default class StoryViewsController extends Controller {
  static values = {
    url: String,
    uuid: String,
    viewed: false,
    current: false,
    viewedClass: String,
    unviewedClass: String,
  };

  get feedItemElement() {
    return document.getElementById(`story-feed-${this.uuidValue}`);
  }

  connect() {
    if (this.currentValue && !this.viewedValue) {
      this.markStoryAsViewed();
    }
  }

  trackView(e) {
    if (this.uuidValue === e.detail.storyId && !this.viewedValue) {
      this.markStoryAsViewed();
    }
  }

  async markStoryAsViewed() {
    await fetch(this.urlValue);
    this.viewedValue = true;

    if (this.feedItemElement) {
      this.feedItemElement.classList.replace(
        this.unviewedClassValue,
        this.viewedClassValue
      );
    }
  }
}
