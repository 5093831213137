import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['monthField', 'monthFieldFallback'];

  connect() {
    const monthFields = this.monthFieldTargets;
    const isChromiumTouchDevice =
      window.chrome && window.matchMedia('(pointer: coarse)').matches;

    const firstMonthField = monthFields?.[0]?.querySelector('[type="month"]');

    if (firstMonthField) {
      const supportsInputMonth = firstMonthField.type === 'month';

      // at time of writing the chromium desktop <input type=month> ui is less usable than 2 selects fallback :/
      const shouldUseFallback =
        !supportsInputMonth || (supportsInputMonth && !isChromiumTouchDevice);

      let focusElement;

      if (shouldUseFallback) {
        // remove to stop html required logic from stopping submission
        monthFields.forEach((div) => div.remove());
        this.monthFieldFallbackTargets.forEach((div) =>
          div.classList.remove('hidden')
        );
        if (document.activeElement === document.body) {
          focusElement = this.monthFieldFallbackTargets[0].querySelector(
            'select'
          );
        }
      } else {
        // remove to stop html required logic from stopping submission
        this.monthFieldFallbackTargets.forEach((div) => div.remove());

        monthFields.forEach((monthField) => {
          monthField.classList.remove('invisible');
        });
        focusElement = monthFields[0].querySelector('input[type=month]');
      }

      if (focusElement) focusElement.focus();
    }
  }
}
