import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['slider', 'fill', 'value'];
  static values = {
    changed: Boolean,
    hasInitial: Boolean,
    validationError: String,
    noValueSelected: String,
  };

  async connect() {
    const [{ default: rangeSlider }] = await Promise.all([
      import(/* webpackChunkName: 'rangeslider' */ 'rangeslider-pure'),
      import(/* webpackChunkName: 'rangeslider' */ './range.scss'),
    ]);

    // requestAnimationFrame needed to init properly
    window.requestAnimationFrame(() => {
      rangeSlider.create(this.sliderTarget, {
        rangeClass: 'rangeslider',
        fillClass: 'rangeslider__fill',
        bufferClass: 'rangeslider__fill__bg',
        handleClass: 'rangeslider__handle',
        onSlide: this.handleSlide,
      });

      this.bindEvents();
      this.showSlider();

      // To position custom validation tooltip properly
      this.sliderTarget.style.height = '100%';
      this.sliderTarget.style.top = '0';

      if (this.hasInitialValue) {
        this.changedValue = true;
        this.valueTarget.innerHTML = this.sliderTarget.value;
        this.setAriaValueText(this.sliderTarget.value);
      } else {
        this.setAriaValueText(null);
      }
    });
  }

  changedValueChanged() {
    if (this.changedValue) {
      this.sliderTarget.setCustomValidity('');
    } else {
      this.sliderTarget.setCustomValidity(this.validationErrorValue);
    }
  }

  disconnect() {
    this.sliderTarget['rangeSlider'].destroy();
    this.sliderTarget.removeEventListener('change', this.handleChange);
  }

  bindEvents() {
    this.sliderTarget.addEventListener('change', this.handleChange);
  }

  showSlider() {
    this.sliderTarget['rangeSlider'].range.classList.add(
      'animate__animated',
      'animate__fadeIn',
      'animate__faster'
    );
    this.sliderTarget['rangeSlider'].range.style.visibility = 'visible';
  }

  handleSlide = (value) => {
    this.changedValue = true;
    this.valueTarget.innerHTML = value;
  };

  handleChange = (event) => {
    // Only for handling keyboard "sliding" with arrows
    // The change event will also fire on resize, so need to check if it's a keyboard event
    // See https://developer.mozilla.org/en-US/docs/Web/API/Event/isTrusted
    if (event?.isTrusted) {
      this.changedValue = true;
      this.valueTarget.innerHTML = this.sliderTarget.value;
    }

    this.setAriaValueText(this.sliderTarget.value);
  };

  setAriaValueText(value) {
    const valueText = value
      ? `${value} ${this.unit}`
      : this.noValueSelectedValue;

    this.sliderTarget.setAttribute('aria-valuetext', valueText);
  }

  get unit() {
    return this.sliderTarget.dataset.unit;
  }
}
