import { Controller } from '@hotwired/stimulus';
import confetti from 'utils/confetti';

export default class extends Controller {
  static targets = ['canvas', 'lottie'];
  static values = {
    confetti: Boolean,
  };

  async connect() {
    if (this.confettiValue) {
      await this.playAnimations();
    }
  }

  async playAnimations() {
    this.canvasTarget.width = window.innerWidth;
    this.canvasTarget.height = window.innerHeight;
    let confettiStarted = false;

    // prettier-ignore
    const [lottie, partyCone] = await Promise.all([
      import(/* webpackChunkName: 'lottie' */ 'lottie-web'),
      import(
        /* webpackChunkName: 'partycone' */ 'lottie-animations/party-cone.json'
      ),
    ]);

    this.partyConeAnimation = lottie.loadAnimation({
      container: this.lottieTarget,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: partyCone,
    });

    this.partyConeAnimation.setSpeed(1.3);

    this.partyConeAnimation.addEventListener(
      'enterFrame',
      ({ currentTime }) => {
        if (!confettiStarted && currentTime > 100) {
          confettiStarted = true;
          confetti(this.canvasTarget);
        }
      }
    );
  }

  disconnect() {
    if (this.partyConeAnimation) {
      this.partyConeAnimation.destroy();
    }
  }
}
