import { Controller } from '@hotwired/stimulus';
import { some } from 'lodash-es';
import { prefersReducedMotion } from 'utils/common';

export default class extends Controller {
  static targets = ['container'];
  static values = {
    type: String,
    header: String,
    datasets: Array,
    color: String,
    companyBodyFont: String,
  };
  static classes = ['animated', 'fadedIn'];

  async connect() {
    this.animated = !prefersReducedMotion();
    if (this.animated) {
      this.element.classList.add(...this.animatedClasses);
    }

    await this.loadLibraries();

    this.observer = new IntersectionObserver(
      (entries) => {
        const isIntersecting = some(entries, 'isIntersecting');
        if (isIntersecting) {
          if (this.animated) {
            this.element.classList.add(this.fadedInClass);
          }

          this.drawChart();
          this.observer.disconnect();
        }
      },
      {
        threshold: 0.8,
      }
    );
    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
  }

  async loadLibraries() {
    const [
      Highcharts,
      { default: highchartsAccessibility },
      { default: Color },
    ] = await Promise.all([
      import(/* webpackChunkName: 'highcharts' */ 'highcharts'),
      import(
        /* webpackChunkName: 'highcharts-accessibility' */ 'highcharts/modules/accessibility'
      ),
      import(/* webpackChunkName: 'color' */ 'color'),
    ]);

    highchartsAccessibility(Highcharts);

    this.Highcharts = Highcharts;
    this.Color = Color;
  }

  drawChart() {
    if (!this.animated) {
      this.Highcharts.setOptions({
        plotOptions: {
          series: {
            animation: false,
          },
        },
      });
    }

    this.Highcharts.chart(this.containerTarget, this.config);
  }

  get config() {
    return {
      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: this.fontFamily,
        },
        type: this.chartType,
      },
      colors: [this.colorValue],
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          borderWidth: 0,
        },
        pie: {
          borderWidth: 0,
          dataLabels: {
            format: '{point.name}',
            style: {
              fontWeight: 'normal',
              fontSize: '14px',
              textOutline: 'none',
              color: this.textColor,
            },
          },
          innerSize: '50%',
        },
      },
      series: this.series,
      title: {
        text: '',
      },
      tooltip: {
        backgroundColor: '#28282B',
        borderRadius: 6,
        borderWidth: 0,
        headerFormat: '',
        padding: 13,
        pointFormat: '{point.name}: <b>{point.y}</b>',
        style: {
          color: 'white',
          fontSize: 14,
        },
      },
      xAxis: {
        categories: this.categories,
        gridLineWidth: 0,
        labels: {
          style: {
            color: this.textColor,
            fontSize: 14,
          },
        },
        lineColor: this.gridColor,
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: '',
        },
      },
    };
  }

  get textColor() {
    const textColor = window
      .getComputedStyle(this.element)
      .getPropertyValue('color');
    return new this.Color(textColor).toString();
  }

  get gridColor() {
    return new this.Color(this.textColor).alpha(0.15).toString();
  }

  get categories() {
    return this.datasetsValue.map(({ label }) => label);
  }

  get fontFamily() {
    return window
      .getComputedStyle(this.element)
      .getPropertyValue('font-family');
  }

  get series() {
    return [
      {
        name: this.headerValue,
        data: this.datasetsValue.map(({ color, value, label }) => ({
          y: parseFloat(value),
          name: label,
          color,
        })),
      },
    ];
  }

  get chartType() {
    switch (this.typeValue) {
      case 'bar':
        return 'column';
      case 'doughnut':
        return 'pie';
      default:
        return this.typeValue;
    }
  }
}
