import { Controller } from '@hotwired/stimulus';
import fitText from 'utils/fittext';

export default class extends Controller {
  connect() {
    if (this.element.clientWidth) {
      this.refitText = fitText(this.element);
    }
    window.addEventListener('resize', this.refitText);
  }

  disconnect() {
    window.removeEventListener('resize', this.refitText);
  }
}
