import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'button',
    'confirmationButton',
    'timeLeftToConfirm',
    'message',
  ];
  static values = {
    requiresConfirmation: Boolean,
    url: String,
    waitingMessage: String,
    successMessage: String,
    errorMessage: String,
    method: String,
  };
  waitingForConfirmation = false;
  timeLeftToConfirm = 0;
  waitTime = 10;

  click(event) {
    event.preventDefault();
    if (this.requiresConfirmationValue) {
      this.setWaitingForConfirmation();
      this.showConfirmationButton();
    } else {
      this.sendRequest();
    }
  }

  confirm(event) {
    event.preventDefault();
    this.waitingForConfirmation = false;
    this.sendRequest();
  }

  sendRequest() {
    this.waitingForConfirmation = false;
    this.showMessage(this.waitingMessageValue);

    post(this.urlValue, {
      responseType: 'json',
    })
      .then((response) => response.json)
      .then((data) => {
        if (data.redirect_after_request) {
          window.location = data.redirect_url;
        } else {
          this.showMessage(
            data.ok ? this.successMessageValue : this.errorMessageValue
          );
        }
      });
  }

  setWaitingForConfirmation() {
    this.waitingForConfirmation = true;
    this.timeLeftToConfirm = this.waitTime;
    this.updateTimeLeftToConfirm();
  }

  updateTimeLeftToConfirm() {
    if (this.waitingForConfirmation) {
      if (this.timeLeftToConfirm > 0) {
        this.timeLeftToConfirmTarget.innerHTML = `(${this.timeLeftToConfirm})`;
        this.timeLeftToConfirm--;
        setTimeout(() => {
          this.updateTimeLeftToConfirm();
        }, 1000);
      } else {
        this.showMainButton();
      }
    }
  }

  showMainButton() {
    this.buttonTarget.classList.remove('hidden');
    this.confirmationButtonTarget.classList.add('hidden');
    this.messageTarget.classList.add('hidden');
  }

  showConfirmationButton() {
    this.buttonTarget.classList.add('hidden');
    this.confirmationButtonTarget.classList.remove('hidden');
    this.messageTarget.classList.add('hidden');
  }

  showMessage(message) {
    this.buttonTarget.classList.add('hidden');
    this.confirmationButtonTarget.classList.add('hidden');
    this.messageTarget.classList.remove('hidden');
    this.messageTarget.innerHTML = message;
  }
}
