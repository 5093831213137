import { Controller } from '@hotwired/stimulus';
import { isEmpty } from 'lodash-es';

import { isWebView } from 'utils/device';
import { enableCookieScripts } from 'utils/script-tags';

export default class extends Controller {
  static values = {
    socialData: Object,
    qualifyingQuestionsAnswers: Object,
    isAllQualifyingQuestionsYes: Boolean,
  };

  static targets = [
    'referrer',
    'city',
    'connectedMessage',
    'connectedMessageText',
    'email',
    'facebookId',
    'firstName',
    'lastName',
    'linkedinProfile',
    'linkedinUid',
    'linkedinUrl',
    'phone',
    'previewSummary',
    'profileImage',
    'seekApplicationId',
    'seekToken',
    'socialButtonContainer',
    'socialImageUrl',
    'summaryModal',
    'qualifyingAnswers',
    'formContent',
    'formContentWrapper',
    'qualifyingQuestionsWrapper',
    'qualifyingMessageWrapper',
  ];

  static classes = [
    'formContentWrapper',
    'form',
    'qualifyingQuestionsWrapper',
    'qualifyingQuestionsMessageWrapper',
  ];

  connect() {
    // Poor mans spam protection, city field needs to be removed from form.
    if (this.hasCityTarget) {
      this.cityTarget.remove();
    }

    // Scroll to the first occurence of Forms::Error
    const firstErrorInput = this.element.querySelector('[data-name="error"]');
    if (firstErrorInput) {
      firstErrorInput.scrollIntoView({ behavior: 'smooth' });
    }

    // Hide social buttons if in a webview
    if (isWebView()) {
      this.socialButtonContainerTarget.classList.add('!hidden');
    }

    if (window.referrer) {
      this.referrerTarget.value = window.referrer;
    }

    if (this.qualifyingAnswersTargets.length) {
      this.initQualifyingAnswers();
    }
    // Since we are lazy loading the form we need to turn on cookie
    // scripts if they have it enabled
    enableCookieScripts();
  }

  socialDataValueChanged() {
    if (!isEmpty(this.socialDataValue)) {
      this.socialButtonContainerTarget.classList.add('!hidden');
      this.connectedMessageTarget.classList.remove('hidden');
    }

    if (this.socialDataValue?.firstName && this.hasFirstNameTarget) {
      this.firstNameTarget.value = this.socialDataValue?.firstName;
    }
    if (this.socialDataValue?.lastName && this.hasLastNameTarget) {
      this.lastNameTarget.value = this.socialDataValue?.lastName;
    }
    if (this.socialDataValue?.emailAddress) {
      this.emailTarget.value = this.socialDataValue?.emailAddress;
    }
    if (this.socialDataValue?.phoneNumber && this.hasPhoneTarget) {
      this.phoneTarget.value = this.socialDataValue?.phoneNumber;
      // Trigger phonenumber formatting and validation
      const event = document.createEvent('Event');
      event.initEvent('input', true, true);
      this.phoneTarget.dispatchEvent(event);
    }
    if (this.socialDataValue?.linkedinUid) {
      this.linkedinUidTarget.value = this.socialDataValue?.linkedinUid;
      this.connectedMessageTextTarget.innerHTML = 'LinkedIn profile connected.';
    }
    if (this.socialDataValue?.linkedinUrl) {
      this.linkedinUrlTarget.value = this.socialDataValue?.linkedinUrl;
    }
    if (this.socialDataValue?.markdownSummary) {
      this.linkedinProfileTarget.value = this.socialDataValue?.markdownSummary;
      this.previewSummaryTarget.setAttribute(
        'data-common--modal-markdown-value',
        this.socialDataValue?.markdownSummary
      );
      this.summaryModalTarget.classList.remove('hidden');
    }
    if (this.socialDataValue?.facebookId) {
      this.facebookIdTarget.value = this.socialDataValue?.facebookId;
      this.connectedMessageTextTarget.innerHTML = 'Facebook profile connected.';
    }
    if (this.socialDataValue?.profileImageUrl) {
      this.socialImageUrlTarget.value = this.socialDataValue?.profileImageUrl;

      this.profileImageTarget.src = this.socialDataValue?.profileImageUrl;
      this.profileImageTarget.classList.remove('hidden');
    }

    if (this.socialDataValue?.seekApplicationId) {
      this.seekApplicationIdTarget.value = this.socialDataValue?.seekApplicationId;
      this.connectedMessageTextTarget.innerHTML = 'Seek profile connected.';
    }

    if (this.socialDataValue?.seekToken) {
      this.seekTokenTarget.value = this.socialDataValue?.seekToken;
    }

    if (this.socialDataValue?.resumeUrl) {
      const resumeUploadContainer = document.getElementById(
        'upload_resume_field'
      );
      if (resumeUploadContainer) {
        resumeUploadContainer.setAttribute(
          'data-forms--inputs--upload-files-value',
          JSON.stringify([
            {
              name: this.socialDataValue?.resumeFilename,
              url: this.socialDataValue?.resumeUrl,
            },
          ])
        );
      }
    }
  }

  setQualifyingAnswer(questionId, value) {
    this.qualifyingQuestionsAnswersValue = {
      ...this.qualifyingQuestionsAnswersValue,
      [questionId]: value,
    };
  }

  updateQualifyingAnswer(questionId, value) {
    this.setQualifyingAnswer(questionId, value);
    this.checkQualifyingAnswers();
  }

  initQualifyingAnswers() {
    for (const answer of this.qualifyingAnswersTargets) {
      this.setQualifyingAnswer(answer.dataset.questionId, null);
    }
    this.checkQualifyingAnswers();
  }

  isCandidateQualified() {
    return Object.values(this.qualifyingQuestionsAnswersValue).every(
      (value) => value === true
    );
  }

  hasAnyFalseQualifyingAnswers() {
    return Object.values(this.qualifyingQuestionsAnswersValue).some(
      (value) => value !== null && value === false
    );
  }

  getFocusableElements(element) {
    return [
      ...element.querySelectorAll(
        'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"]), div.ba-videorecorder-chooser-button-0, div.ba-videorecorder-chooser-button-1'
      ),
    ].filter(
      (el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
    );
  }

  handleFormContent() {
    if (this.qualifyingAnswersTargets.length) {
      this.checkQualifyingAnswers();
    }
  }

  checkQualifyingAnswers() {
    if (this.isCandidateQualified()) {
      this.enableFormContent();
    } else {
      this.disableFormContent();
    }

    if (this.hasAnyFalseQualifyingAnswers()) {
      this.showQualifyingWarningMessage();
    } else {
      this.hideQualifyingWarningMessage();
    }
  }

  enableFormContent() {
    if (this.hasFormContentTarget && this.hasFormContentWrapperTarget) {
      const focusableElements = this.getFocusableElements(
        this.formContentTarget
      );
      for (const element of focusableElements) {
        if (element.tagName.toLowerCase() === 'div') {
          element.setAttribute('tabindex', '0');
        } else {
          element.removeAttribute('tabindex');
        }
      }
      this.formContentWrapperTarget.classList.remove(
        ...this.formContentWrapperClasses
      );
      this.formContentTarget.classList.remove(...this.formClasses);
    }
  }

  disableFormContent() {
    if (this.hasFormContentTarget && this.hasFormContentWrapperTarget) {
      const focusableElements = this.getFocusableElements(
        this.formContentTarget
      );
      for (const element of focusableElements) {
        element.setAttribute('tabindex', '-1');
      }
      this.formContentWrapperTarget.classList.add(
        ...this.formContentWrapperClasses
      );
      this.formContentTarget.classList.add(...this.formClasses);
    }
  }

  hideQualifyingWarningMessage() {
    this.qualifyingQuestionsWrapperTarget.classList.remove(
      ...this.qualifyingQuestionsWrapperClasses
    );

    this.qualifyingMessageWrapperTarget.classList.add(
      ...this.qualifyingQuestionsMessageWrapperClasses
    );
  }

  showQualifyingWarningMessage() {
    this.qualifyingQuestionsWrapperTarget.classList.add(
      ...this.qualifyingQuestionsWrapperClasses
    );

    this.qualifyingMessageWrapperTarget.classList.remove(
      ...this.qualifyingQuestionsMessageWrapperClasses
    );
  }
}
