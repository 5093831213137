import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'rateLimitError',
    'companyInput',
    'passwordInput',
    'emailInput',
    'companyError',
    'passwordError',
    'emailError',
  ];

  beforeSubmit(event) {
    if (this.element !== event.target) {
      return;
    }

    if (!this.element.checkValidity()) {
      event.detail.formSubmission.stop();
    }

    this.updateFormErrorState();
  }

  afterSubmit(event) {
    const response =
      event.detail.formSubmission?.result?.fetchResponse?.response;

    if (this.element !== event.target || !response) {
      return;
    }

    if (response.status === 429) {
      return this.showRateLimitError();
    }
  }

  showRateLimitError() {
    this.rateLimitErrorTarget.classList.remove('hidden');
  }

  updateFormErrorState() {
    [
      [this.companyInputTarget, this.companyErrorTarget],
      [this.passwordInputTarget, this.passwordErrorTarget],
      [this.emailInputTarget, this.emailErrorTarget],
    ].forEach((el) => {
      if (!el[0].checkValidity()) {
        el[0].classList.add('border-red-600');
        el[1].classList.remove('hidden');
      } else {
        el[0].classList.remove('border-red-600');
        el[1].classList.add('hidden');
      }
    });
  }
}
