import { Controller } from '@hotwired/stimulus';

const CHAT_DELAY = 5000;

export default class extends Controller {
  static values = { subdomainHost: String };

  async connect() {
    await import(/* webpackChunkName: 'chat' */ './chat.scss');
    this.chatButton = this.element.querySelector('#messenger-launcher-frame');
    this.chatBox = this.element.querySelector('#messenger-frame');

    if (this.chatButton && this.chatBox) {
      if (localStorage.getItem('MESSENGER_STATE')) {
        this.loadIframes();
      } else {
        this.loadTimeout = setTimeout(() => {
          this.loadIframes();
        }, CHAT_DELAY);
      }

      window.addEventListener('message', this.handleIframeMessage);

      this.chatButton.onload = this.handleButtonLoad;
    }
  }

  disconnect() {
    if (this.chatButton && this.chatBox) {
      clearTimeout(this.loadTimeout);
      window.removeEventListener('message', this.handleIframeMessage);
      window.removeEventListener(
        '(orientation: portrait)',
        this.handleOrientation
      );
    }
  }

  loadIframes() {
    this.chatButton.setAttribute('src', this.chatButton.dataset.src);
    this.chatBox.setAttribute('src', this.chatBox.dataset.src);
  }

  handleButtonLoad = () => {
    this.element.classList.remove('opacity-0');
  };

  handleOrientation = (e) => {
    const portrait = e.matches;
    this.chatButton.contentWindow.postMessage(
      { type: 'mobile', portrait },
      '*'
    );
    this.chatButton.style.height = `${window.innerHeight}px`;
    this.chatButton.style.width = `${window.innerWidth}px`;
  };

  handleIframeMessage = (event) => {
    if (event.data === 'showMessenger') {
      this.chatBox.classList.remove('hidden');
      this.chatBox.contentWindow.postMessage(
        event.data,
        this.chatBox.dataset.url
      );
    } else if (event.data === 'hideMessenger') {
      this.chatBox.classList.add('hidden');
    } else if (event.data === 'showLauncher') {
      this.chatButton.classList.remove('hidden');
    } else if (event.data.message) {
      this.chatBox.contentWindow.postMessage(
        event.data,
        this.chatBox.dataset.url
      );
    } else if (
      event.data.height &&
      event.data.width &&
      event.data.origin === 'launcher'
    ) {
      if (event.data.mobileExpanded) {
        this.chatButton.style.height = `${window.innerHeight}px`;
        this.chatButton.style.width = `${window.innerWidth}px`;
        this.element.classList.add(...['z-modal', 'video-expanded-mobile']);
        const portrait = window.matchMedia('(orientation: portrait)').matches;
        this.chatButton.contentWindow.postMessage(
          { type: 'mobile', portrait },
          '*'
        );
        document.body.classList.add('fixed');

        window
          .matchMedia('(orientation: portrait)')
          .addEventListener('change', this.handleOrientation);
      } else {
        this.chatButton.style.height = `${event.data.height}px`;
        this.chatButton.style.width = `${event.data.width}px`;
        this.element.classList.remove(...['z-modal', 'video-expanded-mobile']);
        document.body.classList.remove('fixed');
      }
    }
  };
}
