import { Controller } from '@hotwired/stimulus';
import hoverintent from 'hoverintent';
import { isMobile } from 'utils/device';
import { observeElementIntersecting } from 'utils';

export default class extends Controller {
  static targets = [
    'cover',
    'coverButton',
    'floatingButton',
    'form',
    'turboFrame',
  ];

  static values = {
    coverVisible: { default: true, type: Boolean },
    jobId: Number,
    jobApplicationUrl: String,
    formVisible: Boolean,
    formSeen: Boolean,
    underForm: Boolean,
  };

  animationDuration = 250;

  connect() {
    this.observeElements();

    window.addEventListener('overlay-job-application-close', () => {
      this.hideFormOverlay();
    });

    this.setupApplyButtonListener(this.floatingButtonTarget);
    this.setupApplyButtonListener(this.coverButtonTarget);

    document.addEventListener('scroll', this.handleScrolled);
  }

  disconnect() {
    this.coverObserver.disconnect();
    this.formObserver.disconnect();
    document.removeEventListener('scroll', this.handleScrolled);

    this.formSeenValue = false;
  }

  observeElements() {
    this.coverObserver = observeElementIntersecting(
      this.coverTarget,
      ({ isIntersecting }) => {
        this.coverVisibleValue = isIntersecting;
      },
      { threshold: 0.01 }
    );

    this.formObserver = observeElementIntersecting(
      this.formTarget,
      ({ isIntersecting, boundingClientRect }) => {
        this.formVisibleValue = isIntersecting;
        this.underFormValue = !isIntersecting && boundingClientRect.y < 0;

        if (!this.formSeenValue && isIntersecting) {
          this.formSeenValue = true;
        }
      }
    );
  }

  setupApplyButtonListener(element) {
    // When hovering apply button, prepare section animation for better performance
    // as well as load the form
    hoverintent(
      element,
      () => {
        this.loadApplicationForm();
      },
      () => {}
    );
  }

  handleScrolled = () => {
    requestAnimationFrame(() => {
      if (window.scrollY > this.formTarget.offsetTop * 0.25) {
        this.loadApplicationForm();
        document.removeEventListener('scroll', this.handleScrolled);
      }
    });
  };

  loadApplicationForm() {
    if (this.hasTurboFrameTarget) {
      this.turboFrameTarget.removeAttribute('loading');
    }
  }

  setElementVisibilities() {
    const showFloatingButton =
      !this.coverVisibleValue && !this.formVisibleValue && !this.underFormValue;
    this.setFloatingButtonVisibility(showFloatingButton);
    this.setChatVisibility(showFloatingButton);
  }

  coverVisibleValueChanged() {
    this.setElementVisibilities();
  }

  formVisibleValueChanged() {
    this.setElementVisibilities();
  }

  underFormValueChanged() {
    this.setElementVisibilities();
  }

  formSeenValueChanged() {
    if (this.formSeenValue) {
      if (window.ga) {
        window.ga('send', 'event', {
          eventCategory: 'Teamtailor',
          eventAction: 'Application form seen',
          eventLabel: this.jobIdValue,
        });
      }

      if (window.trackPageView) {
        window.trackPageView(this.jobApplicationUrlValue);
      }
    }
  }

  setFloatingButtonVisibility(showFloatingButton) {
    this.floatingButtonTarget.setAttribute(
      'data-jobs--floating-apply-button-visible-value',
      showFloatingButton
    );

    if (showFloatingButton) this.floatingButtonTarget.style.left = '';
  }

  setChatVisibility(showFloatingButton) {
    const chat = document.querySelector('[data-controller="common--chat"]');
    if (chat)
      if (isMobile() && (showFloatingButton || this.formVisibleValue))
        chat.style.opacity = '0%';
      else chat.style.opacity = '100%';
  }

  showFormOverlay() {
    // Prevents button from jumping to the right when scrollbar disappears
    const buttonStyle = window.getComputedStyle(this.floatingButtonTarget);
    this.floatingButtonTarget.style.left = buttonStyle.left;
    this.formTarget.setAttribute('data-jobs--overlay-overlay-value', true);
  }

  hideFormOverlay() {
    this.formTarget.setAttribute('data-jobs--overlay-overlay-value', false);
  }
}
