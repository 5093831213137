import { throttle } from 'lodash-es';
import { Controller } from '@hotwired/stimulus';

export default class RedirectButton extends Controller {
  static targets = ['button'];
  static values = {
    isListening: { type: Boolean, default: false },
  };
  static WATCHED_EVENTS = ['mousemove', 'keydown', 'touchstart'];

  connect() {
    this.startListening();
  }

  disconnect() {
    this.stopListening();
  }

  redirect() {
    let redirect_url = this.buttonTarget.href;
    this.buttonTarget.removeAttribute('href');
    document.location = redirect_url;
  }

  startListening = () => {
    if (!this.isListeningValue) {
      this.isListeningValue = true;
      RedirectButton.WATCHED_EVENTS.forEach((event) => {
        document.addEventListener(event, this.watchEvent);
      });
    }
  };

  stopListening = () => {
    if (this.isListeningValue) {
      this.isListeningValue = false;
      RedirectButton.WATCHED_EVENTS.forEach((event) => {
        document.removeEventListener(event, this.watchEvent);
      });
    }
  };

  watchEvent = throttle(
    () => {
      this.redirect();
      this.stopListening();
    },
    1000,
    { trailing: false }
  );
}
