/* global ga */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['formRow', 'addRowButton', 'rowContainer'];
  static values = {
    turbo: Boolean,
  };

  count = 1;

  connect() {
    if (!this.turboValue) {
      this.toggleAddRowButton();
    }
  }

  addFormRow() {
    const newRow = this.formRowTargets[0].cloneNode(true);
    newRow.querySelectorAll('input').forEach((input) => {
      input.value = '';
      input.name = input.name.replace(/\[\d*\]/, `[${this.count}]`);
    });
    newRow.querySelector('button').classList.remove('hidden');
    this.rowContainerTarget.append(newRow);
    this.count++;
    this.toggleAddRowButton();
  }

  toggleAddRowButton() {
    this.addRowButtonTarget.classList.toggle(
      'hidden',
      this.formRowTargets.length >= 3
    );
  }

  removeFormRow(event) {
    const row = event.target.closest(
      '[data-forms--references-target="formRow"]'
    );
    row.remove();
    this.toggleAddRowButton();
  }

  async onSubmitSuccess(event) {
    const responseNode = document.createElement('div');
    responseNode.innerHTML = await event.detail.fetchResponse.responseHTML;
    this.element.replaceWith(responseNode.firstChild);

    if (ga) {
      ga('send', 'event', {
        eventCategory: 'Teamtailor',
        eventAction: 'Recommendation added',
        eventLabel: 'application',
      });
    }
  }
}
