const width = '100%';
const height = '152';

function createSpotifyIframe(trackId) {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', `https://open.spotify.com/embed/track/${trackId}`);
  iframe.setAttribute('width', width);
  iframe.setAttribute('height', height);
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allowtransparency', 'true');
  iframe.setAttribute('allow', 'encrypted-media');

  return iframe;
}

function createAppleMusicIframe(url) {
  const iframe = document.createElement('iframe');
  iframe.setAttribute(
    'src',
    url.replace('music.apple.com', 'embed.music.apple.com')
  );
  iframe.setAttribute('width', width);
  iframe.setAttribute('height', height);
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allowtransparency', 'true');
  iframe.setAttribute('allow', 'encrypted-media');

  return iframe;
}

function createTidalIframe(id) {
  const iframe = document.createElement('iframe');
  iframe.setAttribute(
    'src',
    `https://embed.tidal.com/tracks/${id}?disableAnalytics=true`
  );
  iframe.setAttribute('style', `width:${width};height:${height}px`);
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allowfullscreen', 'allowfullscreen');

  return iframe;
}

function createDeezerIframe(id) {
  const iframe = document.createElement('iframe');
  iframe.setAttribute(
    'src',
    `https://widget.deezer.com/widget/auto/track/${id}?tracklist=false`
  );
  iframe.setAttribute('width', width);
  iframe.setAttribute('height', height);
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allowtransparency', 'true');
  iframe.setAttribute('allow', 'encrypted-media');

  return iframe;
}

function matchSpotifyTrackId(url) {
  const regex = new RegExp('^https://open.spotify.com/track/([a-zA-Z0-9]+)');
  const match = url.match(regex);
  if (match) {
    return match[1];
  }
  return null;
}

function matchAppleMusicUrl(url) {
  if (url.includes('music.apple.com')) {
    return url;
  }
}

function matchTidalId(url) {
  const regex = /^https?:\/\/listen\.tidal\.com\/track\/(\d+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}

function matchDeezerId(url) {
  const regex = /^https?:\/\/(?:www\.)?deezer\.com\/track\/(\d+)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  }
  return null;
}

export function isValidSongLink(url) {
  return (
    matchSpotifyTrackId(url) ||
    matchAppleMusicUrl(url) ||
    matchTidalId(url) ||
    matchDeezerId(url)
  );
}

export function createSongEmbedIframe(url) {
  const spotifyTrackId = matchSpotifyTrackId(url);
  const appleMusicUrl = matchAppleMusicUrl(url);
  const tidalId = matchTidalId(url);
  const deezerId = matchDeezerId(url);

  const spotifyIframe = spotifyTrackId
    ? createSpotifyIframe(spotifyTrackId)
    : null;
  const appleMusicIframe = appleMusicUrl
    ? createAppleMusicIframe(appleMusicUrl)
    : null;
  const tidalIframe = tidalId ? createTidalIframe(tidalId) : null;
  const deezerIframe = deezerId ? createDeezerIframe(deezerId) : null;

  return spotifyIframe || appleMusicIframe || tidalIframe || deezerIframe;
}
