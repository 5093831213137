import { Controller } from '@hotwired/stimulus';

export default class GroupJobs extends Controller {
  static targets = ['container'];

  toggle(e) {
    e.preventDefault();
    e.currentTarget.remove();
    this.containerTarget.classList.remove('hidden');
    this.containerTarget.classList.add('mb-4');
    this.containerTarget.style.maxHeight = `${this.containerTarget.scrollHeight}px`;
  }
}
