import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['radio', 'otherInput'];

  connect() {
    if (this.hasOtherInputTarget) {
      this.radioTargets.forEach((element) => {
        element.addEventListener('change', this.radioChange);
      });
    }
  }

  radioChange = ({ target }) => {
    const isOther = target.value === 'other';

    this.otherInputTarget.classList.toggle('hidden', !isOther);
    this.otherInputTarget.disabled = !isOther;
  };
}
