import { Controller } from '@hotwired/stimulus';

// Controller to re-render the background video so it doesn't break on Safari
// See: https://github.com/hotwired/turbo/issues/892
export default class ReRenderVideoController extends Controller {
  connect() {
    this.reRenderMediaElement();
  }

  // Fix potentially blank videos due to autoplay rules by re-inserting it into the DOM
  reRenderMediaElement() {
    const videoElement = this.videoElementTarget;
    const clone = videoElement.cloneNode(true);
    videoElement.parentNode.insertBefore(clone, videoElement);
    videoElement.remove();
  }
}

ReRenderVideoController.targets = ['videoElement'];
