import { Controller } from '@hotwired/stimulus';
import { observeElementVisible } from 'utils';
import { hasAcceptedCookieCategory } from 'utils/cookies';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static targets = ['player', 'controlsTemplate', 'thumbnail'];
  static classes = ['hidden'];

  static values = {
    autoplay: Boolean,
    aspectRatio: String,
    thumbnailImage: Boolean,
    isExternal: Boolean,
    noControls: Boolean,
    useIntersection: Boolean,
  };

  async connect() {
    observeElementVisible(this.element, async () => {
      await this.initializePlayer();

      if (this.useIntersectionValue) {
        useIntersection(this);
      }
    });

    if (!hasAcceptedCookieCategory('marketing') && this.isExternalValue) {
      this.hideThumbnail();
      document.addEventListener('teamtailor:cookies-changed', async () => {
        await this.initializePlayer();
      });
    }
  }

  get controls() {
    return this.controlsTemplateTarget.innerHTML;
  }

  appear() {
    if (this.player.ready) {
      this.playVideo();
    }
    this.player.once('ready', () => {
      this.playVideo();
    });
  }

  disappear() {
    this.stopVideo();
  }

  disconnect() {
    if (this.player) {
      this.player.destroy();
    }
  }

  playVideo = () => {
    this.player?.play();
  };

  stopVideo = () => {
    if (this.player?.playing) {
      this.player?.pause();
      this.player?.stop();
    }
    this.showThumbnail();
  };

  async initializePlayer() {
    if (!hasAcceptedCookieCategory('marketing') && this.isExternalValue) {
      return;
    }
    this.showThumbnail();

    const [{ default: Plyr }] = await Promise.all([
      import(/* webpackChunkName: 'plyr' */ 'plyr'),
      import(
        /* webpackChunkName: 'video-player-style' */ './video_player.scss'
      ),
    ]);

    this.player = new Plyr(this.playerTarget, {
      controls: this.noControlsValue ? [] : this.controls,
      autoplay: this.autoplayValue,
      hideControls: false,
      ratio: this.aspectRatioValue,
      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: true,
        container: null,
      },
      youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
      },
      vimeo: {
        dnt: true,
      },
    });

    this.player.on('play', () => {
      this.hideThumbnail();
    });

    if (this.hasPlayerTarget) {
      this.playerTarget.classList.remove('invisible');
    }
  }

  hideThumbnail() {
    if (this.hasThumbnailTarget) {
      this.thumbnailTarget.classList.add('invisible');
    }
  }

  showThumbnail() {
    if (this.hasThumbnailTarget) {
      this.thumbnailTarget.classList.remove('invisible');
    }
  }
}
