import { Controller } from '@hotwired/stimulus';
import { isMobile } from 'utils/device';

export default class extends Controller {
  static targets = ['animate'];
  moveBack = false;

  connect() {
    // Disable until we fix mobile animation issues
    if (!isMobile()) {
      document.addEventListener('turbo:click', this.setAnimationDirection);
      document.addEventListener('turbo:submit-end', this.animateOut);
      document.addEventListener('turbo:before-render', this.animateIn);
    }
  }

  disconnect() {
    // Disable until we fix mobile animation issues
    if (!isMobile()) {
      document.removeEventListener('turbo:click', this.setAnimationDirection);
      document.removeEventListener('turbo:submit-end', this.animateOut);
      document.removeEventListener('turbo:before-render', this.animateIn);
    }
  }

  animationDirectionOut() {
    if (isMobile()) {
      return this.moveBack ? 'slideOutRight' : 'slideOutLeft';
    } else {
      return this.moveBack ? 'fadeOutDown' : 'fadeOutUp';
    }
  }

  animationDirectionIn() {
    if (isMobile()) {
      return this.moveBack ? 'slideInLeft' : 'slideInRight';
    } else {
      return this.moveBack ? 'fadeInDown' : 'fadeInUp';
    }
  }

  setAnimationDirection = ({ target }) => {
    this.moveBack =
      target.hasAttribute('data-animate-back') &&
      target.dataset.animateBack === 'true';
  };

  animateIn = ({ detail }) => {
    const newAnimateTarget =
      detail.newBody &&
      detail.newBody.querySelector(
        `[data-${this.identifier}-target='animate']`
      );

    if (newAnimateTarget) {
      newAnimateTarget.classList.add(
        'animate__animated',
        'animate__faster',
        `animate__${this.animationDirectionIn()}`
      );
    }
  };

  animateOut = () => {
    if (this.hasAnimateTarget) {
      this.animateTarget.classList.add(
        'animate__animated',
        'animate__faster',
        `animate__${this.animationDirectionOut()}`
      );
    }
  };
}
