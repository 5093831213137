import { Controller } from '@hotwired/stimulus';
import { imageLoaded } from 'utils/common';

export default class GalleryRow extends Controller {
  static targets = ['image'];

  async connect() {
    this.imageTargets.forEach((image) => {
      imageLoaded(image).then((image) => {
        image.classList.add('animate__fadeIn');
        image.classList.remove('invisible');
      });
    });
  }
}
