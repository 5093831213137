import { Controller } from '@hotwired/stimulus';
import { uniq } from 'lodash-es';

import './text.scss';

export default class extends Controller {
  static targets = ['text', 'suggestionElement', 'suggestionElementContainer'];

  static values = {
    suggestedEmail: String,
  };

  connect() {
    if (this.shouldCorrectEmail) {
      this.textTarget.addEventListener('change', this.emailChanged);
    }
  }

  emailChanged = async ({ target }) => {
    // prettier-ignore
    const [{ default: emailSpellChecker, POPULAR_TLDS }, { default: tlds }] = await Promise.all([
      import(/* webpackChunkName: 'email-spell-checker' */ '@zootools/email-spell-checker'),
      import(/* webpackChunkName: 'tlds' */ 'utils/tlds'),
    ]);

    const suggestedEmail = emailSpellChecker.run({
      email: target.value,
      topLevelDomains: uniq([...tlds, ...POPULAR_TLDS]),
    });

    this.suggestedEmailValue = suggestedEmail?.full || '';
  };

  suggestedEmailValueChanged() {
    if (this.suggestedEmailValue) {
      this.suggestionElementTarget.innerHTML = this.suggestedEmailValue;
      this.suggestionElementContainerTarget.classList.remove('hidden');
    } else {
      this.suggestionElementContainerTarget.classList.add('hidden');
    }
  }

  useSuggestedEmail = (event) => {
    event.preventDefault();
    this.textTarget.value = this.suggestedEmailValue;
    this.suggestedEmailValue = '';
  };

  get shouldCorrectEmail() {
    return (
      this.hasTextTarget &&
      this.textTarget.type == 'email' &&
      this.textTarget.dataset.suggestEmailCorrection
    );
  }

  disconnect() {
    this.textTarget.removeEventListener('change', this.emailChanged);
  }
}
