import { Controller } from '@hotwired/stimulus';
import twemoji from 'twemoji';

export default class TwemojiController extends Controller {
  connect() {
    twemoji.parse(document.body, {
      base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
    });
  }
}
