import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

import { isMobile } from 'utils/device';

export default class UploadInputComponentController extends Controller {
  static targets = [
    'errorContainer',
    'errorMsg',
    'previewsContainer',
    'templatePreview',
    'trigger',
    'secondaryTrigger',
    'mobileText',
    'desktopText',
  ];

  static values = {
    acceptedFiles: String,
    maxFileSize: Number,
    maxFiles: Number,
    translations: Object,
    files: Array,
    required: Boolean,
  };

  static outlets = ['careersite--form'];

  count = 0;

  async connect() {
    if (isMobile()) {
      this.mobileTextTarget.classList.remove('hidden');
    } else {
      this.desktopTextTarget.classList.remove('hidden');
    }

    // prettier-ignore
    const [{ default: Dropzone }, { captureException }] = await Promise.all([
      import(
        /* webpackChunkName: 'dropzone' */ 'dropzone'
      ),
      import(/* webpackChunkName: 'sentry-browser' */ '@sentry/browser'),
    ])

    this.dropZone = new Dropzone(this.element, {
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: false,
      clickable: [this.secondaryTriggerTarget],
      createImageThumbnails: false,
      hiddenInputContainer: this.triggerTarget,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      autoProcessQueue: false,
      paramName: 'file',
      parallelUploads: 1,
      uploadMultiple: false,
      url: '/',
      previewTemplate: this.templatePreviewTarget.innerHTML,
      previewsContainer: this.previewsContainerTarget,
      accept: (file, done) => {
        if (this.dropZone.options.maxFiles < this.dropZone.files.length) {
          done(this.dropZone.options.dictMaxFilesExceeded);
        } else {
          post('/uploads/presigned_data', {
            responseType: 'json',
          })
            .then((response) => response.json)
            .then((data) => {
              file.uploadURL = data.url;
              file.params = data.fields;

              const escapedFileName = file.name
                .replace(/[^a-z0-9_\-.]/gi, '_')
                .replace(/_{2,}/g, '_')
                .toLowerCase();

              file.params.key = file.params.key.replace(
                '${filename}',
                escapedFileName
              );
              return this.searchForXSS(file, this.translationsValue?.xssError);
            })
            .then((file) => {
              done();
              setTimeout(() => this.dropZone.processFile(file));
            })
            .catch((error) => {
              captureException(error);
              done(error);
            });
        }
      },
      ...this.translationsValue,
      dictInvalidFileType: this.translationsValue.dictInvalidFileType?.replace(
        '{{acceptedFiles}}',
        this.acceptedFiles.replaceAll(',.', ', .')
      ),
    });

    this.makeHiddenInputAccessible();
    this.bindEvents();
    this.addFiles();
    this.toggleRequired();

    Dropzone.autoDiscover = false;

    if (this.hasCareersiteFormOutlet && this.hasTriggerTarget) {
      this.careersiteFormOutlet.handleFormContent();
    }
  }

  disconnect() {
    this.dropZone.disable();
  }

  makeHiddenInputAccessible() {
    this.dropZone.hiddenFileInput.style.visibility = '';
    this.dropZone.hiddenFileInput.style.width = '100%';
    this.dropZone.hiddenFileInput.style.height = '100%';
    this.dropZone.hiddenFileInput.style.cursor = 'pointer';
    this.dropZone.hiddenFileInput.style.opacity = '0';
    this.dropZone.hiddenFileInput.id = this.element
      .querySelector('label')
      .getAttribute('for');
  }

  addFiles() {
    if (this.files.length > 0 && this.dropZone) {
      this.files.forEach((file) => {
        this.dropZone.files.push(file);
        this.dropZone.displayExistingFile(file, null, null, null, false);
      });
    }
  }

  bindEvents() {
    this.dropZone.on('addedfile', this.handleAddedFile);
    this.dropZone.on('processing', this.handleProcessing);
    this.dropZone.on('removedfile', this.handleRemovedFile);
    this.dropZone.on('success', this.handleSuccess);
    this.dropZone.on('error', this.handleError);
    this.dropZone.on('queuecomplete', this.handleQueueComplete);
  }

  searchForXSS(file, errorMessage = 'XSS error') {
    return new Promise((resolve, reject) => {
      if (!file || file.type !== 'application/pdf') {
        resolve(file);
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        const contents = e.target.result;
        const jsInjection = contents.match(/\/JS(\s*)\(/);

        if (jsInjection) {
          reject(errorMessage);
        } else {
          resolve(file);
        }
      };
      reader.readAsText(file);
    });
  }

  findElement(root, selector) {
    if (typeof root == 'string') {
      selector = root;
      root = document;
    }
    return root.querySelector(selector);
  }

  toggleTriggers() {
    this.triggerTarget.classList.toggle('hidden', this.hasFiles);
    if (this.maxFiles > 1) {
      this.secondaryTriggerTarget.classList.toggle(
        'hidden',
        !this.hasFiles || this.limitReached
      );
      this.secondaryTriggerTarget.classList.toggle(
        'flex',
        this.hasFiles && !this.limitReached
      );
    }
  }

  toggleRequired() {
    if (this.required) {
      this.dropZone.hiddenFileInput.required = true;
      this.dropZone.hiddenFileInput.disabled = this.hasFiles ? true : false;
    }
  }

  handleAddedFile = (data) => {
    this.count++;
    if (data.download_url || data.url) {
      data.previewElement.setAttribute(
        'data-forms--inputs--upload-preview-download-url-value',
        data.download_url || data.url
      );
    }
    if (data.id) {
      data.previewElement.setAttribute(
        'data-forms--inputs--upload-preview-id-value',
        data.id
      );
    }

    data.previewElement.setAttribute(
      'data-forms--inputs--upload-preview-number-value',
      this.count
    );

    this.errorContainerTarget.classList.remove('flex');
    this.errorContainerTarget.classList.add('hidden');

    this.toggleRequired();
    this.toggleTriggers();
  };

  handleProcessing = (file) => {
    this.submitButton.disabled = true;
    this.dropZone.options.url = file.uploadURL;
    let params = file.params;
    params['Content-Type'] = file.type;
    this.dropZone.options.params = params;
  };

  handleRemovedFile = (data) => {
    if (this.filePersisted(data)) {
      const deleteInput = data.previewElement
        .querySelector(
          '[data-forms--inputs--upload-preview-target="deleteInput"]'
        )
        .cloneNode();
      deleteInput.disabled = false;
      deleteInput.value = 1;
      this.element.append(deleteInput);
    }

    const idInput = data.previewElement.querySelector(
      '[data-forms--inputs--upload-preview-target="idInput"]'
    );
    if (!idInput.disabled) {
      this.element.append(idInput.cloneNode());
    }

    this.toggleRequired();
    this.toggleTriggers();
    setTimeout(() => this.makeHiddenInputAccessible());
  };

  handleSuccess = (data, response) => {
    let fileUrl = decodeURIComponent(data.xhr.getResponseHeader('Location'));
    if (fileUrl === 'null') {
      const [, location] = response.match(/<Location>(.*)<\/Location>/i);
      fileUrl = location;
    }

    data.previewElement.setAttribute(
      'data-forms--inputs--upload-preview-url-value',
      fileUrl
    );

    this.makeHiddenInputAccessible();
  };

  handleError = (file, message) => {
    this.dropZone.removeFile(file);
    this.errorMsgTarget.textContent = message;
    this.errorContainerTarget.classList.add('flex');
    this.errorContainerTarget.classList.remove('hidden');
    this.makeHiddenInputAccessible();
  };

  handleQueueComplete = () => {
    this.submitButton.disabled = false;
  };

  filesValueChanged() {
    if (this.files.length > 0) {
      this.addFiles();
    }
  }

  filePersisted(data) {
    const persistedFile = this.filesValue.find(
      (file) => file.name === data.name && !!data.download_url
    );

    return !!persistedFile;
  }

  get hasFiles() {
    return this.dropZone.files.length > 0;
  }

  get limitReached() {
    return this.dropZone.files.length >= this.maxFiles;
  }

  get files() {
    return this.filesValue || [];
  }

  get maxFiles() {
    return this.maxFilesValue || 1;
  }

  get maxFileSize() {
    return this.maxFileSizeValue || 10;
  }

  get acceptedFiles() {
    return this.acceptedFilesValue;
  }

  get form() {
    return this.element.closest('form');
  }

  get required() {
    return this.requiredValue || false;
  }

  get submitButton() {
    return this.findElement(
      this.form,
      'input[type=submit], button[type=submit]'
    );
  }
}
