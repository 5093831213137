import { Controller } from '@hotwired/stimulus';

const SCREEN_TYPES = {
  mobile: 'mobile',
  desktop: 'desktop',
};

export default class extends Controller {
  static targets = [
    'jobsSectionContainer',
    'cardModal',
    'greeting',
    'mediaItems',
  ];
  static values = {
    isPreview: Boolean,
  };

  connect() {
    document.addEventListener('turbo:morph', this.handleJobsListLiveUpdates);
    document.addEventListener(
      'turbo:before-fetch-request',
      this.handleBlogPostsLiveUpdates
    );
  }

  disconnect() {
    document.removeEventListener('turbo:morph', this.handleJobsListLiveUpdates);
    document.removeEventListener(
      'turbo:before-fetch-request',
      this.handleBlogPostsLiveUpdates
    );
  }

  handleJobsListLiveUpdates = (event) => {
    const width = window.innerWidth;
    const screenType = width < 700 ? SCREEN_TYPES.mobile : SCREEN_TYPES.desktop;
    let jobListTemplate = event.detail.newElement.querySelector(
      `template[data-connect--jobs-list-target=${screenType}]`
    );

    if (!jobListTemplate) {
      jobListTemplate = event.detail.currentElement.querySelector(
        `template[data-connect--jobs-list-target=${screenType}]`
      );
    }

    if (jobListTemplate) {
      this.jobsSectionContainerTarget.innerHTML = jobListTemplate.innerHTML;
    }
  };

  handleBlogPostsLiveUpdates = (e) => {
    // This logic is needed to force the card modal to regenerate after morphing the page

    if (this.hasCardModalTarget) {
      if (this.shouldDeleteCardModals(e)) {
        this.cardModalTargets.forEach((x) => x.remove());
      } else if (this.shouldRemoveModalController(e)) {
        this.cardModalTargets.forEach((x) =>
          x.removeAttribute('data-controller')
        );
      }
    }

    if (this.hasMediaItemsTarget && this.shouldRemoveModalController(e)) {
      this.mediaItemsTargets.forEach((x) => {
        x.remove();
      });
    }
  };

  shouldDeleteCardModals(e) {
    return (
      e.detail.fetchOptions.method === 'post' &&
      e.detail.url.pathname.includes('/connect/profile') &&
      (!e.detail.fetchOptions.headers['Turbo-Frame'] ||
        e.detail.fetchOptions.headers['Turbo-Frame'] === 'connect_questions')
    );
  }

  shouldRemoveModalController(e) {
    return (
      e.detail.url.pathname.includes('/connect/dashboard') &&
      this.isPreviewValue
    );
  }

  closeGreeting() {
    this.greetingTarget.classList.add('hidden');
  }
}
