const possibleColors = [
  'DodgerBlue',
  'OliveDrab',
  'Gold',
  'Pink',
  'SlateBlue',
  'LightBlue',
  'Gold',
  'Violet',
  'PaleGreen',
  'SteelBlue',
  'SandyBrown',
  'Chocolate',
  'Crimson',
];

function randomFromTo(from, to) {
  return Math.floor(Math.random() * (to - from + 1) + from);
}

function ConfettiParticle(width, height, context, maxConfettis) {
  this.x = Math.random() * width; // x
  this.y = Math.random() * height - height; // y
  this.r = randomFromTo(11, 33); // radius
  this.d = Math.random() * maxConfettis + 11;
  this.color =
    possibleColors[Math.floor(Math.random() * possibleColors.length)];
  this.tilt = Math.floor(Math.random() * 33) - 11;
  this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
  this.tiltAngle = 0;

  this.draw = function () {
    context.beginPath();
    context.lineWidth = this.r / 2;
    context.strokeStyle = this.color;
    context.moveTo(this.x + this.tilt + this.r / 3, this.y);
    context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 3);
    return context.stroke();
  };
}

function draw(context, width, height, particles) {
  context.clearRect(0, 0, width, window.innerHeight);

  particles.forEach(function (particle, i) {
    particle.draw();
    particle.tiltAngle += particle.tiltAngleIncremental;
    particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 3;
    particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

    // If a confetti has fluttered out of view,
    // bring it back to above the viewport and let if re-fall.
    if (
      particle.x > width + 30 ||
      particle.x < -30 ||
      particle.y > height + 30
    ) {
      particle.x = Math.random() * width;
      particle.y = -30;
      particle.tilt = Math.floor(Math.random() * 10) - 20;
    }
  });

  // Magical recursive functional love
  requestAnimationFrame(() => {
    draw(context, width, height, particles);
  });
}

export default function (canvas) {
  const particles = [];
  const maxConfettis = 60;

  const height = canvas.height;
  const width = canvas.width;
  const context = canvas.getContext('2d');

  for (let i = 0; i < maxConfettis; i++) {
    particles.push(new ConfettiParticle(width, height, context, maxConfettis));
  }
  requestAnimationFrame(() => {
    draw(context, width, height, particles);
  });
}
