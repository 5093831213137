import { Controller } from '@hotwired/stimulus';
import { createSongEmbedIframe } from 'utils/song-embed';

export default class extends Controller {
  static values = { url: String };

  connect() {
    const embedIframe = createSongEmbedIframe(this.urlValue);
    if (embedIframe) {
      this.element.appendChild(embedIframe);
    }
  }
}
