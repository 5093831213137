import { Controller } from '@hotwired/stimulus';
import debounce from 'debounce';

export default class extends Controller {
  static targets = ['leftButton', 'rightButton', 'cardsContainer', 'mainCard'];

  connect() {
    this.debouncedToggleNavButtons = debounce(this.toggleNavButtons, 250);
    this.cardsContainerTarget.addEventListener(
      'scroll',
      this.debouncedToggleNavButtons
    );
    window.addEventListener('resize', this.debouncedToggleNavButtons);
    this.cardsContainerTarget.dispatchEvent(new CustomEvent('scroll'));
  }

  disconnect() {
    this.cardsContainerTarget.removeEventListener(
      'scroll',
      this.debouncedToggleNavButtons
    );
    window.removeEventListener('resize', this.debouncedToggleNavButtons);
  }

  getScrollPosition() {
    const { scrollLeft, offsetWidth, scrollWidth } = this.cardsContainerTarget;
    const scrollDiff = scrollLeft + offsetWidth;

    const startReached = scrollLeft == 0;
    const endReached = scrollDiff >= scrollWidth;

    return {
      startReached,
      endReached,
    };
  }

  debouncedToggleNavButtons = () => {
    debounce(this.toggleNavButtons, 250);
  };

  toggleNavButtons = () => {
    if (this.hasLeftButtonTarget && this.hasRightButtonTarget) {
      const { endReached, startReached } = this.getScrollPosition();
      this.leftButtonTarget.classList.toggle('sm:block', !startReached);
      this.rightButtonTarget.classList.toggle('sm:block', !endReached);
    }
  };

  get scrollLength() {
    return this.mainCardTarget.offsetWidth;
  }

  left() {
    this.scrollContainer(-this.scrollLength);
  }

  right() {
    this.scrollContainer(this.scrollLength);
  }

  scrollContainer(value) {
    this.cardsContainerTarget.scrollBy({ left: value, behavior: 'smooth' });
  }
}
