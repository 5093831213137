import { Controller } from '@hotwired/stimulus';

export default class QuestionsPresetFavouritesColorItemController extends Controller {
  static targets = ['input', 'colorInput'];
  static values = {
    input: String,
    colorInput: String,
  };

  connect() {
    if (this.inputTarget.value) {
      this.colorInputTarget.value = this.inputTarget.value;
    } else {
      this.updateValue();
    }
  }

  handleColorChange(e) {
    e.preventDefault();
    this.updateValue();
  }

  handleInputChange(e) {
    e.preventDefault();
    this.colorInputTarget.value = this.inputTarget.value;
  }

  updateValue() {
    this.inputTarget.value = this.colorInputTarget.value;
    this.inputTarget.dispatchEvent(new Event('change'));
  }
}
